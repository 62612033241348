import React from 'react';
import { coreCurrentClientApi } from '@cp/entities/client';
import { useClientProfile } from 'src/entities/client';
import { useMainResume } from 'src/entities/mainResume';
import { useAppDispatch } from 'src/redux';
import { useApiServerErrorHandler } from 'src/shared/apiServer';

export const useIsCurrentClientWorkingAvailable = () => {
  const { data } = useMainResume();
  return data?.isPublished ?? false;
};

interface ICurrentClientWorkingStateChangeAction {
  (newIsWorking?: boolean): void;
}

interface IUseCurrentClientWorkingState {
  isWorking: boolean;
  isWorkingAvailable: boolean;
}

export const useCurrentClientWorking = (): [ICurrentClientWorkingStateChangeAction, IUseCurrentClientWorkingState] => {
  const dispatch = useAppDispatch();
  const handleApiError = useApiServerErrorHandler();
  const clientProfile = useClientProfile();
  const isWorkingAvailable = useIsCurrentClientWorkingAvailable();

  const { isWorking = false } = clientProfile ?? {};

  return [
    React.useCallback(
      async (newIsWorking = !isWorking) => {
        const response = await dispatch(coreCurrentClientApi.endpoints.updateProfile.initiate({ isWorking: newIsWorking }));
        if ('error' in response && response.error) {
          handleApiError(response.error);
        }
      },
      [dispatch, handleApiError, isWorking],
    ),
    { isWorking, isWorkingAvailable },
  ];
};
