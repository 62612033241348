import { AppTheme } from '../../theme';
import { IconButtonColor, IconButtonSize, IconButtonVariant } from './model';

export const ICON_BUTTON_COLOR_DEFAULT: IconButtonColor = 'white';
export const ICON_BUTTON_VARIANT_DEFAULT: IconButtonVariant = 'primary';
export const ICON_BUTTON_BORDER_WIDTH = 1;

export const iconButtonSizeDict: Record<IconButtonSize, { sizeInRem: number }> = {
  small: {
    sizeInRem: 2.5,
  },
  normal: {
    sizeInRem: 3.125,
  },
};

export const iconButtonVariantDict: Record<
  IconButtonVariant,
  Record<
    IconButtonColor,
    Record<
      'color' | 'colorDisabled' | 'backgroundColor' | 'backgroundColorHover' | 'borderColor' | 'borderColorHover' | 'borderColorDisabled',
      keyof AppTheme['palette']
    >
  >
> = {
  primary: {
    white: {
      color: 'brand gray',
      colorDisabled: 'gray middle',
      backgroundColor: 'white',
      backgroundColorHover: 'gray ultra light',
      borderColor: 'gray light',
      borderColorHover: 'brand gray',
      borderColorDisabled: 'gray light',
    },
    'gray dark': {
      color: 'white',
      colorDisabled: 'gray',
      backgroundColor: 'brand gray',
      backgroundColorHover: 'gray dark',
      borderColor: 'gray',
      borderColorHover: 'white',
      borderColorDisabled: 'gray',
    },
  },
  secondary: {
    // ToDo: adjust colors
    white: {
      color: 'brand gray',
      colorDisabled: 'gray middle',
      backgroundColor: 'white',
      backgroundColorHover: 'gray ultra light',
      borderColor: 'gray light',
      borderColorHover: 'brand gray',
      borderColorDisabled: 'gray light',
    },
    'gray dark': {
      color: 'brand gray',
      colorDisabled: 'gray middle',
      backgroundColor: 'transparent',
      backgroundColorHover: 'gray ultra light',
      borderColor: 'gray middle',
      borderColorHover: 'brand gray',
      borderColorDisabled: 'gray middle',
    },
  },
};
