import React from 'react';
import { useIsMobile } from '@cp/ds/src/hooks/useIsMobile';
import loadable from '@loadable/component';
import { LayoutProps } from './model';

export * from './model';

const Desktop = loadable(() => import('./view.desktop'));
const Mobile = loadable(() => import('./view.mobile'));

export const Layout: React.FC<LayoutProps> = (props) => {
  const isMobile = useIsMobile();

  const LayoutView = isMobile ? Mobile : Desktop;

  return <LayoutView {...props} />;
};
