import { Tooltip } from '@mui/material';
import tooltipClasses from '@mui/material/Tooltip/tooltipClasses';
import styled from '@mui/material/styles/styled';
import React from 'react';
import { TooltipProps, TooltipStateProps } from '../model';

export const StyledTooltip = styled(({ className, ...props }: TooltipProps & { ownerState: Required<TooltipStateProps> }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme, ownerState }) => {
  const { colorSchema } = ownerState;

  const colorSchemaToStyles = {
    error: {
      backgroundColor: theme.palette['brand pink'],
      color: theme.palette.white,
    },
    light: {
      backgroundColor: theme.palette['gray light'],
      color: theme.palette['brand gray'],
    },
    dark: {
      backgroundColor: theme.palette['brand gray'],
      color: theme.palette.white,
    },
    secondary: {
      backgroundColor: theme.palette['gray'],
      color: theme.palette.white,
    },
    success: {
      backgroundColor: theme.palette.green,
      color: theme.palette.white,
    },
  };

  return {
    [`& .${tooltipClasses.tooltip}`]: {
      marginTop: '0.71em !important',
      marginBottom: '0 !important',
      ...theme.typography['caption bold'],
      borderRadius: `${theme.borderRadius.small}px`,
      ...colorSchemaToStyles[colorSchema],
      padding: '0 !important',
    },
    // [`& .${tooltipClasses.tooltipArrow}`]: {},
    [`& .${tooltipClasses.arrow}`]: {
      // transform: 'translate(20px, 0px) !important',
      color: colorSchemaToStyles[colorSchema].backgroundColor,
    },
  };
});
