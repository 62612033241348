import { useCallback } from 'react';
import { useModal } from 'src/app/shared/hooks/useModal';
import { CURRENT_CLIENT_PATRONAGE_HINTS } from 'src/app/shared/components/modals/currentClientPatronageHints/const';

export const useShowPatronageHints = () => {
  const { openModal } = useModal();
  return useCallback(() => {
    openModal(CURRENT_CLIENT_PATRONAGE_HINTS);
  }, [openModal]);
};
