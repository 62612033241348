import { CitiesRequest } from '@common/model/location/citiesRequest';
import { CityViewModel } from '@common/model/location/cityViewModel';
import { CityByIdRequest } from '@common/model/location/cityByIdRequest';
import { CityLocationsRequest } from '@common/model/location/city/cityLocationsRequest';
import { LocationSuggestion } from '@common/model/location/locationSuggestion';
import { coreApi } from '@cp/shared/api/core';
import { CoreLocationApiTags, coreLocationApiTagTypes } from './tags';

export const coreLocationApi = coreApi.enhanceEndpoints({ addTagTypes: coreLocationApiTagTypes }).injectEndpoints({
  endpoints: (builder) => ({
    getCities: builder.query<CityViewModel[], CitiesRequest>({
      query: (params) => ({
        url: 'location/cities',
        params,
      }),
      // providesTags: (_result, _error, arg) => {
      //   return [{ type: 'CityList', id: JSON.stringify(arg) }];
      // },
    }),
    getCityById: builder.query<CityViewModel, CityByIdRequest>({
      query: ({ id }) => ({
        url: `location/cities/${id}`,
      }),
      providesTags: (_result, _error, { id }) => [{ type: CoreLocationApiTags.City, id }],
    }),

    getCityLocations: builder.query<LocationSuggestion[], CityLocationsRequest & { cityId: number }>({
      query: ({ cityId, ...params }) => ({
        url: `location/cities/${cityId}/locations`,
        params,
      }),
    }),
  }),

  overrideExisting: false,
});
