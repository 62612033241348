import React from 'react';
import styled from '@mui/material/styles/styled';
import { useTheme } from '../../hooks/useTheme';
import { ViewProps } from './model';
import { buildImageSources } from './utils';

const StyledImg = styled('img')({ verticalAlign: 'middle' });

export const ViewNormal: React.FC<ViewProps> = ({ assetItem, responsiveGrid, alt, ...imgProps }) => {
  const theme = useTheme();
  const sources = buildImageSources(assetItem, responsiveGrid, theme);

  return (
    <picture>
      {sources.map((sourcesProps) => (
        <source key={[sourcesProps.srcSet, sourcesProps.media].join('|')} {...sourcesProps} />
      ))}
      <StyledImg alt={alt} {...imgProps} />
    </picture>
  );
};
