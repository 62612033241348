import { AppTheme } from '@cp/ds';
import { LinkColor, LinkSize } from './model';

export const LINK_COLOR_DEFAULT: LinkColor = 'dark';
export const LINK_SIZE_DEFAULT: LinkSize = 'normal';

export const linkColorDict: Record<
  LinkColor,
  Record<LinkSize, Record<'color' | 'borderColor' | 'hoverColor' | 'hoverBorderColor', keyof AppTheme['palette']>>
> = {
  dark: {
    normal: {
      color: 'brand gray',
      borderColor: 'gray',
      hoverColor: 'brand pink',
      hoverBorderColor: 'brand pink',
    },
    small: {
      color: 'brand gray',
      borderColor: 'gray',
      hoverColor: 'brand pink hover',
      hoverBorderColor: 'brand pink hover',
    },
  },
  light: {
    normal: {
      color: 'gray light',
      borderColor: 'gray',
      hoverColor: 'white',
      hoverBorderColor: 'white',
    },
    small: {
      color: 'gray light',
      borderColor: 'gray',
      hoverColor: 'white',
      hoverBorderColor: 'white',
    },
  },
  pink: {
    normal: {
      color: 'brand pink',
      borderColor: 'brand pink',
      hoverColor: 'brand pink hover',
      hoverBorderColor: 'brand pink hover',
    },
    small: {
      color: 'brand pink',
      borderColor: 'brand pink',
      hoverColor: 'brand pink hover',
      hoverBorderColor: 'brand pink hover',
    },
  },
};
