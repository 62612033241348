import { CityViewModel } from '@common/model/location/cityViewModel';
import React from 'react';

export interface CurrentCityContextValue {
  currentCity?: CityViewModel;
}

export const CURRENT_CITY_CONTEXT_DEFAULT_VALUE = {};

export const CurrentCityContext = React.createContext<CurrentCityContextValue>(CURRENT_CITY_CONTEXT_DEFAULT_VALUE);
