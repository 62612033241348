import { CityViewModel } from '@common/model/location/cityViewModel';
import React from 'react';
import { CurrentCityContext } from './context';

interface UseCurrentCity {
  (): { currentCity: CityViewModel };
  (options: { allowEmptyValue: true }): { currentCity: CityViewModel | undefined };
}

export const useCurrentCity = ((options?: Parameters<UseCurrentCity>[0]): ReturnType<UseCurrentCity> => {
  let currentCity: CityViewModel | undefined;
  let err: Error | undefined;

  try {
    ({ currentCity } = React.useContext(CurrentCityContext));
  } catch (e) {
    if (e instanceof Error) {
      err = e;
    }
  }

  if (options && 'allowEmptyValue' in options && options.allowEmptyValue) {
    return {
      currentCity,
    };
  }

  if (!currentCity) {
    throw err ?? new Error('CurrentCity value is empty');
  }

  return {
    currentCity,
  };
}) as UseCurrentCity;
