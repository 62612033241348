import { useMemo } from 'react';
import { CITY_PATH_PARAMETER, useClientWebResolver } from '@cp/entities/clientWebRoutes';
import { ClientWebPage } from '@common/model/meta/clientWebPage';
import { clientTypeToSuitableCatalogItemType } from '@common/utils/catalogType';
import { useClientCity } from 'src/app/shared/hooks/useClientCity';
import { useClientProfile } from 'src/entities/client';

/**
 * Generate link to home page.
 */
export const useHomeHref = () => {
  const resolver = useClientWebResolver();
  const clientProfile = useClientProfile();
  const { clientCity } = useClientCity();
  return useMemo(() => {
    return resolver.resolve(
      clientProfile?.mainCareerId && clientProfile.type
        ? {
            page: ClientWebPage.Catalog,
            pageProps: { careerId: clientProfile.mainCareerId, catalogType: clientTypeToSuitableCatalogItemType(clientProfile.type) },
            pathParams: { [CITY_PATH_PARAMETER]: clientCity?.alias },
          }
        : { page: ClientWebPage.Home },
    );
  }, [clientCity?.alias, clientProfile, resolver]);
};
