import { SystemStyleObject } from '@mui/system';
import merge from 'lodash/merge';
import { AppTheme, SxProps } from '../../theme';

export const mergeSx = (...args: (SxProps | undefined)[]): SxProps => {
  let mergedObject: SystemStyleObject<{}> = {};
  const chain: Array<SystemStyleObject<AppTheme> | ((theme: AppTheme) => SystemStyleObject<AppTheme>)> = [];

  for (const arg of args) {
    if (!arg) {
      continue;
    }

    if (typeof arg === 'function') {
      // FIXME: its ts error here. We need to change types or change behavior
      // @ts-ignore
      chain.push(arg);
    }

    if (Array.isArray(arg)) {
      chain.push(...arg);
    }

    mergedObject = merge(mergedObject, arg);
  }

  return [mergedObject, ...chain];
};
