import React from 'react';
import { Button } from '@cp/ds/src/components/button';
import { ButtonProps } from '@cp/ds/src/components/button/model';
import { cx } from '@emotion/css';
import { EButtonName, useSemanticEventTrigger } from 'src/shared/tracking';
import { useClientLogin } from '../../model';
import { LoginButtonClickEvent } from '../../lib';

export type LoginButtonProps = ButtonProps;

export const LoginButton = (props: LoginButtonProps) => {
  const fireEvent = useSemanticEventTrigger(LoginButtonClickEvent);
  const login = useClientLogin();
  return (
    <Button
      onClick={() => {
        fireEvent();
        login();
      }}
      size="small"
      variant="secondary"
      {...props}
      className={cx(EButtonName.Login, props.className)}
    >
      Войти
    </Button>
  );
};
