import React from 'react';
import { SvgIcon, SvgIconProps } from '../components/svgIcon';

export const InfoIcon = React.forwardRef<SVGSVGElement, SvgIconProps>((props, ref) => {
  return (
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    <SvgIcon cacheName="info" ref={ref as any} {...props}>
      <path
        clipRule="evenodd"
        d="M12 4.8a7.2 7.2 0 1 0 0 14.4 7.2 7.2 0 0 0 0-14.4ZM3.2 12a8.8 8.8 0 1 1 17.6 0 8.8 8.8 0 0 1-17.6 0Z"
        fill="currentColor"
        fillRule="evenodd"
      />
      <path
        clipRule="evenodd"
        d="M11.2 8a.8.8 0 0 1 .8-.8h.008a.8.8 0 0 1 0 1.6H12a.8.8 0 0 1-.8-.8ZM9.6 11.2a.8.8 0 0 1 .8-.8H12a.8.8 0 0 1 .8.8v3.2h.8a.8.8 0 1 1 0 1.6h-3.2a.8.8 0 0 1 0-1.6h.8V12h-.8a.8.8 0 0 1-.8-.8Z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </SvgIcon>
  );
});
