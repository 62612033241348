import { SerializedError } from '@reduxjs/toolkit';
import { FieldPath, FieldValues, UseFormReturn } from 'react-hook-form';
import { ApiServerError } from '../rtk/query/model';
// import { HttpStatusCode } from '../const/httpStatusCode';
import { collectFormFieldErrorsFromApiServerError } from '../form/collectFormFieldErrorsFromApiServerError';
import { getFirstFieldNameInForm } from '../form/getFirstFieldNameInForm';

export interface ApiServerHandlerOptions<F extends FieldValues = FieldValues, FC = unknown> {
  form?: UseFormReturn<F, FC>;
  formFieldNameForCommonErrors?: FieldPath<F>;
  onUnhandledError?: (payload: { message: string }) => void;
}

export const isApiServerError = (err: unknown): err is ApiServerError =>
  typeof err === 'object' && err !== null && 'isHandled' in err && 'data' in err;

export const isSerializedError = (err: unknown): err is SerializedError =>
  typeof err === 'object' && err !== null && ('message' in err || 'name' in err);

export const handleApiServerError = <F extends FieldValues = FieldValues, FC = unknown>(
  error: SerializedError | ApiServerError,
  {
    form,
    formFieldNameForCommonErrors: _formFieldNameForCommonErrors,
    onUnhandledError = ({ message }) => alert(message),
  }: ApiServerHandlerOptions<F, FC> = {},
) => {
  const formFieldNameForCommonErrors = _formFieldNameForCommonErrors ?? ((form && getFirstFieldNameInForm(form)) as FieldPath<F>);

  // ApiServerError
  if (isApiServerError(error)) {
    if (error.isHandled) {
      return;
    }

    // if (error.status !== HttpStatusCode.BadRequest || !error.data.errors) {
    //   console.error(error.status, error);

    //   onUnhandledError({ message: error.data.message });
    //   return;
    // }

    const errorDict = collectFormFieldErrorsFromApiServerError<F>(error);
    const firstErrorFromDict = Object.values<string | undefined>(errorDict).filter(Boolean)[0];
    const messageToShow = firstErrorFromDict ?? error.data.message;

    if (!form) {
      onUnhandledError({ message: messageToShow });
      return;
    }

    if (firstErrorFromDict) {
      for (const p in errorDict) {
        form.setError(p as FieldPath<F>, { type: 'ApiServerValidationError', message: errorDict[p] });
      }
    } else {
      if (form && formFieldNameForCommonErrors) {
        form.setError(formFieldNameForCommonErrors as FieldPath<F>, { type: 'ApiServerValidationError', message: messageToShow });
      }
    }
    // SerializedError
  } else {
    console.error(error.name, error);

    if (!error.message) {
      return;
    }

    if (formFieldNameForCommonErrors && form) {
      form.setError(formFieldNameForCommonErrors, { type: 'SerializedError', message: error.message });
    } else {
      onUnhandledError({ message: error.message });
    }
  }
};
