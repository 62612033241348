import { SxProps } from '../../theme';
import { AvatarSizeProp } from './types';

export const getAvatarSizeStyles = (size: AvatarSizeProp): SxProps => {
  return {
    height: size,
    maxHeight: size,
    maxWidth: size,
    width: size,
  };
};
