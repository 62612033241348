import React from 'react';
import { SvgIcon } from '../components/svgIcon';
import { SvgIconProps } from '../components/svgIcon/model';

export const HeartThickIcon = React.forwardRef<SVGSVGElement, SvgIconProps>((props, ref) => {
  return (
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    <SvgIcon cacheName="heartThick" ref={ref as any} {...props}>
      <path
        d="M12 18.994c-.516-.445-1.1-.907-1.716-1.4h-.008c-2.172-1.725-4.634-3.678-5.72-6.018A5.638 5.638 0 0 1 4 9.199a4.088 4.088 0 0 1 .334-1.626c.222-.515.547-.982.957-1.373.41-.39.896-.698 1.43-.904A4.423 4.423 0 0 1 8.4 5a5.03 5.03 0 0 1 2.662.764c.35.22.665.486.938.79.275-.303.59-.568.938-.79a5.026 5.026 0 0 1 2.662-.764 4.423 4.423 0 0 1 1.679.295c.534.206 1.02.513 1.43.904.41.391.735.858.957 1.373.222.515.335 1.068.334 1.626a5.636 5.636 0 0 1-.555 2.38c-1.087 2.34-3.548 4.293-5.72 6.015l-.008.007c-.618.489-1.2.951-1.716 1.399L12 18.994ZM8.4 6.556a2.873 2.873 0 0 0-2 .77 2.595 2.595 0 0 0-.596.858A2.534 2.534 0 0 0 5.6 9.199a4.14 4.14 0 0 0 .41 1.732 9.597 9.597 0 0 0 2.045 2.699c.793.777 1.705 1.53 2.494 2.162.218.175.44.352.663.528l.14.111.648.518.01-.01.005-.003h.005l.007-.005h.008l.015-.012.032-.026.006-.004.009-.007h.005l.007-.006.531-.424.14-.11.665-.53c.789-.633 1.701-1.385 2.494-2.166a9.583 9.583 0 0 0 2.046-2.698 4.132 4.132 0 0 0 .416-1.75 2.535 2.535 0 0 0-.206-1.01c-.138-.321-.34-.612-.595-.855a2.872 2.872 0 0 0-2-.777 3.27 3.27 0 0 0-1.308.26 3.19 3.19 0 0 0-1.084.759L12 8.927l-1.208-1.352a3.19 3.19 0 0 0-1.084-.759 3.27 3.27 0 0 0-1.308-.26Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
});
