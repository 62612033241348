import React from 'react';
import { Button } from '@cp/ds/src/components/button';
import { ClientWebPage } from '@common/model/meta/clientWebPage';
import { usePathGenerator } from '@cp/entities/clientWebRoutes';
import { cx } from '@emotion/css';
import { RoutedLink as Link } from 'src/shared/ui/link';
import { EButtonName } from 'src/shared/tracking';
import { SupportNavigateProps } from './model';

export const SupportNavigate: React.FC<SupportNavigateProps> = ({ buttonProps, linkProps }) => {
  const generatePath = usePathGenerator();

  return (
    <Link href={generatePath({ page: ClientWebPage.Support })} underline={false} {...linkProps}>
      <Button
        color="gray"
        size="small"
        variant="primary"
        {...buttonProps}
        className={cx(EButtonName.ContactSupport, buttonProps?.className)}
      >
        Написать
      </Button>
    </Link>
  );
};
