import { IEventBus } from '@cp/shared/cqrs';
import { createContext, useContext } from 'react';

const ctx = createContext<IEventBus>({
  publish: (event) => {
    console.warn('[EventBus] get message before initialized. Message will be ignored', event);
  },
});

export const EventBusContext = ctx.Provider;

export const useEventBus = (): IEventBus => useContext(ctx);
