import React from 'react';
import { Container } from '../container';
import { ContentSkeleton } from '../contentSkeleton';
import { PageSkeletonProps } from './model';
export type { PageSkeletonProps } from './model';

export const PageSkeleton: React.FC<PageSkeletonProps> = () => {
  return (
    <Container sx={{ pt: 4, pb: 8, backgroundColor: 'white' }}>
      <ContentSkeleton blocks={5} spacing={4} />
    </Container>
  );
};
