import { createDraftSafeSelector } from '@reduxjs/toolkit';
import { authenticationTokenPayloadSelector } from '@cp/shared/api/core';
import { CurrentUserType } from './currentUserType';
import { CurrentUser } from './currentUser';

const initialState = { id: 0, type: CurrentUserType.Guest };

export const currentUserSelector = createDraftSafeSelector(authenticationTokenPayloadSelector, (payload): CurrentUser => {
  if (payload?.clientId) {
    return {
      id: payload.clientId,
      type: CurrentUserType.Client,
    };
  }
  if (payload?.adminId) {
    return {
      id: payload.adminId,
      type: CurrentUserType.Admin,
    };
  }
  return initialState;
});
