import React, { PropsWithChildren, ReactNode } from 'react';
import { Details } from '@cp/ui/components';
import { ChevronDownLightIcon } from '@cp/ds/icons';
import { styled } from '@cp/ds/theme';
import useMediaQuery from '@mui/material/useMediaQuery';

const Root = styled(Details, { name: 'Accordion' })(({ theme }) => ({
  '& > summary': {
    justifyContent: 'space-between',
    alignItems: 'flex-start',
  },
  '.Details-Marker': {
    transition: theme.transitions.create(['transform'], {
      duration: theme.transitions.duration.standard,
    }),
  },
  '&[open] .Details-Marker': {
    transform: 'rotate(180deg)',
  },
  '@container (min-width: 600px)': {
    '& .Details-Marker': {
      display: 'none',
    },
  },
}));

export interface AccordionProps extends PropsWithChildren {
  summary: ReactNode;
}
export const Accordion = (props: AccordionProps) => {
  const open = useMediaQuery('(min-width: 600px)');
  return <Root marker={<ChevronDownLightIcon />} open={open} {...props} />;
};
