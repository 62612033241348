import { newCareersDict } from '@common/const/catalog';
import { CareerId } from '@common/const/catalog/career';
import { ClientPatronageStage } from '@common/model/admin/clientPatronage/stage';
import { ClientType } from '@common/model/client/clientType';
export * from './useSemanticEventTrigger';

export enum EButtonName {
  ClientPatronageAlert = 'ClientPatronageAlert',
  ContactSupport = 'ContactSupport',
  DeleteAccount = 'DeleteAccount',
  FindJob = 'FindJob',
  Login = 'Login',
  Next = 'Next',
  PublishVacancy = 'PublishVacancy',
  RegisterAsSpecialist = 'RegisterAsSpecialist',
  SelectCareer = 'SelectCareer',
  SelectClientType = 'SelectClientType',
  SelectTariff = 'SelectTariff',
}

export enum EPageName {
  Registration = 'Registration',
}

export const CAREER_ID_TO_CAREER_NAME: Record<CareerId | keyof typeof newCareersDict, string> = {
  [CareerId.Nanny]: 'Nanny',
  [CareerId.Nurse]: 'Nurse',
  [CareerId.Tutor]: 'Tutor',
  [CareerId.Housekeeper]: 'Housekeeper',
  5: 'Psychologist',
  6: 'PetSitter',
};

export const CLIENT_TYPE_TO_CLIENT_TYPE_NAME: Record<ClientType, string> = {
  [ClientType.Employer]: 'Employer',
  [ClientType.Specialist]: 'Specialist',
};

export const CLIENT_PATRONAGE_STAGE_TO_CLIENT_PATRONAGE_STAGE_NAME: Record<ClientPatronageStage, string> = {
  [ClientPatronageStage.Unknown]: 'Unknown',
  [ClientPatronageStage.Initial]: 'Initial',
  [ClientPatronageStage.Interview]: 'Interview',
  [ClientPatronageStage.Training]: 'Training',
  [ClientPatronageStage.Photo]: 'Photo',
  [ClientPatronageStage.ResumeFilling]: 'ResumeFilling',
  [ClientPatronageStage.ResumeModeration]: 'ResumeModeration',
  [ClientPatronageStage.Completed]: 'Completed',
  [ClientPatronageStage.Rejected]: 'Rejected',
};
