import { CatalogStateShape } from './slice';

export const catalogLayoutMapRequestedSelector = (state: CatalogStateShape) => state.catalog.isMapRequested;

export const catalogLayoutDesktopViewModeSelector = (state: CatalogStateShape) => state.catalog.desktopViewMode;

export const catalogLayoutActiveItemIdSelector = (state: CatalogStateShape) => state.catalog.activeItemId;

export const catalogLayoutFilterSecondOpenedSelector = (state: CatalogStateShape) => state.catalog.isFilterSecondOpened;

export const catalogLayoutMapInstanceSelector = (state: CatalogStateShape) => state.catalog.mapInstance;

export const catalogLayoutViewModeSwitchHeightSelector = (state: CatalogStateShape) => state.catalog.viewModeSwitchHeight;

export const catalogLayoutContactRequestsCountSelector = (state: CatalogStateShape) => state.catalog.contactRequestsCount;
