import React from 'react';
import { CURRENT_CLIENT_INTERVIEW_DETAILS } from 'src/app/shared/components/modals/currentClientInterviewDetails/const';
import { useModal } from 'src/app/shared/hooks/useModal';

export const useShowInterviewDetails = () => {
  const { openModal } = useModal();

  return [
    React.useCallback(() => {
      openModal(CURRENT_CLIENT_INTERVIEW_DETAILS);
    }, [openModal]),
  ];
};
