import React from 'react';
import { Typography } from '../../../../typography';
import { mergeSx } from '../../../../../utils/sx/merge';
import { IconContainer } from '../../iconContainer';
import { ProfileLettersProps } from './model';
import { LETTERS_SIZE_TO_CONTAINER_SIZE_RATE } from './const';

export const AvatarLettersContent: React.FC<ProfileLettersProps> = ({ containerSize, theme, backgroundColor, letters, color }) => {
  return (
    <IconContainer
      sx={{
        backgroundColor: backgroundColor ?? 'peach',
      }}
      theme={theme}
    >
      <Typography
        sx={mergeSx(
          { color: color && typeof theme.palette[color] === 'string' ? (theme.palette[color] as string) : 'white' },
          typeof containerSize === 'number' ? { fontSize: containerSize * LETTERS_SIZE_TO_CONTAINER_SIZE_RATE } : undefined,
        )}
        variant="h6"
      >
        {letters.toUpperCase()}
      </Typography>
    </IconContainer>
  );
};
