import React from 'react';
import { ClientProfileViewModel } from '@common/model/client';
import { useClientProfile } from 'src/entities/client';
import { useItems, useVisibility } from '../model';
import { View } from './view';
import { BottomMenuProps } from './model';

export * from './model';
export * from './const';

const BottomMenu: React.FC<BottomMenuProps & { clientProfile: ClientProfileViewModel }> = ({ sx, ...restProps }) => {
  const { items, isLoading } = useItems(restProps);
  const { isVisible } = useVisibility();

  const viewProps = {
    items,
    isLoading,
    isVisible,
    sx,
  };

  return <View {...viewProps} />;
};
BottomMenu.displayName = 'BottomMenu';

export const BottomMenuWrapper: React.FC<BottomMenuProps> = (props) => {
  const clientProfile = useClientProfile();

  if (!clientProfile) {
    return null;
  }

  return <BottomMenu {...props} clientProfile={clientProfile} />;
};
