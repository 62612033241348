import { FieldPath, FieldValues } from 'react-hook-form';
import { ApiServerErrorsDict } from '../apiServer/errorResponse';
import { ApiServerError } from '../rtk/query/model';

export const collectFormFieldErrorsFromApiServerError = <F extends FieldValues = FieldValues>(error: ApiServerError) => {
  const errorsDict = {} as { [K in FieldPath<F>]: string };

  const getErrors = (coreApiErrorsDict: ApiServerErrorsDict, prefix?: FieldPath<F>) => {
    for (const p in coreApiErrorsDict) {
      const fieldName = (prefix ? [prefix, p].join('.') : p) as FieldPath<F>;
      const errorDesc = coreApiErrorsDict[p];

      if (typeof errorDesc === 'object') {
        getErrors(errorDesc, fieldName);
        continue;
      }

      errorsDict[fieldName] = errorDesc;
    }
  };

  if (error.data.errors) {
    getErrors(error.data.errors);
  }

  return errorsDict;
};
