import { useMemo } from 'react';
import { useIsMobile } from '@cp/ds/src/hooks/useIsMobile';

export enum HeaderFeature {
  GoBack,
  CitySelection,
  ClientMenu,
  BurgerMenu,
  LoginButton,
  NavigateToCatalog,
}

export type FeatureConfig = Partial<Record<HeaderFeature, boolean>>;

const DEFAULT_FEATURES_MOBILE: FeatureConfig = {
  [HeaderFeature.GoBack]: true,
  [HeaderFeature.BurgerMenu]: true,
};

const DEFAULT_FEATURES_DESKTOP: FeatureConfig = {
  [HeaderFeature.ClientMenu]: true,
  [HeaderFeature.NavigateToCatalog]: true,
  [HeaderFeature.LoginButton]: true,
};

export const useHeaderFeatures = (mobileConfig: FeatureConfig = {}, desktopConfig: FeatureConfig = {}) => {
  const isMobile = useIsMobile();
  return useMemo(() => {
    return isMobile
      ? ({ ...DEFAULT_FEATURES_MOBILE, ...mobileConfig } as Required<FeatureConfig>)
      : ({ ...DEFAULT_FEATURES_DESKTOP, ...desktopConfig } as Required<FeatureConfig>);
  }, [isMobile, mobileConfig, desktopConfig]);
};
