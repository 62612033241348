import React from 'react';
import { clientTypeToSuitableCatalogItemType } from '@common/utils/catalogType';
import { CityViewModel } from '@common/model/location/cityViewModel';
import { coreLocationApi } from '@cp/entities/location';
import { coreCurrentClientApi } from '@cp/entities/client';
import { useApiServerErrorHandler } from 'src/shared/apiServer';
import { CLIENT_ADDRESS_SEARCH_MODAL_NAME } from '../components/modals/clientAddressSearch/const';
import { useModal } from './useModal';

export const useClientCityFillingChecker = () => {
  const [getClientProfile] = coreCurrentClientApi.useLazyGetProfileQuery();
  const [getCityById] = coreLocationApi.useLazyGetCityByIdQuery();
  const handleApiServerError = useApiServerErrorHandler();
  const [updateClientProfile] = coreCurrentClientApi.useUpdateProfileMutation();
  const { openModal } = useModal();

  return React.useCallback<() => Promise<CityViewModel>>(async () => {
    const { data: clientProfile } = await getClientProfile(undefined, true);

    if (!clientProfile) {
      throw new Error('Client profile is empty');
    }

    if (!clientProfile?.type || !clientProfile?.mainCareerId) {
      throw new Error('Client main career or type not filled');
    }

    if (clientProfile.cityId) {
      const { data: clientCity } = await getCityById({ id: clientProfile.cityId }, true);

      if (!clientCity) {
        throw new Error(`Can\'t fetch client city (id: ${clientProfile.cityId})`);
      }

      return clientCity;
    }

    const catalogType = clientTypeToSuitableCatalogItemType(clientProfile.type);

    return new Promise((resolve, reject) => {
      openModal(CLIENT_ADDRESS_SEARCH_MODAL_NAME, {
        careerId: clientProfile.mainCareerId as number,
        catalogType,
        onModalDestroy: reject,
        onAddressConfirmed: async ({ city, form, formSearchFieldName }) => {
          const response = await updateClientProfile({ cityId: city.id });

          if ('error' in response) {
            handleApiServerError(response.error, { form, formFieldNameForCommonErrors: formSearchFieldName });
          } else {
            resolve(city);
          }
        },
      });
    });
  }, [getCityById, getClientProfile, handleApiServerError, openModal, updateClientProfile]);
};
