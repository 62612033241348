import React from 'react';
import { ClientPatronageAlert } from 'src/entities/client/current/patronage';
import { useGoToNextPatronageStage } from '../../model';
import { PatronageAlertProps } from './model';

export const PatronageAlert: React.FC<PatronageAlertProps> = ({ clientProfile, afterClick }) => {
  const [goToNextPatronageStage] = useGoToNextPatronageStage(clientProfile.patronageStage);

  const handleClick = () => {
    if (!goToNextPatronageStage) {
      return;
    }

    goToNextPatronageStage();
    afterClick?.();
  };

  return <ClientPatronageAlert clientProfile={clientProfile} onClick={handleClick} />;
};
