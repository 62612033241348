import React, { PropsWithChildren } from 'react';
import { Box, BoxProps, Container } from '@cp/ui/components';
import { MOBILE_BREAKPOINT_THRESHOLD, styled } from '@cp/ui/theme';
import { createCssVar, createGetCssVar } from '@cp/ui/utils';
import adornment1M from './assets/section-adornment1-m.svg';
import adornment1D from './assets/section-adornment1-d.svg';
import adornment2M from './assets/section-adornment2-m.svg';
import adornment2D from './assets/section-adornment2-d.svg';

type AdornmentVariant = 'one';
export interface SectionProps extends BoxProps, PropsWithChildren {
  bottomAdornment?: AdornmentVariant;
  topAdornment?: AdornmentVariant;
  offset?: 'small' | 'medium' | 'large';
}

const name = 'Section';
const token = createCssVar(name);
const getVar = createGetCssVar(name);

const Root = styled(Box, {
  name,
  shouldForwardProp: (n) => !['bottomAdornment', 'topAdornment', 'offset'].includes(n as string),
})<SectionProps>(({ theme, bottomAdornment, topAdornment, offset }) => ({
  position: 'relative',
  paddingTop: getVar('offset'),
  paddingBottom: getVar('offset'),
  [token('header-offset')]: '20px',
  [token('offset')]: '20px',
  [theme.breakpoints.up(MOBILE_BREAKPOINT_THRESHOLD)]: {
    [token('header-offset')]: '40px',
    [token('offset')]: '40px',
  },
  ...(bottomAdornment && {
    '&::after': {
      top: '100%',
      left: 0,
      right: 0,
      position: 'absolute',
      content: '""',
      display: 'block',
      height: getVar(['adornment', 'bottom', 'height']),
      background: `${getVar('adornment-bottom-url')} bottom center no-repeat`,
    },
  }),
  ...(topAdornment && {
    '&::before': {
      bottom: '100%',
      left: 0,
      right: 0,
      position: 'absolute',
      content: '""',
      display: 'block',
      height: getVar(['adornment', 'top', 'height']),
      background: `${getVar('adornment-top-url')} bottom center no-repeat`,
    },
  }),
  ...(bottomAdornment === 'one' && {
    [token(['adornment', 'bottom', 'height'])]: `${adornment1M.height}px`,
    [token('adornment-bottom-url')]: `url(${adornment1M.src})`,
    [theme.breakpoints.up(MOBILE_BREAKPOINT_THRESHOLD)]: {
      [token('adornment-bottom-height')]: `${adornment1D.height}px`,
      [token('adornment-bottom-url')]: `url(${adornment1D.src})`,
    },
  }),
  ...(topAdornment === 'one' && {
    [token('adornment-top-height')]: `${adornment2M.height}px`,
    [token('adornment-top-url')]: `url(${adornment2M.src})`,
    [theme.breakpoints.up(MOBILE_BREAKPOINT_THRESHOLD)]: {
      [token('adornment-top-height')]: `${adornment2D.height}px`,
      [token('adornment-top-url')]: `url(${adornment2D.src})`,
    },
  }),
}));

export const Section: React.FC<SectionProps> = ({ children, ...props }) => {
  return (
    <Root {...props}>
      <Container>{children}</Container>
    </Root>
  );
};
