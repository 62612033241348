import qs from 'qs';
import { isValidDate } from '@cp/ds/src/utils';
import { QueryParamsDictionary, QueryParamsNonEmptyDictionary } from './model';

const decodeValue = (value: string): string | number | boolean | Date => {
  if (/^(\d+|\d*\.\d+)$/.test(value)) {
    return parseFloat(value);
  }

  const keywords = {
    true: true,
    false: false,
    null: null,
    undefined,
  };
  if (value in keywords) {
    return keywords[value];
  }
  if (isValidDate(value)) {
    return new Date(value);
  }

  return value;
};

export const parseQueryParamValues = (dict: QueryParamsDictionary) => {
  return Object.entries(dict as QueryParamsNonEmptyDictionary).reduce((acc, [k, v]) => {
    if (typeof v === 'string') {
      acc[k] = decodeValue(v);
    } else if (Array.isArray(v)) {
      acc[k] = v.map((subValue) => {
        return decodeValue(String(subValue));
      });
    } else if (typeof v === 'object' && !(v instanceof Date)) {
      acc[k] = parseQueryParamValues(v);
    } else {
      acc[k] = v;
    }

    return acc;
  }, {} as QueryParamsNonEmptyDictionary);
};

export const decodeQueryString = <T extends QueryParamsDictionary>(queryString: string, defaultParams?: Partial<T>): T => {
  const dict = qs.parse(queryString, {
    ignoreQueryPrefix: true,
    decoder: decodeValue,
  }) as QueryParamsNonEmptyDictionary;

  if (defaultParams) {
    for (const k in defaultParams) {
      dict[k] = dict[k] ?? defaultParams[k];
    }
  }

  return dict as unknown as T;
};

export const encodeQueryParams = <T extends QueryParamsDictionary>(queryParams: T): string => {
  return qs.stringify(queryParams, { serializeDate: (date) => date.toISOString(), encode: false });
};
