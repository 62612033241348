import React from 'react';
import { Button, ButtonProps } from '@cp/ds/components/button';
import { Box, BoxProps } from '@cp/ui/components';
import { ArrowRightIcon } from '@cp/ds/icons';
import { styled } from '@cp/ui/theme';
import { useIsMobile } from '@cp/ds/hooks/useIsMobile';
import { RoutedLink } from 'src/shared/ui/link';

const Root = styled(Box, { name: 'FastActions' })({
  display: 'flex',
  justifyContent: 'center',
  gap: '20px',
});

const FAButton = ({ ...props }: ButtonProps) => (
  <Button
    as="span"
    iconRight={<ArrowRightIcon />}
    sx={{ textWrap: 'nowrap', textOverflow: 'clip', userSelect: 'none', display: 'flex', flexGrow: 1 }}
    {...props}
  />
);

export const FastActions = (props: BoxProps) => {
  const isMobile = useIsMobile();
  const size = isMobile ? 'small' : 'normal';
  return (
    <Root {...props}>
      <RoutedLink href="/nannies" sx={{ display: 'contents' }}>
        <FAButton color="pink" size={size}>
          Найти няню
        </FAButton>
      </RoutedLink>
      <RoutedLink href="/work-for-nannies" sx={{ display: 'contents' }}>
        <FAButton size={size}>Найти работу</FAButton>
      </RoutedLink>
    </Root>
  );
};
