import React from 'react';
import { useTheme } from '../../hooks/useTheme';
import { Box } from '../box';
import { StyledInput } from '../checkbox/components/styledInput';
import { Typography } from '../typography';
import { SWITCH_INPUT_HEIGHT, SWITCH_INPUT_WIDTH, SWITCH_LABEL_SPACING } from './const';
import { SwitchProps } from './model';
export type { SwitchProps } from './model';
import { styled } from '../../theme/styled';

const SwitchRoot = styled('label', { name: 'Switch' })<SwitchProps>(() => ({
  WebkitTapHighlightColor: 'transparent',
  position: 'relative',
  display: 'inline-flex',
  alignItems: 'center',
  justifyContent: 'flex-start',
  width: '100%',
  cursor: 'pointer',
  '&:disabled': {
    cursor: 'not-allowed',
  },
}));

export const Switch = React.forwardRef<HTMLInputElement, SwitchProps>(
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  ({ inputName, value = false, onChange, disabled, error, label, labelPosition = 'right', labelTypography = 'body', sx }, ref) => {
    const theme = useTheme();

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      if (disabled) {
        return;
      }

      onChange?.(event.target.checked);
    };

    const renderLabel = () => (
      <Typography
        component="span"
        sx={{
          ml: labelPosition === 'right' ? SWITCH_LABEL_SPACING : 0,
          mr: labelPosition === 'left' ? SWITCH_LABEL_SPACING : 0,
          color: theme.palette[disabled ? 'gray' : 'brand gray'],
        }}
        variant={labelTypography}
      >
        {label}
      </Typography>
    );

    return (
      <SwitchRoot disabled={disabled} sx={sx}>
        {label && labelPosition === 'left' && renderLabel()}
        <StyledInput
          checked={value}
          disabled={disabled}
          name={inputName}
          onChange={handleInputChange}
          ref={ref}
          sx={{
            WebkitTapHighlightColor: 'transparent',
            visibility: 'hidden',
            position: 'absolute',
            zIndex: 1,
            left: '-100%',
            width: 1,
            height: 1,
            opacity: 0,
          }}
          type="checkbox"
        />
        <Box
          component="span"
          sx={{
            display: 'inline-flex',
            width: SWITCH_INPUT_WIDTH,
            minWidth: SWITCH_INPUT_WIDTH,
            height: SWITCH_INPUT_HEIGHT,
            minHeight: SWITCH_INPUT_HEIGHT,
            borderRadius: '10px',
            p: 0.25,
            transition: theme.transitions.create(['background-color'], {
              duration: theme.transitions.duration.short,
            }),
            backgroundColor: theme.palette[value && !disabled ? 'green' : 'gray light'],
          }}
        >
          <Box
            component="span"
            sx={{
              display: 'inline-block',
              width: 16,
              height: 16,
              borderRadius: '8px',
              backgroundColor: theme.palette.white,
              transition: theme.transitions.create(['transform'], {
                duration: theme.transitions.duration.short,
              }),
              // eslint-disable-next-line @typescript-eslint/no-magic-numbers
              transform: `translateX(${value ? 20 : 0}px)`,
            }}
          />
        </Box>
        {label && labelPosition === 'right' && renderLabel()}
      </SwitchRoot>
    );
  },
);
