/* eslint-disable @typescript-eslint/no-magic-numbers */
import React from 'react';
import { MOBILE_BREAKPOINT_THRESHOLD } from '@cp/ds/src/theme';
import { Box } from '@cp/ds/src/components/box';
import { useTheme } from '@cp/ds/src/hooks/useTheme';
import { Divider } from '@cp/ds/src/components/divider';
import { useIsMobile } from '@cp/ds/src/hooks/useIsMobile';
import { mergeSx } from '@cp/ds/src/utils/sx/merge';
import { Item } from './item';
import { ClientMenuProps } from './model';
import { Header } from './header';
import { HORIZONTAL_SPACING_DESKTOP, HORIZONTAL_SPACING_MOBILE } from './const';

export * from './header';
export * from './item';
export * from './layout';
export * from './model';

// eslint-disable-next-line @typescript-eslint/naming-convention
export const ClientMenu: React.FC<ClientMenuProps> = ({ clientProfile, items, onClose, HeaderProps, headerBottom, footer, sx }) => {
  const theme = useTheme();
  const isMobile = useIsMobile();

  return (
    <Box sx={mergeSx({ pt: { xs: 0.75, [MOBILE_BREAKPOINT_THRESHOLD]: 2 }, pb: 3 }, sx)}>
      <Box
        sx={{
          px: HORIZONTAL_SPACING_MOBILE,
          [theme.breakpoints.up(MOBILE_BREAKPOINT_THRESHOLD)]: {
            pb: 1.5,
            px: HORIZONTAL_SPACING_DESKTOP,
            borderBottom: `1px solid ${theme.palette['gray middle']}`,
          },
        }}
      >
        <Header clientProfile={clientProfile} {...HeaderProps} sx={mergeSx({ mb: headerBottom ? 1.5 : 0 }, HeaderProps?.sx)} />
        {headerBottom}
        {isMobile && <Divider sx={{ mt: headerBottom ? 1 : 2.5 }} />}
      </Box>
      <Box component="ul" sx={{ m: 0, p: 0, pt: { xs: 0, [MOBILE_BREAKPOINT_THRESHOLD]: 1 } }}>
        {items.map((item, index) =>
          'node' in item ? (
            <React.Fragment key={index}>{item.node}</React.Fragment>
          ) : (
            <Item {...item.config} key={index} onMenuClose={onClose} />
          ),
        )}
      </Box>
      {footer}
    </Box>
  );
};
