import { coreApi } from '../..';
// import { DeclinationDictionaryRequest } from './declinationDictionary/request';
import { DeclinationDictionaryResponse /* declinationDictionary */ } from './declinationDictionary/response';
import { TranslationsRequest } from './translations/request';
import { TranslationsResponse /* translations */ } from './translations/response';
import { CoreApiTranslationTags, coreApiTranslationTagTypes } from './tags';

export const resourceTranslationApi = coreApi
  .enhanceEndpoints({
    addTagTypes: coreApiTranslationTagTypes,
  })
  .injectEndpoints({
    endpoints: (builder) => ({
      getTranslations: builder.query<TranslationsResponse, TranslationsRequest>({
        query: () => ({
          url: 'resource/translations',
        }),
        providesTags: (result, error, { group }) => [{ type: CoreApiTranslationTags.ResourceTranslation, id: group }],
      }),
      getDeclinationDictionary: builder.query<DeclinationDictionaryResponse, void>({
        query: () => ({
          url: 'resource/declinations',
        }),
        providesTags: () => [{ type: CoreApiTranslationTags.ResourceDeclinationDictionary }],
      }),
    }),

    overrideExisting: false,
  });
