import React, { FC, PropsWithChildren, useMemo } from 'react';
import {
  ClientPageResolver,
  ClientWebPageResolverProvider,
  PathGeneratorContextProvider,
  coreRouterApi,
  createRoutesDict,
} from '@cp/entities/clientWebRoutes';
import { createUseAppPathGenerator } from '@youhelp/client.web/app/useAppPathGenerator';

export const ClientWebRouteResolver: FC<PropsWithChildren> = (props) => {
  const { data } = coreRouterApi.endpoints.getClientWebRoutes.useQuery();
  const routesDict = React.useMemo(() => createRoutesDict(data), [data]);
  const resolver = useMemo(() => {
    return new ClientPageResolver(routesDict);
  }, [routesDict]);
  const useAppPathGenerator = createUseAppPathGenerator(routesDict);
  return (
    <ClientWebPageResolverProvider value={resolver}>
      <PathGeneratorContextProvider value={useAppPathGenerator}>{props.children}</PathGeneratorContextProvider>
    </ClientWebPageResolverProvider>
  );
};
