import { Breakpoint } from '@mui/material';
import { AppTheme } from '../../theme';
import { ImageAssetItem } from '../../../../../common/model/resource/image';
import { ImageResponsiveGrid } from './model';

export const breakpoints: Breakpoint[] = ['xs', 'sm', 'md', 'lg', 'xl'];

export const getImageDefaultProps = (assetItem: ImageAssetItem): Required<ImageAssetItem>['images'][number] => {
  const result = {
    path: assetItem.src,
    width: assetItem.width,
    height: assetItem.height,
  };
  let currentWidth = assetItem.width;

  for (const { path, width, height } of assetItem.images ?? []) {
    if (!currentWidth || width > currentWidth) {
      result.path = path;
      result.width = width;
      result.height = height;
      currentWidth = width;
    }
  }

  return result;
};

export const buildDefaultImageReponsiveGrid = (assetItem: ImageAssetItem, bpIndexShifter?: number): ImageResponsiveGrid => {
  if (!assetItem.images) {
    return {};
  }

  return assetItem.images
    .slice()
    .filter((a) => a.width > 48) // ToDo: do not use small client photos from legacy backend
    .sort((a, b) => a.width - b.width)
    .reduce((acc, { width }, index) => {
      const bpIndex = Math.min(index + (bpIndexShifter ?? 0), breakpoints.length - 1);

      acc[breakpoints[bpIndex]] = width;

      return acc;
    }, {} as ImageResponsiveGrid);
};

export const buildImageSources = (assetItem: ImageAssetItem, grid: ImageResponsiveGrid, theme: AppTheme) => {
  const preparedGridEntries = Object.entries(grid).sort((a, b) => breakpoints.indexOf(a[0]) - breakpoints.indexOf(b[0]));

  const imageSources: { media: string; srcSet: string }[] = [];

  if (!assetItem.images?.length) {
    return imageSources;
  }

  for (const [index, [bp, width]] of preparedGridEntries.entries()) {
    const screenWidth = theme.breakpoints.values[bp];

    if (screenWidth === undefined) {
      if (process.env.NODE_ENV !== 'production') {
        console.warn(`Breakpoint "${bp}" not exists! Error occured when building images sources for image asset "${assetItem.src}".`);
      }
      continue;
    }

    const srcSet = assetItem.images?.find(({ width: imgWidth }) => width === imgWidth)?.path;

    if (!srcSet) {
      if (process.env.NODE_ENV !== 'production') {
        console.warn(`Image with width ${width} not found inside image asset "${assetItem.src}".`);
      }
      continue;
    }

    const isLast = index === preparedGridEntries.length - 1;

    if (!isLast) {
      imageSources.push({
        media: `(max-width: ${screenWidth}px)`,
        srcSet,
      });
    } else {
      const prevBP = breakpoints[breakpoints.indexOf(bp) - 1];
      const prevScreenWidth = theme.breakpoints.values[prevBP] ?? 0;

      imageSources.push({
        media: `(min-width: ${prevScreenWidth + 1}px)`,
        srcSet,
      });
    }
  }

  return imageSources;
};
