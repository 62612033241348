import isEqual from 'lodash/isEqual';
import isUndefined from 'lodash/isUndefined';
import omitBy from 'lodash/omitBy';
import { encodeQueryParams } from '@cp/shared/queryParams';
import { generatePath } from 'react-router';
import { CITY_PATH_PARAMETER } from '../const';
import { PathGenerator } from '../pathGenerator';
import { routePathWithCity } from './city';

export const pathGenerator: PathGenerator = ({ page, pageProps = undefined, pathParams: _pathParams, queryParams, pageRoutes }): string => {
  let routePath = '';
  const pathParams = { ..._pathParams };

  if (Array.isArray(pageRoutes)) {
    const targetRoute = pageRoutes.find(({ props }) => isEqual(omitBy(props ?? {}, isUndefined), omitBy(pageProps ?? {}, isUndefined)));

    if (targetRoute) {
      const { path, hasCitySeparatedContent, defaultCityAlias } = targetRoute;

      if (hasCitySeparatedContent) {
        routePath = routePathWithCity(path);
        pathParams[CITY_PATH_PARAMETER] = pathParams.city === defaultCityAlias ? undefined : pathParams.city;
      } else {
        routePath = path;
      }
    } else if (process.env.NODE_ENV === 'development') {
      console.error(`Route not found for page ${page} and page props "${JSON.stringify(pageProps)}"`);
    }
  } else if (process.env.NODE_ENV === 'development') {
    console.error(`Route not found for page ${page}`);
  }

  const queryString = queryParams ? encodeQueryParams(queryParams) : '';

  return `${generatePath(routePath, pathParams as Parameters<typeof generatePath>[1])}${queryString ? `?${queryString}` : ''}`;
};
