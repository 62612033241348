/* eslint-disable prettier/prettier */
export enum DateFormat {
  DateDefault = 'dd.MM.yyyy',
  DateISO = 'yyyy-MM-dd',
  DateTimeISO = 'yyyy-MM-dd\'T\'HH:mm:ssXX',
  CatalogItemDateThisYear = 'dd MMMM',
  CatalogItemDateAnotherYear = 'dd MMMM yyyy',
  DateTimeFullMonth = 'dd MMMM yyyy',
  DateTimePostgreSQL = 'yyyy-MM-dd HH:mm:ss',
  DateTimeModerationTask = 'dd.MM.yyyy HH:mm',
  Time = 'HH:mm',
  DayOfThisWeekShort = 'EEEEEE',
  DayOfWeekFull = 'EEEE',
}
