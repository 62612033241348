import React from 'react';
import LazyLoad from 'react-lazyload';
import { useImageFormat } from '../../hooks/useImageFormat';
import { ImageProps, ViewProps } from './model';
import { buildDefaultImageReponsiveGrid, getImageDefaultProps } from './utils';
import { ViewNormal } from './view.normal';
import { ViewPlaceholder } from './view.placeholder';
export type { ImageProps, ViewProps } from './model';

const DEFAULT_HEIGHT = 10;
const DEFAULT_OFFSET = 900;

export const Image: React.FC<ImageProps> = ({
  asset,
  disableLazyLoad,
  lazyLoadProps,
  webpSupportResolver,
  responsiveGrid,
  ...restProps
}) => {
  const format = useImageFormat({ webpSupportResolver });

  const formatDependentViewProps = React.useMemo(() => {
    const assetItem = format === 'webp' && asset.webp ? asset.webp : asset.base;
    const imgDefaultProps = getImageDefaultProps(assetItem);

    return {
      assetItem,
      src: imgDefaultProps.path,
      // width: imgDefaultProps.width,
      // height: imgDefaultProps.height,
      // ToDo: remove shifter = "1" if "xs" breakpoint value will be more than 0
      responsiveGrid: responsiveGrid ?? buildDefaultImageReponsiveGrid(assetItem, 1),
    };
  }, [asset.base, asset.webp, format, responsiveGrid]);

  const viewProps: ViewProps = {
    ...restProps,
    ...formatDependentViewProps,
  };
  const NormalViewComponent = format ? ViewNormal : ViewPlaceholder;

  const placeholderView = lazyLoadProps?.placeholder ?? <ViewPlaceholder {...viewProps} />;
  const normalView = <NormalViewComponent {...viewProps} />;

  return disableLazyLoad ? (
    normalView
  ) : (
    <LazyLoad height={DEFAULT_HEIGHT} offset={DEFAULT_OFFSET} placeholder={placeholderView} {...lazyLoadProps} resize>
      {normalView}
    </LazyLoad>
  );
};
