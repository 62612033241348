import React from 'react';
import { SvgIcon } from '../../components/svgIcon';
import { SvgIconProps } from '../../components/svgIcon/model';

export const YoutubeIcon = React.forwardRef<SVGSVGElement, SvgIconProps>((props, ref) => {
  return (
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    <SvgIcon cacheName="social/youtube" ref={ref as any} {...props}>
      <path
        clipRule="evenodd"
        d="M19.9888 6.02019C20.2836 6.31688 20.4953 6.68579 20.6027 7.09C21 8.58193 21 11.6932 21 11.6932C21 11.6932 21 14.8044 20.6027 16.2963C20.4953 16.7005 20.2836 17.0694 19.9888 17.3661C19.694 17.6628 19.3265 17.8769 18.923 17.9869C17.4375 18.3863 11.5 18.3863 11.5 18.3863C11.5 18.3863 5.5625 18.3863 4.07704 17.9869C3.67353 17.8769 3.30598 17.6628 3.01118 17.3661C2.71639 17.0694 2.50469 16.7005 2.39727 16.2963C2 14.8044 2 11.6932 2 11.6932C2 11.6932 2 8.58193 2.39727 7.09C2.50469 6.68579 2.71639 6.31688 3.01118 6.02019C3.30598 5.7235 3.67353 5.50944 4.07704 5.39943C5.5625 5 11.5 5 11.5 5C11.5 5 17.4375 5 18.923 5.39943C19.3265 5.50944 19.694 5.7235 19.9888 6.02019ZM14.5228 11.6949L9.55691 8.86969V14.52L14.5228 11.6949Z"
        fillRule="evenodd"
      />
    </SvgIcon>
  );
});
