import { s as t } from "./styled-egLbU9Lr.mjs";
import { getCssVar as o, getCssToken as x } from "./utils.mjs";
import { Box as d } from "@mui/system";
import { Box as L } from "@mui/system";
import { jsxs as r, jsx as u } from "react/jsx-runtime";
import h from "react";
const p = "Typography", I = t("em", { name: p, slot: "Accent" })({
  fontStyle: "normal",
  color: o(p, ["color", "accent"], x("color", ["text", "accent"]))
}), k = t(d, { name: "Collapse", slot: "root" })({
  display: "flex",
  alignItems: "flex-start",
  flexDirection: "column",
  flex: "1 1 0"
}), C = t(d, { name: "Collapse", slot: "container" })({
  flex: "1 1 0"
}), R = ({ open: e, header: l, footer: s, children: i, ...c }) => /* @__PURE__ */ r(k, { ...c, children: [
  l,
  /* @__PURE__ */ u(C, { sx: { overflow: e ? "visible" : "hidden" }, children: i }),
  s
] }), $ = t("img")({ border: 0, display: "block", color: "transparent" }), a = "Container", v = t("div", { name: a, slot: "root" })({
  maxWidth: o(a, "width"),
  marginLeft: "auto",
  marginRight: "auto",
  boxSizing: "content-box",
  paddingInlineStart: o(a, "offset"),
  paddingInlineEnd: o(a, "offset"),
  containerType: "inline-size"
}), n = "Article", E = t("article", { name: n })(({ theme: e }) => ({
  ...e.typography.default,
  p: {
    ...e.typography.default,
    marginBlockStart: o(n, "gap"),
    marginBlockEnd: o(n, "gap")
  },
  section: {
    marginBlockStart: o(n, "gap"),
    marginBlockEnd: o(n, "gap")
  }
})), b = "Details", w = t("details", { name: b })({
  "& > summary": {
    display: "flex",
    flexFlow: "row nowrap"
  },
  "& > summary::-webkit-details-marker": {
    display: "none"
  },
  "& > summary::marker": {
    display: "none"
  }
}), j = ({ open: e = !1, children: l, summary: s, marker: i, renderMarker: c = () => null, ...g }) => {
  const [m, f] = h.useState(e);
  return /* @__PURE__ */ r(
    w,
    {
      open: m || e,
      onToggle: (y) => {
        f(y.currentTarget.open);
      },
      ...g,
      children: [
        /* @__PURE__ */ r("summary", { children: [
          s,
          /* @__PURE__ */ r("span", { className: "Details-Marker", children: [
            i,
            c(m)
          ] })
        ] }),
        l
      ]
    }
  );
};
export {
  E as Article,
  L as Box,
  R as Collapse,
  v as Container,
  j as Details,
  $ as Image,
  I as TypographyAccent
};
