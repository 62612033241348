import React from 'react';
import { SvgIcon } from '../components/svgIcon';
import { SvgIconProps } from '../components/svgIcon/model';

export const UserIcon = React.forwardRef<SVGSVGElement, SvgIconProps>((props, ref) => {
  return (
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    <SvgIcon cacheName="user" ref={ref as any} {...props}>
      <path
        d="M11.984 11.526a3.504 3.504 0 0 1-3.498-3.498c0-1.927 1.571-3.528 3.498-3.528a3.504 3.504 0 0 1 3.498 3.498c0 1.927-1.57 3.528-3.498 3.528ZM12 6c-1.275 0-2 .725-2 2s.71 2 1.984 2C13.26 10 14 9.273 14 7.998S13.275 6 12 6ZM16.727 19.5H7.242A1.55 1.55 0 0 1 5.7 17.959v-.208c0-2.58 1.541-4.891 3.943-5.84.385-.148.86-.178 1.274 0a3.379 3.379 0 0 0 2.164 0 1.88 1.88 0 0 1 1.275 0c2.4.978 3.942 3.26 3.942 5.84v.178c0 .86-.711 1.571-1.57 1.571Zm-6.405-6.03c-.06 0-.148 0-.207.03-1.957.77-2.97 1.79-2.97 3.895v.178a.36.36 0 0 0 .355.356l9 .03a.36.36 0 0 0 .356-.356v-.178c0-2.105-.955-3.154-2.911-3.925-.119-.06-.267-.06-.445 0a4.35 4.35 0 0 1-3 0c0-.03-.089-.03-.178-.03Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
});
