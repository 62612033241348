/* eslint-disable @typescript-eslint/no-magic-numbers */
import React from 'react';
import styled from '@mui/material/styles/styled';
import { alpha, shouldForwardProp } from '@mui/system';
import { ButtonStatePropsForDescedants, ButtonTagProps } from '../model';
import { BUTTON_VERTICAL_SPACING, buttonColorToThemeColor, buttonSizeDict, buttonSquareSizeDict } from '../const';
import { getButtonColor } from '../utils';

type UnstyledButtonComponent = React.ComponentClass<ButtonTagProps & { ownerState: ButtonStatePropsForDescedants }>;

export const StyledButton = styled('button' as unknown as UnstyledButtonComponent, {
  shouldForwardProp,
  name: 'Button',
  slot: 'Root',
})(({ theme, ownerState }) => {
  const commonStyles: React.CSSProperties = {
    backgroundColor: 'transparent',
    color: getButtonColor({ ownerState, theme }).buttonColor,
    borderWidth: 0,
    borderStyle: 'none',
    borderColor: 'transparent',
    boxSizing: 'border-box',
  };
  const hasIcon = Boolean(ownerState.iconLeft ?? ownerState.iconRight);
  const { color, borderColor, hoverColor, disabledColor } = buttonColorToThemeColor[ownerState.color];
  const activeBoxShadowBaseColor = theme.palette.black;
  let activeBoxShadowOpacity = 0.11;
  let buttonVerticalSpacing = BUTTON_VERTICAL_SPACING;

  if (ownerState.square) {
    commonStyles.width = buttonSquareSizeDict[ownerState.size].size;
    commonStyles.height = buttonSquareSizeDict[ownerState.size].size;
    commonStyles.display = 'inline-flex';
    commonStyles.justifyContent = 'center';
    commonStyles.alignItems = 'center';

    commonStyles.paddingLeft = 0;
    commonStyles.paddingRight = 0;
  } else if (ownerState.size === 'extraSmall') {
    buttonVerticalSpacing = 8;

    commonStyles.paddingLeft = ownerState.iconLeft ? 13 : hasIcon ? 11 : 16;
    commonStyles.paddingRight = ownerState.iconRight ? 13 : hasIcon ? 11 : 16;
  } else if (ownerState.size === 'small' || ownerState.variant === 'text') {
    buttonVerticalSpacing = 10;

    commonStyles.paddingLeft = ownerState.iconLeft ? 13 : hasIcon ? 11 : 16;
    commonStyles.paddingRight = ownerState.iconRight ? 13 : hasIcon ? 11 : 16;
  } else {
    buttonVerticalSpacing = hasIcon ? 12 : 16;

    commonStyles.paddingLeft = ownerState.iconLeft ? 2 : hasIcon ? 0 : ownerState.variant === 'primary' ? 48 : 18;
    commonStyles.paddingRight = ownerState.iconRight ? 2 : hasIcon ? 0 : ownerState.variant === 'primary' ? 48 : 18;
  }

  if (ownerState.variant === 'primary') {
    commonStyles.backgroundColor = theme.palette[
      buttonColorToThemeColor[ownerState.color].backgroundColor ??
        (ownerState.disabled ? (ownerState.color === 'white' ? 'white' : disabledColor) : color)
    ] as string;

    if (ownerState.color === 'gray') {
      activeBoxShadowOpacity = 0.25;
    } else if (ownerState.color === 'pink') {
      activeBoxShadowOpacity = 0.14;
    }
  } else if (ownerState.variant === 'secondary') {
    commonStyles.borderWidth = 1;
    commonStyles.borderStyle = 'solid';
    commonStyles.borderColor = theme.palette[ownerState.disabled ? disabledColor : borderColor] as string;
    // commonStyles.backgroundColor = theme.palette[ownerState.color === 'white' ? 'brand gray' : 'white'];
  }

  const paddingVertical = ownerState.square ? 0 : buttonVerticalSpacing - (commonStyles.borderWidth as number);

  return {
    ...theme.typography.button,
    ...commonStyles,
    position: 'relative',
    overflow: 'hidden',
    display: 'inline-flex',
    flexWrap: 'nowrap',
    justifyContent: 'center',
    alignItems: 'center',
    textTransform: 'none',
    cursor: ownerState.disabled ? 'not-allowed' : 'pointer',
    userSelect: ownerState.disabled ? 'none' : 'auto',
    transition: theme.transitions.create(['background-color', 'border-color', 'color', 'text-decoration-color'], {
      duration: theme.transitions.duration.short,
    }),
    WebkitTapHighlightColor: 'transparent',
    paddingTop: paddingVertical,
    paddingBottom: paddingVertical,

    ...(ownerState.variant === 'text'
      ? {
          padding: 0,
          // textDecorationColor: alpha(commonStyles.color, theme.palette.action.hoverOpacity),
          // textUnderlinePosition: 'under',
        }
      : {
          '&:active': {
            outline: 'none',
            ...(!ownerState.disabled && {
              boxShadow: `inset 1px 2px 4px ${alpha(activeBoxShadowBaseColor, activeBoxShadowOpacity)}`,
              ...(ownerState.variant === 'secondary' &&
                ownerState.color === 'gray ultra light' && {
                  borderColor: theme.palette['brand gray'],
                  '& > *': {
                    borderColor: theme.palette['brand gray'],
                  },
                }),
            }),
          },
          borderRadius: buttonSizeDict[ownerState.size].borderRadius,
        }),

    '&:focus': {
      outline: 'none',
    },

    '@media (pointer: fine)': {
      '&:hover': !ownerState.disabled && {
        ...(ownerState.variant === 'primary' && {
          backgroundColor: theme.palette[hoverColor],
        }),
        ...(ownerState.variant === 'secondary' && {
          backgroundColor: theme.palette[ownerState.color === 'white' ? 'gray' : 'gray ultra light'],
        }),
        ...(ownerState.variant === 'secondary' &&
          ownerState.color === 'gray ultra light' && {
            borderColor: theme.palette['brand gray'],
            '& > *': {
              borderColor: theme.palette['brand gray'],
            },
          }),
        ...(ownerState.variant === 'text' && {
          color: theme.palette[hoverColor],
        }),
      },
    },
  };
});
