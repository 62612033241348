import React from 'react';
import { IconContainer } from '../../iconContainer';
import { PlusIcon } from '../../../../../icons/plus';
import { AvatarIcon } from '../../../../../icons/avatar';
import { Image } from '../../../../image';
import { mergeSx } from '../../../../../utils/sx/merge';
import { getAvatarSizeStyles } from '../../../utils';
import { getAvatarContentSizeStyles } from '../utils';
import { IMAGE_FALLBACK_SIZE_TO_CONTAINER_SIZE, PLUS_ICON_SIZE_DEFAULT, PLUS_ICON_SIZE_TO_CONTAINER_SIZE } from './const';
import { AvatarImageContentProps } from './model';

export const AvatarImageContent: React.FC<AvatarImageContentProps> = ({
  theme,
  containerSize,
  size,
  backgroundColor,
  clickAction,
  onClick,
  asset,
  alt = '',
  lazyLoadProps,
}) => {
  const [isLoadingError, setLoadingError] = React.useState(false);

  const plusSize = `${typeof containerSize === 'number' ? containerSize * PLUS_ICON_SIZE_TO_CONTAINER_SIZE : PLUS_ICON_SIZE_DEFAULT}rem`;

  return (
    <React.Fragment>
      {asset ? (
        <Image
          alt={alt}
          asset={asset}
          lazyLoadProps={lazyLoadProps}
          onError={() => {
            setLoadingError(true);
          }}
          onLoad={() => {
            setLoadingError(false);
          }}
          sx={mergeSx(
            {
              borderRadius: { xs: `${theme.borderRadius.normal}px`, md: `${theme.borderRadius.medium}px` },
              position: 'relative',
              zIndex: 1,
            },
            getAvatarSizeStyles(containerSize),
          )}
        />
      ) : null}
      {(!asset || isLoadingError) && (
        <IconContainer
          sx={{
            alignItems: onClick ? 'center' : 'flex-end',
            backgroundColor,
          }}
          theme={theme}
        >
          {clickAction === 'change' ? (
            <PlusIcon sx={{ fontSize: plusSize }} />
          ) : (
            <AvatarIcon
              color="gray middle"
              sx={getAvatarContentSizeStyles(size, {
                containerSize,
                contentSizeToContainerSizeRate: IMAGE_FALLBACK_SIZE_TO_CONTAINER_SIZE,
              })}
            />
          )}
        </IconContainer>
      )}
    </React.Fragment>
  );
};
