import React from 'react';
import { TYPOGRAPHY_COMPONENT_DEFAULT, TypographyOwnerState, TypographyProps /*, TypographyTypeMap */ } from './model';
import { TYPOGRAPHY_VARIANT_DEFAULT } from './const';
import { TypographyRoot } from './components/root';
export type { TypographyProps } from './model';

export const Typography = React.forwardRef<HTMLElement, React.PropsWithChildren<TypographyProps>>(
  ({ component = TYPOGRAPHY_COMPONENT_DEFAULT, variant = TYPOGRAPHY_VARIANT_DEFAULT, numberOfLines, ...props }, ref) => {
    const ownerState: TypographyOwnerState = {
      component,
      variant,
      numberOfLines,
    };

    return <TypographyRoot as={component} ownerState={ownerState} ref={ref} {...props} />;
  },
);
