import { ClientCatalogItemViewModel } from '@common/model/client/catalog/item/clientCatalogItemViewModel';
import { ClientProfileViewModel } from '@common/model/client';
import { ClientPatronageStage } from '@common/model/admin/clientPatronage/stage';

export const checkPatronageCompleted = (clientProfile: ClientProfileViewModel, mainResume: ClientCatalogItemViewModel): boolean => {
  if (!mainResume.isPublished) {
    return false;
  }
  // ToDo: maybe remove when all migrated from old backend resumes will be processed by moderators
  if (!clientProfile.patronageStage) {
    return true;
  }
  return clientProfile.patronageStage === ClientPatronageStage.Completed;
};
