import { isNodeEnv } from '../env/runtime';

const mockImages = {
  lossy: 'UklGRiIAAABXRUJQVlA4IBYAAAAwAQCdASoBAAEADsD+JaQAA3AAAAAA',
  lossless: 'UklGRhoAAABXRUJQVlA4TA0AAAAvAAAAEAcQERGIiP4HAA==',
  alpha: 'UklGRkoAAABXRUJQVlA4WAoAAAAQAAAAAAAAAAAAQUxQSAwAAAARBxAR/Q9ERP8DAABWUDggGAAAABQBAJ0BKgEAAQAAAP4AAA3AAP7mtQAAAA==',
  animation: 'UklGRlIAAABXRUJQVlA4WAoAAAASAAAAAAAAAAAAQU5JTQYAAAD/////AABBTk1GJgAAAAAAAAAAAAAAAAAAAGQAAABWUDhMDQAAAC8AAAAQBxAREYiI/gcA',
};

export type WebpSupportedFeaturesDictionary = {
  [feature in keyof typeof mockImages]: boolean;
};

const webpSupportedFeatures: WebpSupportedFeaturesDictionary = Object.keys(mockImages).reduce((acc, feature) => {
  acc[feature] = false;
  return acc;
}, {} as WebpSupportedFeaturesDictionary);

const testingPromise = Promise.all(
  Object.keys(mockImages).map(
    (feature) =>
      new Promise<void>((resolve) => {
        if (isNodeEnv()) {
          resolve();
          return;
        }

        const img = new Image();
        img.onload = () => {
          webpSupportedFeatures[feature] = img.width > 0 && img.height > 0;
          resolve();
        };
        img.onerror = () => {
          resolve();
        };
        img.src = 'data:image/webp;base64,' + mockImages[feature];
      }),
  ),
);

export const getWebpSupport = (): Promise<WebpSupportedFeaturesDictionary> => {
  return testingPromise.then(() => webpSupportedFeatures);
};
