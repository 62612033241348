import React, { HTMLAttributes } from 'react';
import { Variant as MuiVariant } from '@mui/material/styles/createTypography';
// import { OverrideProps } from '@mui/material/OverridableComponent';
import { SxProps } from '../../theme/model';

// Update the Typography's variant prop options
declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    // Add custom variants
    'h0': true;
    'h0 thin': true;
    'h1 thin': true;
    'h2 thin': true;
    'h3 thin': true;
    'h4 thin': true;
    'h5 thin': true;
    'h6 Bold': true;
    'button secondary': true;
    'body bold': true;
    'body': true;
    'caption bold': true;
    'small text': true;
    'extraSmall': true;

    // Disable variants
    subtitle2: false;
    subtitle1: false;
    body1: false;
    body2: false;
    overline: false;
  }
}

export type Variant =
  | Exclude<MuiVariant, 'subtitle2' | 'subtitle1' | 'body1' | 'body2' | 'overline'>
  | 'h0'
  | 'h0 thin'
  | 'h1 thin'
  | 'h2 thin'
  | 'h3 thin'
  | 'h4 thin'
  | 'h5 thin'
  | 'h6 Bold'
  | 'button secondary'
  | 'button bold'
  | 'body bold'
  | 'body'
  | 'caption bold'
  | 'small text'
  | 'small text bold'
  | 'extraSmall';

export const TYPOGRAPHY_COMPONENT_DEFAULT = 'span' as const;
export type TypographyComponentDefault = typeof TYPOGRAPHY_COMPONENT_DEFAULT;

export interface TypographyOwnProps {
  component?: React.ElementType;
  variant?: Variant;
  numberOfLines?: number;
  sx?: SxProps;
}

export type TypographyOwnerState = Required<Pick<TypographyOwnProps, 'component' | 'variant'>> & Pick<TypographyOwnProps, 'numberOfLines'>;

// export interface TypographyTypeMap<D extends React.ElementType = TypographyComponentDefault> {
//   props: TypographyOwnProps;
//   defaultComponent: D;
// }

// export type TypographyProps<D extends React.ElementType = TypographyTypeMap['defaultComponent']> = OverrideProps<TypographyTypeMap<D>, D>;

export interface TypographyProps<T extends HTMLElement = HTMLElement> extends TypographyOwnProps, HTMLAttributes<T> {}
