import React from 'react';
import { IconContainer } from '../../iconContainer';
import { getAvatarContentSizeStyles } from '../utils';
import { mergeSx } from '../../../../../utils/sx/merge';
import { AvatarIconContentProps } from './model';
import { ICON_SIZE_TO_CONTAINER_SIZE } from './const';

export const AvatarIconContent: React.FC<AvatarIconContentProps> = ({
  theme,
  backgroundColor,
  size,
  containerSize,
  icon: Icon,
  iconProps,
}) => {
  return (
    <IconContainer
      sx={{
        backgroundColor,
      }}
      theme={theme}
    >
      <Icon
        {...iconProps}
        sx={mergeSx(
          getAvatarContentSizeStyles(size, {
            containerSize,
            contentSizeToContainerSizeRate: ICON_SIZE_TO_CONTAINER_SIZE,
          }),
          iconProps?.sx,
        )}
      />
    </IconContainer>
  );
};
