import { Manager } from 'socket.io-client';
import { config } from '../../config';
import { createSocketManager } from './createManager';

let manager: Manager | undefined;

export const getManager = (): Manager => {
  if (manager) {
    return manager;
  }
  manager = createSocketManager({ ...config });
  return manager;
};

export const useSocketManager = () => {
  return getManager();
};
