import React from 'react';
import { SvgIcon, SvgIconProps } from '../../components/svgIcon';

export const InviteActiveIcon = React.forwardRef<SVGSVGElement, SvgIconProps>((props, ref) => {
  return (
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    <SvgIcon cacheName="mobileMenu/inviteActive" ref={ref as any} {...props}>
      <rect
        fill="#FFCEC3"
        height="14.0625"
        mask="url(#path-1-inside-1_7672_188088)"
        rx="1"
        stroke="#405368"
        strokeWidth="1.2"
        width="13.125"
        x="5.25"
        y="5.4375"
      />
      <path
        clipRule="evenodd"
        d="M10.0449 9.35212L9.28365 10.1134L9.033 9.86272C8.81351 9.64322 8.44684 9.64322 8.22734 9.86272C8.21678 9.87328 8.20672 9.88419 8.19717 9.89541C8.00812 10.1172 8.0181 10.4596 8.22712 10.6686L8.8806 11.3221C9.10009 11.5416 9.46676 11.5416 9.68625 11.3221L9.69015 11.3182L10.8506 10.1578C11.0701 9.93828 11.0701 9.57161 10.8506 9.35212C10.6309 9.13263 10.2643 9.13263 10.0449 9.35212ZM13.0452 9.76749H15.4669C15.7775 9.76749 16.0366 10.0266 16.0366 10.3372C16.0366 10.6477 15.7775 10.9068 15.4669 10.9068H14.7963H12.3745C12.064 10.9068 11.8048 10.6477 11.8048 10.3372C11.8048 10.0266 12.064 9.76749 12.3745 9.76749H13.0452ZM14.7964 15.4511H12.3747C12.0641 15.4511 11.805 15.1919 11.805 14.8814C11.805 14.5708 12.0641 14.3117 12.3747 14.3117H13.0454H15.4671C15.7776 14.3117 16.0368 14.5708 16.0368 14.8814C16.0368 15.1919 15.7776 15.4511 15.4671 15.4511H14.7964ZM9.53872 14.0757L9.80505 13.8094C10.0245 13.5899 10.3912 13.5899 10.6107 13.8094C10.8302 14.0289 10.8302 14.3955 10.6107 14.615L10.3444 14.8814L10.6107 15.1477C10.8302 15.3672 10.8302 15.7339 10.6107 15.9534C10.3912 16.1729 10.0246 16.1729 9.80508 15.9534L9.53872 15.687L9.27258 15.9531C9.05309 16.1726 8.68642 16.1726 8.46693 15.9531C8.24743 15.7337 8.24743 15.367 8.46693 15.1475L8.73306 14.8814L8.46695 14.6152C8.24758 14.3958 8.24758 14.0291 8.46695 13.8096C8.68645 13.5901 9.05312 13.5901 9.27261 13.8096L9.53872 14.0757Z"
        fill="#405368"
        fillRule="evenodd"
      />
      <rect fill="#FFCEC3" height="1.4125" rx="0.70625" stroke="#405368" strokeWidth="1.4" width="4.225" x="9.7" y="5.2" />
    </SvgIcon>
  );
});
