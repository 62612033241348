import React, { useMemo } from 'react';

export type Menu = {
  title: string;
  id: string;
  items: Array<{ label: string; link: string | URL; external?: boolean }>;
};

export const useUsersMenuItems = (): Menu => {
  return useMemo(
    () => ({
      title: 'Пользователям',
      id: 'for-users',
      items: [
        {
          label: 'Оплата',
          link: '/payment-policy/',
        },
        {
          label: 'Возврат',
          link: '/payment-policy/#refund',
        },
        {
          label: 'Правила использования',
          link: '/user-agreement/',
        },
        {
          label: 'Политика конфиденциальности',
          link: '/privacy-policy/',
        },
      ],
    }),
    [],
  );
};

export const useAboutMenuItems = (): Menu => {
  return React.useMemo(
    () => ({
      title: 'О компании',
      id: 'about-company',
      items: [
        {
          label: 'Поддержка',
          link: '/support/',
        },
        {
          label: 'Контакты',
          link: '/contacts/',
        },
      ],
    }),
    [],
  );
};
