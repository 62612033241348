import { DeclinationMnemonic } from '@common/model/resource/declinationMnemonic';
import React from 'react';

export interface IDeclinationContextValue {
  declination: (number: number, mnemonic: DeclinationMnemonic) => string;
}

export const DeclinationContext = React.createContext<IDeclinationContextValue>({
  declination: () => {
    throw new Error('Declination is not provided');
  },
});
