/* eslint-disable @typescript-eslint/no-magic-numbers */
import React from 'react';
import { shouldForwardProp } from '@mui/system';
import { styled } from '../../../theme/styled';
import { ButtonStatePropsForDescedants } from '../model';
import { BUTTON_ICON_HORIZONTAL_SPACING_NORMAL } from '../const';
import { getButtonColor } from '../utils';

type UnstyledIconComponent = React.ComponentClass<
  React.DetailedHTMLProps<React.HTMLAttributes<HTMLSpanElement>, HTMLSpanElement> & { ownerState: ButtonStatePropsForDescedants }
>;

export const StyledIcon = styled('span' as unknown as UnstyledIconComponent, {
  shouldForwardProp,
  name: 'ButtonIcon',
  slot: 'Icon',
})(({ theme, ownerState }) => {
  const { buttonColor, dividerColor } = getButtonColor({ ownerState, theme });

  const commonStyles: React.CSSProperties = {
    // color: buttonColor,
  };

  if (ownerState.variant !== 'text') {
    if (ownerState.size === 'normal') {
      const side = ownerState.iconLeft ? 'Right' : 'Left';

      commonStyles[`border${side}Width`] = 1;
      commonStyles[`border${side}Style`] = 'solid';
      commonStyles[`border${side}Color`] = dividerColor;

      commonStyles.paddingLeft = BUTTON_ICON_HORIZONTAL_SPACING_NORMAL;
      commonStyles.paddingRight = BUTTON_ICON_HORIZONTAL_SPACING_NORMAL;
    }
  } else {
    commonStyles.paddingLeft = ownerState.iconLeft ? 7 : 0;
    commonStyles.paddingRight = ownerState.iconRight ? 7 : 0;
  }

  return {
    transition: theme.transitions.create(['border-color'], {
      duration: theme.transitions.duration.short,
    }),
    display: 'inline-flex',
    justifyContent: 'center',
    alignSelf: 'stretch',
    alignItems: 'center',
    fontSize: 16,
    color: buttonColor,
    ...commonStyles,

    '& > *:nth-of-type(1)': {
      fontSize: ownerState.size === 'small' ? 18 : 24,
    },
  };
});
