/* eslint-disable @typescript-eslint/no-magic-numbers */
import React from 'react';
import { alpha } from '@mui/system';
import { styled } from '../../../theme/styled';
import { iconButtonSizeDict, iconButtonVariantDict } from '../const';
import { ICON_BUTTON_COMPONENT_DEFAULT, IconButtonOwnerState } from '../model';

export const IconButtonRoot = styled(ICON_BUTTON_COMPONENT_DEFAULT, {
  name: 'IconButton',
  slot: 'Root',
})<{ ownerState: IconButtonOwnerState }>(({ theme, ownerState }) => {
  const { variant } = ownerState;
  const { color, colorDisabled, backgroundColor, backgroundColorHover, borderColor, borderColorHover, borderColorDisabled } =
    iconButtonVariantDict[variant][ownerState.color];

  const { sizeInRem } = iconButtonSizeDict[ownerState.size];

  const commonStyles: React.CSSProperties = {
    width: `${sizeInRem}rem`,
    height: `${sizeInRem}rem`,
    backgroundColor: theme.palette[backgroundColor] as string,
    color: theme.palette[ownerState.disabled ? colorDisabled : color] as string,
    // eslint-disable-next-line @typescript-eslint/no-base-to-string
    border: `1px solid ${theme.palette[ownerState.disabled ? borderColorDisabled : borderColor]}`,
    borderRadius: `${sizeInRem / 2}rem`,
  };

  const activeBoxShadowBaseColor = theme.palette.black;
  const activeBoxShadowOpacity = 0.11;

  return {
    ...theme.typography['button secondary'],
    ...commonStyles,
    display: 'inline-flex',
    flexWrap: 'nowrap',
    justifyContent: 'center',
    alignItems: 'center',
    textTransform: 'none',
    textDecoration: 'none',
    cursor: ownerState.disabled ? 'not-allowed' : 'pointer',
    transition: theme.transitions.create(['background-color', 'border-color', 'color'], {
      duration: theme.transitions.duration.short,
    }),

    '&:active': {
      outline: 'none',
      ...(!ownerState.disabled && {
        boxShadow: `inset 1px 2px 4px ${alpha(activeBoxShadowBaseColor, activeBoxShadowOpacity)}`,
      }),
    },

    '&:focus': {
      outline: 'none',
    },

    ...(!ownerState.disabled && {
      '@media (pointer: fine)': {
        '&:hover': {
          backgroundColor: theme.palette[backgroundColorHover],
          borderColor: theme.palette[borderColorHover],
        },
      },
    }),

    ...(ownerState.disabled && {
      '& > svg': {
        color: theme.palette[colorDisabled],
      },
    }),
  };
});
