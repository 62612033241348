import { ClientProfileViewModel } from '@common/model/client';
import { ClientType } from '@common/model/client/clientType';
import { ClientWebPage } from '@common/model/meta/clientWebPage';
import { Box } from '@cp/ds/src/components/box';
import { Switch } from '@cp/ds/src/components/switch';
import { Tooltip } from '@cp/ds/src/components/tooltip';
import { useIsMobile } from '@cp/ds/src/hooks/useIsMobile';
import {
  HeartThickIcon,
  InfoIcon,
  PortfolioIcon,
  RubOutlineIcon,
  ScheduleIcon,
  SignoutIcon,
  UserIcon,
  VacanciesIcon,
  WorkIcon,
} from '@cp/ds/src/icons';
import { usePathGenerator } from '@cp/entities/clientWebRoutes';
import { useRouter } from '@cp/shared/router';
import React from 'react';
import { ClientMenuItemSlotDescription } from 'src/entities/client/current/menu';
import { getClientIsWorkingContent } from 'src/entities/client/current/working';
import { useLogoutProcessStart } from 'src/features/client/current/logout';
import { useWithShowPatronageHints } from 'src/features/client/current/patronage';
import { useCurrentClientWorking } from 'src/features/client/current/working';
import { IMainResumeViewHandle } from 'src/features/mainResume/view';

export const useItems = (props: {
  clientProfile: ClientProfileViewModel | undefined;
  mainResumeViewRef: React.RefObject<IMainResumeViewHandle>;
}): ClientMenuItemSlotDescription[] => {
  const router = useRouter();
  const { clientProfile, mainResumeViewRef } = props;
  const isMobile = useIsMobile();
  const pathGenerator = usePathGenerator();
  const [startLogout] = useLogoutProcessStart();
  const [changeIsWorking, { isWorking, isWorkingAvailable }] = useCurrentClientWorking();
  const [withShowPatronageHints] = useWithShowPatronageHints();

  return React.useMemo(() => {
    if (!clientProfile) {
      return [];
    }

    if (clientProfile.type === ClientType.Specialist) {
      const viewMainResume = () => {
        withShowPatronageHints(() => mainResumeViewRef.current?.show());
      };

      const goToFavoriteEmployers = () => {
        withShowPatronageHints(() => {
          router.navigate(pathGenerator({ page: ClientWebPage.ProfileFavorites }));
        });
      };

      const workingContent = getClientIsWorkingContent(isWorking);

      return [
        ...(isMobile && isWorkingAvailable
          ? [
              {
                config: {
                  children: (
                    <Box sx={{ display: 'inline-flex', alignItems: 'center' }}>
                      <span>{workingContent.label}</span>
                      <Tooltip
                        arrow
                        colorSchema="dark"
                        enterTouchDelay={0}
                        leaveTouchDelay={9000}
                        title={
                          <Box component="span" sx={{ display: 'inline-block', px: 1.5, py: 1 }}>
                            {workingContent.tooltipText}
                          </Box>
                        }
                      >
                        <Box component="span" sx={{ p: 1, my: -1, color: 'gray', display: 'inline-flex' }}>
                          <InfoIcon fontSize="1.5rem" />
                        </Box>
                      </Tooltip>
                    </Box>
                  ),
                  startIcon: WorkIcon,
                  onClick: () => undefined,
                  closeMenuOnClick: false,
                  borderBottom: true,
                  endAdornment: <Switch onChange={changeIsWorking} sx={{ flex: '0 0 0px' }} value={isWorking} />,
                },
              },
            ]
          : []),
        { config: { children: 'Обо мне', startIcon: UserIcon, href: pathGenerator({ page: ClientWebPage.Profile }) } },
        { config: { children: 'Анкета и отзывы', startIcon: PortfolioIcon, onClick: viewMainResume } },
        { config: { children: 'Расписание', startIcon: ScheduleIcon, href: pathGenerator({ page: ClientWebPage.ProfileSchedule }) } },
        {
          config: {
            children: 'Избранные наниматели',
            startIcon: HeartThickIcon,
            onClick: goToFavoriteEmployers,
          },
        },
        {
          config: {
            children: 'Выход',
            startIcon: SignoutIcon,
            onClick: startLogout,
          },
        },
      ];
    } else {
      return [
        { config: { children: 'Обо мне', startIcon: UserIcon, href: pathGenerator({ page: ClientWebPage.Profile }) } },
        { config: { children: 'Мои объявления', startIcon: VacanciesIcon, href: pathGenerator({ page: ClientWebPage.ProfileVacancies }) } },
        {
          config: {
            children: 'Избранные специалисты',
            startIcon: HeartThickIcon,
            href: pathGenerator({ page: ClientWebPage.ProfileFavorites }),
          },
        },
        {
          config: {
            children: 'Оплата',
            startIcon: RubOutlineIcon,
            href: pathGenerator({ page: ClientWebPage.ProfileBilling }),
          },
        },
        {
          config: {
            children: 'Выход',
            startIcon: SignoutIcon,
            onClick: startLogout,
          },
        },
      ];
    }
  }, [
    changeIsWorking,
    clientProfile,
    router,
    isMobile,
    isWorking,
    isWorkingAvailable,
    mainResumeViewRef,
    pathGenerator,
    startLogout,
    withShowPatronageHints,
  ]);
};
