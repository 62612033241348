import React from 'react';
import { LOGOUT_MODAL_NAME } from 'src/app/shared/components/modals/logout/const';
import { useModal } from 'src/app/shared/hooks/useModal';

export const useLogoutProcessStart = () => {
  const { openModal } = useModal();

  return [
    React.useCallback(() => {
      openModal(LOGOUT_MODAL_NAME);
    }, [openModal]),
  ];
};
