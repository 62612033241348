import React from 'react';
import { UseFormReturn, useFormContext } from 'react-hook-form';
import { StyledButton } from './components/styledButton';
import { StyledIcon } from './components/styledIcon';
import { StyledLabel } from './components/styledLabel';
import { BUTTON_COLOR_DEFAULT, BUTTON_VARIANT_DEFAULT } from './const';
import { BUTTON_COLOR, BUTTON_VARIANT, ButtonProps } from './model';
export type { ButtonProps } from './model';

export const Button = React.forwardRef<HTMLButtonElement, ButtonProps>((props, ref) => {
  const {
    children,
    square = false,
    variant: _variant = BUTTON_VARIANT_DEFAULT,
    color: _color = BUTTON_COLOR_DEFAULT,
    size = 'normal',
    loading = false,
    icon,
    iconLeft,
    iconRight: _iconRight,
    tabIndex = 0,
    type = 'button',
    labelSx,
    ...buttonTagProps
  } = props;

  let form: UseFormReturn | undefined;
  try {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    form = useFormContext();
  } catch {
    // If we are not inside form, just do nothing
  }

  if (__DEV__) {
    if (!BUTTON_VARIANT.includes(_variant)) {
      console.error(`Button variant "${_variant}" is not supported! Migrate to new prop values.`);
    }
    if (!BUTTON_COLOR.includes(_color)) {
      console.error(`Button color "${_color}" is not supported! Migrate to new prop values.`);
    }
  }

  const variant = BUTTON_VARIANT.includes(_variant) ? _variant : BUTTON_VARIANT_DEFAULT;
  const color = BUTTON_COLOR.includes(_color) ? _color : BUTTON_COLOR_DEFAULT;
  const iconRight = _iconRight ?? icon;
  const ownerState = {
    square,
    variant,
    color,
    disabled: loading || (buttonTagProps.disabled ?? false) || (type === 'submit' && Boolean(form?.formState.isSubmitting)),
    size,
    loading,
    iconLeft,
    iconRight,
  };

  return (
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    <StyledButton ownerState={ownerState} ref={ref as any} tabIndex={tabIndex} type={type} {...buttonTagProps}>
      {!square && iconLeft && <StyledIcon ownerState={ownerState}>{iconLeft}</StyledIcon>}
      <StyledLabel ownerState={ownerState} sx={labelSx}>
        {children}
      </StyledLabel>
      {!square && iconRight && <StyledIcon ownerState={ownerState}>{iconRight}</StyledIcon>}
    </StyledButton>
  );
});
