import React from 'react';
import { SvgIcon } from '../components/svgIcon';
import { SvgIconProps } from '../components/svgIcon/model';

export const AvatarIcon = React.forwardRef<SVGSVGElement, SvgIconProps>((props, ref) => {
  return (
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    <SvgIcon cacheName="avatar" ref={ref as any} {...props}>
      <path
        d="M2.32288 23.6841C2.48898 19.8252 5.67968 16.7368 9.57901 16.7368H14.6316C18.5309 16.7368 21.7223 19.8252 21.8877 23.6841H2.32288Z"
        fill="currentColor"
      />
      <path
        d="M14.6314 17.0527C18.2497 17.0527 21.2301 19.8323 21.5503 23.3684H2.65999C2.9802 19.8323 5.96059 17.0527 9.57887 17.0527H14.6314ZM14.6314 16.4211H9.57887C5.39344 16.4211 2 19.8146 2 24H22.2103C22.2103 19.8146 18.8169 16.4211 14.6314 16.4211Z"
        fill="currentColor"
      />
      <path
        d="M12.1051 18.6314C10.0721 18.6314 9.38369 17.3045 9.26306 17.0298V13.5789H14.9472V17.0298C14.8285 17.3013 14.1407 18.6314 12.1051 18.6314Z"
        fill="currentColor"
      />
      <path
        d="M14.6315 13.8948V16.961C14.4793 17.2692 13.8345 18.3158 12.1052 18.3158C10.3811 18.3158 9.73306 17.2692 9.57896 16.9598V13.8948H14.6315ZM15.2631 13.2632H8.94739V17.0918C8.94739 17.0918 9.62064 18.9473 12.1052 18.9473C14.5899 18.9473 15.2631 17.0918 15.2631 17.0918V13.2632Z"
        fill="currentColor"
      />
      <path
        d="M16.6165 11.5035C15.7954 11.5035 15.1285 10.8353 15.1285 10.0149C15.1285 9.19447 15.7961 8.52627 16.6165 8.52627C17.8563 8.52627 18.1051 8.86605 18.1051 9.41363C18.1051 10.3067 17.3693 11.5035 16.6165 11.5035ZM7.59384 11.5035C6.84101 11.5035 6.10522 10.3067 6.10522 9.41299C6.10522 8.86542 6.3547 8.52563 7.59384 8.52563C8.41489 8.52563 9.08183 9.19321 9.08183 10.0143C9.08183 10.8353 8.41489 11.5035 7.59384 11.5035Z"
        fill="currentColor"
      />
      <path
        d="M16.6165 8.84202C17.7893 8.84202 17.7893 9.14581 17.7893 9.41359C17.7893 10.193 17.1318 11.1883 16.6165 11.1883C15.9697 11.1883 15.4436 10.6622 15.4436 10.0155C15.4436 9.36812 15.9697 8.84202 16.6165 8.84202ZM7.59383 8.84202C8.24056 8.84202 8.76666 9.36812 8.76666 10.0149C8.76666 10.6616 8.24056 11.1877 7.59383 11.1877C7.07847 11.1877 6.421 10.193 6.421 9.41296C6.421 9.14581 6.421 8.84202 7.59383 8.84202ZM16.6165 8.21045C15.6199 8.21045 14.8121 9.01823 14.8121 10.0149C14.8121 11.0115 15.6199 11.8193 16.6165 11.8193C17.6131 11.8193 18.4209 10.4096 18.4209 9.41296C18.4209 8.41697 17.6131 8.21045 16.6165 8.21045ZM7.59383 8.21045C6.59721 8.21045 5.78943 8.41697 5.78943 9.41359C5.78943 10.4102 6.59721 11.8199 7.59383 11.8199C8.59045 11.8199 9.39823 11.0121 9.39823 10.0155C9.39823 9.01886 8.59045 8.21045 7.59383 8.21045Z"
        fill="currentColor"
      />
      <path
        d="M12.1052 16.105C11.7237 16.105 11.365 15.9673 11.0682 15.7071L11.0107 15.6566L10.9374 15.6376C8.83618 15.1046 7.36841 13.2187 7.36841 11.0524V5.18005C7.36841 4.02616 8.30693 3.08765 9.46018 3.08765H14.7496C15.9028 3.08765 16.842 4.02616 16.842 5.18005V11.0524C16.842 13.2187 15.3742 15.1046 13.273 15.6376L13.1997 15.6566L13.1422 15.7071C12.8454 15.9673 12.4867 16.105 12.1052 16.105Z"
        fill="currentColor"
      />
      <path
        d="M14.7495 3.40355C15.729 3.40355 16.5261 4.20059 16.5261 5.18016V11.0525C16.5261 13.0742 15.1562 14.8337 13.1945 15.3321L13.0474 15.3693L12.9331 15.4697C12.767 15.6156 12.4891 15.7893 12.1051 15.7893C11.7211 15.7893 11.4432 15.6156 11.2771 15.4697L11.1628 15.3693L11.0156 15.3321C9.05394 14.8337 7.68406 13.0742 7.68406 11.0525V5.18016C7.68406 4.20059 8.48111 3.40355 9.46068 3.40355H14.7495V3.40355ZM14.7495 2.77197H9.46004C8.13058 2.77197 7.05249 3.85007 7.05249 5.18016V11.0525C7.05249 13.4127 8.6731 15.3889 10.8602 15.944C11.1937 16.2371 11.6263 16.4209 12.1051 16.4209C12.5838 16.4209 13.0164 16.2371 13.3499 15.944C15.537 15.3889 17.1576 13.4127 17.1576 11.0525V5.18016C17.1576 3.85007 16.0796 2.77197 14.7495 2.77197Z"
        fill="currentColor"
      />
      <path
        d="M16.8418 10.4208V8.21033C16.8418 5.91393 14.9263 4.68489 14.8442 4.63373L14.5928 4.47584L14.4191 4.71773C14.3654 4.79226 13.0808 6.54108 10.3051 6.54108C9.57875 6.54108 7.36825 6.54108 7.36825 8.8419V10.4208H7.2552C6.97983 9.80063 6.1051 7.68486 6.1051 5.88109C6.1051 2.55207 8.3895 0.315674 11.7893 0.315674C14.2139 0.315674 14.9421 1.94829 14.9717 2.01776L15.0545 2.20913L15.2629 2.21039C16.3183 2.21039 18.105 2.94428 18.105 5.69351C18.105 7.35013 17.2164 9.73116 16.9441 10.4208H16.8418Z"
        fill="currentColor"
      />
      <path
        d="M11.7894 0.631572C13.9891 0.631572 14.6554 2.08229 14.6801 2.13787L14.8424 2.52629H15.263C16.4283 2.52629 17.7893 3.35554 17.7893 5.69363C17.7893 6.66372 17.4691 7.90286 17.1577 8.87864V8.21044C17.1577 5.74162 15.1001 4.42164 15.0123 4.36606L14.5064 4.04838L14.1609 4.53595C14.1489 4.55301 12.935 6.22478 10.3052 6.22478C8.14709 6.22541 7.05257 7.10582 7.05257 8.84201V9.04033C6.74247 8.14223 6.421 6.95298 6.421 5.88057C6.421 2.74102 8.57845 0.631572 11.7894 0.631572ZM11.7894 0C8.11425 0 5.78943 2.51429 5.78943 5.88057C5.78943 8.11065 7.05257 10.7367 7.05257 10.7367H7.68415C7.68415 10.7367 7.68415 9.45527 7.68415 8.84201C7.68415 7.15698 8.96245 6.85698 10.3052 6.85698C13.2925 6.85698 14.6769 4.901 14.6769 4.901C14.6769 4.901 16.5262 6.06246 16.5262 8.21044C16.5262 8.88559 16.5262 10.7367 16.5262 10.7367H17.1577C17.1577 10.7367 18.4209 7.72539 18.4209 5.69363C18.4209 2.84081 16.6038 1.89472 15.263 1.89472C15.263 1.89472 14.4717 0 11.7894 0Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
});
