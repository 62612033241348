import { AppTheme } from '../../theme';
import { buttonColorToThemeColor } from './const';
import { ButtonStatePropsForDescedants } from './model';

export const getButtonColor = ({
  ownerState,
  theme,
}: {
  ownerState: ButtonStatePropsForDescedants;
  theme: AppTheme;
}): { buttonColor: string; dividerColor: string } => {
  const { color, disabledColor } = buttonColorToThemeColor[ownerState.color];

  let buttonColor = 'inherit';
  let dividerColor = 'inherit';

  if (ownerState.variant === 'primary') {
    buttonColor = theme.palette[
      ownerState.color === 'white' || ownerState.color === 'gray ultra light'
        ? ownerState.disabled
          ? disabledColor
          : 'brand gray'
        : 'white'
    ] as string;

    dividerColor = buttonColor;
  } else if (ownerState.variant === 'secondary') {
    buttonColor = theme.palette[
      ownerState.color === 'white' ? (ownerState.disabled ? 'gray middle' : 'white') : ownerState.disabled ? disabledColor : 'brand gray'
    ] as string;

    if (ownerState.color === 'gray ultra light') {
      dividerColor = theme.palette[ownerState.disabled ? 'gray middle' : 'gray light'];
    } else {
      dividerColor = buttonColor;
    }
  } else if (ownerState.variant === 'text') {
    buttonColor = theme.palette[
      ownerState.color === 'white' ? (ownerState.disabled ? 'gray middle' : 'white') : ownerState.disabled ? disabledColor : color
    ] as string;

    dividerColor = buttonColor;
  }

  return { buttonColor, dividerColor };
};
