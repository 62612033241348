import React from 'react';
import { Link, LinkProps } from '@cp/ds/src/components/link';
import { IRouter, withRouteHandler, withRouter } from '@cp/shared/router';

const RoutableLink = ({
  href,
  router,
  disableHover,
  external,
  ...props
}: Omit<LinkProps, 'href'> & { href?: string | URL; router: IRouter }) => {
  const ownerState = {
    disableHover,
    external,
  };
  // Do not show links for pages, which referenced to themselves
  if (!href || (typeof href === 'string' ? href.split('#')[0] : href.pathname) === router.location.pathname) {
    if (typeof disableHover === 'undefined') {
      ownerState.disableHover = true;
    }

    const buttonProps = !href
      ? {
          role: 'button',
          tabIndex: 0,
        }
      : undefined;
    return <Link component="span" {...buttonProps} {...props} {...ownerState} />;
  }

  return <Link href={String(href)} {...props} {...ownerState} />;
};

export const RoutedLink = withRouteHandler(withRouter(RoutableLink));
