import React from 'react';
import { useSelector } from 'react-redux';
import loadable from '@loadable/component';
import { ClientType } from '@common/model/client/clientType';
import { Skeleton } from '@cp/ds/src/components/skeleton';
import { ClientPatronageStage } from '@common/model/admin/clientPatronage/stage';
import { ClientMenu as ClientMenuEntity, ClientMenuLayout } from 'src/entities/client/current/menu';
import { useClientProfile } from 'src/entities/client';
import { firstModalInQueueSelector } from 'src/redux/slices/modal/queue';
import { useMainResume } from 'src/entities/mainResume';
import { IMainResumeViewHandle } from 'src/features/mainResume/view';
import { useItems, usePatronageUpdater } from '../model';
import { ClientMenuProps } from './model';
import { PatronageAlert } from './patronageAlert';

export * from './model';

const MainResumeView = loadable(() => import('src/features/mainResume/view/ui'));

export const ClientMenu: React.FC<ClientMenuProps> = ({ children, sx, absoluteTopPosition }) => {
  const clientProfile = useClientProfile();
  const mainResumeViewRef = React.useRef<IMainResumeViewHandle>(null);
  const items = useItems({ clientProfile, mainResumeViewRef });
  const targetRef = React.useRef<HTMLElement>(null);
  const [isOpen, setOpen] = React.useState(false);
  const { isLoading: isLoadingMainResume } = useMainResume();
  const isOpenedModal = Boolean(useSelector(firstModalInQueueSelector));

  usePatronageUpdater();

  React.useEffect(() => {
    if (isOpenedModal) {
      setOpen(false);
    }
  }, [isOpenedModal]);

  const toggleMenu = React.useCallback(() => setOpen((prevState) => !prevState), []);

  if (!clientProfile) {
    return null;
  }

  return (
    <>
      <ClientMenuLayout absoluteTopPosition={absoluteTopPosition} isOpen={isOpen} onClose={() => setOpen(false)} target={targetRef}>
        <ClientMenuEntity
          clientProfile={clientProfile}
          headerBottom={
            clientProfile.type === ClientType.Specialist ? (
              isLoadingMainResume ? (
                <Skeleton height="40" width="100%" />
              ) : !clientProfile.patronageStage || clientProfile.patronageStage === ClientPatronageStage.Completed ? null : (
                <PatronageAlert afterClick={() => setOpen(false)} clientProfile={clientProfile} />
              )
            ) : null
          }
          items={items}
          onClose={() => setOpen(false)}
          sx={sx}
        />
      </ClientMenuLayout>

      <MainResumeView ref={mainResumeViewRef} />

      {children({ isMenuOpened: isOpen, ref: targetRef, toggleMenu })}
    </>
  );
};

export default ClientMenu;
