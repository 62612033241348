import { Action, Middleware, combineReducers } from 'redux';
import { configureStore as createStore } from '@reduxjs/toolkit';
import { ThunkDispatch } from 'redux-thunk';
import { setupListeners } from '@reduxjs/toolkit/query';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import { PersistConfig } from 'redux-persist';
import { localizationReducerPath, localizationSlice } from '@cp/shared/localization';
import { authenticationReducerPath, authenticationSlice, coreApi } from '@cp/shared/api/core';
import { dialogMiddleware } from '@cp/entities/dialog';
import { isBrowser } from '@cp/utils/browser';
import { createRootReducer } from './../slices/index';

interface ConfigureStoreOptions<S> {
  preloadedState?: S;
  extendedMiddleware?: Middleware[];
  persistConfig: PersistConfig<S>;
}

export const configureStore = ({ preloadedState, extendedMiddleware, persistConfig }: ConfigureStoreOptions<any>) => {
  const middleware = [isBrowser ? dialogMiddleware.middleware : undefined].filter(Boolean) as Middleware[];

  if (extendedMiddleware) {
    middleware.push(...extendedMiddleware);
  }

  const persistedRootReducer = createRootReducer(persistConfig);

  const store = createStore({
    reducer: persistedRootReducer,
    preloadedState,
    middleware: (gDM) => gDM({ serializableCheck: false }).prepend(middleware).concat(coreApi.middleware),
    devTools: true,
  });
  setupListeners(store.dispatch);

  return store as typeof store & { dispatch: ThunkDispatch<ReturnType<typeof store.getState>, void, Action> };
};

export const createStoreForSitemap = () => {
  const reducers = {
    [coreApi.reducerPath]: coreApi.reducer,
    [authenticationReducerPath]: authenticationSlice.reducer,
    [localizationReducerPath]: localizationSlice.reducer,
  };

  const rootReducer = combineReducers(reducers);

  const store = createStore({
    reducer: rootReducer,
    middleware: (gDM) => gDM().concat(coreApi.middleware),
  });
  return store;
};
/**
 * Store typings
 */
export type StoreType = Awaited<ReturnType<typeof configureStore>>;

export type RootState = ReturnType<StoreType['getState']>;

export type AppDispatch = ThunkDispatch<RootState, void, Action>; // StoreType['dispatch'];

/**
 * Typed hooks
 */
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

export const useAppDispatch = useDispatch as () => AppDispatch;
