import React from 'react';
import { Skeleton } from '../skeleton';
import { SkeletonProps } from '../skeleton/model';
import { Stack } from '../stack';
import { ContentSkeletonProps } from './model';
export type { ContentSkeletonProps } from './model';

const blockElements: Partial<SkeletonProps>[] = [{ width: '80%' }, { width: '90%' }, { width: '45%' }, { width: '70%' }];

export const ContentSkeleton: React.FC<ContentSkeletonProps> = ({ blocks = 1, spacing = 1, elementsSpacing = 1, sx }) => {
  return (
    <Stack alignItems="stretch" direction="column" spacing={spacing} sx={{ py: 4, ...sx }}>
      {Array.from(new Array(blocks), (_, index) => (
        <Stack key={index} spacing={elementsSpacing}>
          {blockElements.map((elementProps, elementIndex) => (
            <Skeleton height="1.5rem" key={elementIndex} {...elementProps} />
          ))}
        </Stack>
      ))}
    </Stack>
  );
};
