export enum ReactAppErrorType {
  Redirect = 'Redirect',
  PageNotFound = 'PageNotFound',
  Unknown = 'Unknown',
}

export interface IReactAppError {
  type: ReactAppErrorType;
  payload?: unknown;
  name?: string;
  message?: string;
  stack?: string;
}

export class ReactAppError extends Error implements IReactAppError {
  payload?: unknown;

  constructor(readonly type: ReactAppErrorType, message?: string) {
    super(message);
  }

  toJSON() {
    const { type, message, name, stack, payload } = this;
    return { type, message, name, stack, payload };
  }
}

export class ReactAppRedirectError extends ReactAppError {
  constructor(location: string, message?: string) {
    super(ReactAppErrorType.Redirect, message);
    this.payload = location;
  }
}

export class ReactAppPageNotFoundError extends ReactAppError {
  constructor(message?: string) {
    super(ReactAppErrorType.PageNotFound, message);
  }
}

export class ReactAppUnknownError extends ReactAppError {
  constructor(errorOrMessage?: unknown) {
    super(ReactAppErrorType.Unknown);

    if (errorOrMessage) {
      if (errorOrMessage instanceof Error) {
        this.message = errorOrMessage.message;
        this.name = errorOrMessage.name;
        this.stack = errorOrMessage.stack;
      } else if (typeof errorOrMessage === 'object') {
        try {
          this.message = JSON.stringify(errorOrMessage);
        } catch {}
      } else {
        this.message = String(errorOrMessage);
      }
    }
  }
}
