import React from 'react';
import { SxProps, styled, useTheme } from '@cp/ui/theme';
import { Typography } from '@cp/ds/components/typography';
import { Tag } from '#shared/ui/tag';

const List = styled('ul')({
  margin: 0,
  padding: 0,
  listStyle: 'none',
  display: 'flex',
  flexWrap: 'wrap',
  alignItems: 'center',
  gap: '8px',
});
const ListItem = styled('li')({ margin: 0, padding: 0 });

const data = [
  {
    key: '/nannies-for-an-hour/',
    label: 'Няня на час',
    href: '/nannies-for-an-hour/',
  },
  {
    key: '/car-nannies/',
    label: 'Автоняня',
    href: '/car-nannies/',
  },
  {
    key: '/governesses/',
    label: 'Гувернантка',
    href: '/governesses/',
  },
  {
    key: '/babysitters/',
    label: 'Бебиситтер',
    href: '/babysitters/',
  },
  {
    key: '/accompanying-nannies/',
    label: 'Сопровождающая няня',
    href: '/accompanying-nannies/',
  },
];

export const Tags = (props: { sx?: SxProps }) => {
  const theme = useTheme();
  return (
    <List {...props}>
      {data.map((item) => (
        <ListItem key={item.label}>
          <Tag clickable component="a" href={item.href} label={item.label} size="small" variant="outlined" />
        </ListItem>
      ))}
      <Typography sx={{ color: theme.palette.text.secondary }} variant="caption">
        и другие...
      </Typography>
    </List>
  );
};
