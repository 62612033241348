import { TYPOGRAPHY_COMPONENT_DEFAULT, TypographyOwnerState } from '../model';
import { styled } from '../../../theme';

export const TypographyRoot = styled(TYPOGRAPHY_COMPONENT_DEFAULT, {
  name: 'Typography',
  slot: 'Root',
})<{ ownerState: TypographyOwnerState }>(({ theme, ownerState }) => {
  return {
    ...theme.typography[ownerState.variant],
    ...(ownerState.numberOfLines
      ? {
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          display: '-webkit-box',
          WebkitBoxOrient: 'vertical',
          WebkitLineClamp: ownerState.numberOfLines,
          lineClamp: ownerState.numberOfLines,
        }
      : undefined),
  };
});
