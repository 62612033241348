import React from 'react';
import { Button } from '@cp/ds/src/components/button';
import { ChevronRightIcon } from '@cp/ds/src/icons';
import { BurgerMenu } from 'src/features/burgerMenu';
import { LoginButton } from 'src/features/client/current/login';
import { styled } from '@cp/ds/theme/styled';
import { HeaderContentProps, HeaderFeature } from '../model';

const Content = styled('div', { name: 'Header', slot: 'content' })({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  flexFlow: 'row nowrap',
});

const Left = styled('div', { name: 'Header', slot: 'left' })({
  display: 'flex',
  flexFlow: 'row nowrap',
  alignItems: 'center',
});

export const HeaderContentMobile: React.FC<HeaderContentProps> = ({ features, onGoBack, logo, isLoggedIn, rightBlock, leftBlock }) => {
  return (
    <Content>
      <Left>
        {features[HeaderFeature.GoBack] && onGoBack ? (
          <Button onClick={onGoBack} size="small" square sx={{ ml: -1, mr: 0.5 }} variant="text">
            <ChevronRightIcon sx={{ transform: 'rotate(180deg)', fontSize: '1.75rem' }} />
          </Button>
        ) : null}
        {logo}
        {leftBlock}
      </Left>
      {rightBlock}
      {features[HeaderFeature.LoginButton] && !isLoggedIn ? <LoginButton /> : null}
      {features[HeaderFeature.BurgerMenu] ? <BurgerMenu /> : null}
    </Content>
  );
};
