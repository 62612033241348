import React from 'react';
import { SvgIcon } from '../components/svgIcon';
import { SvgIconProps } from '../components/svgIcon/model';

export const ChevronDownLightIcon = React.forwardRef<SVGSVGElement, SvgIconProps>((props, ref) => {
  return (
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    <SvgIcon cacheName="chevronDownLight" ref={ref as any} {...props}>
      <path
        d="M12.571 16.0173L19.7638 8.45165C20.0787 8.11968 20.0787 7.58182 19.7638 7.24901C19.4489 6.91703 18.9374 6.91703 18.6225 7.24901L12.0004 14.2146L5.37835 7.24985C5.06342 6.91787 4.55191 6.91787 4.23619 7.24985C3.92127 7.58182 3.92127 8.12051 4.23619 8.45249L11.4289 16.0181C11.7406 16.345 12.2601 16.345 12.571 16.0173Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
});
