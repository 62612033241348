import styled from '@mui/material/styles/styled';

export const StyledButton = styled('button')(({ theme }) => ({
  padding: 0,
  border: 'none',
  backgroundColor: 'transparent',
  color: theme.palette['brand gray'],
  WebkitTapHighlightColor: 'transparent',
  cursor: 'pointer',
  '&:focus, &:active': {
    outline: 'none',
  },
}));
