import React from 'react';
import { OverridableComponent } from '@mui/material/OverridableComponent';
import {
  ICON_BUTTON_COLOR,
  ICON_BUTTON_COMPONENT_DEFAULT,
  ICON_BUTTON_VARIANT,
  IconButtonOwnerState,
  IconButtonProps,
  IconButtonTypeMap,
} from './model';
import { ICON_BUTTON_COLOR_DEFAULT, ICON_BUTTON_VARIANT_DEFAULT } from './const';
import { IconButtonRoot } from './components/root';
export type { IconButtonProps } from './model';

export const IconButton = React.forwardRef(
  (
    {
      component = ICON_BUTTON_COMPONENT_DEFAULT,
      size = 'small',
      color = ICON_BUTTON_COLOR_DEFAULT,
      variant = ICON_BUTTON_VARIANT_DEFAULT,
      disabled = false,
      onClick,
      ...props
    }: IconButtonProps,
    ref,
  ) => {
    const ownerState: IconButtonOwnerState = {
      component,
      size,
      color: ICON_BUTTON_COLOR.includes(color) ? color : ICON_BUTTON_COLOR_DEFAULT,
      variant: ICON_BUTTON_VARIANT.includes(variant) ? variant : ICON_BUTTON_VARIANT_DEFAULT,
      disabled,
    };

    return (
      <IconButtonRoot
        as={component}
        onClick={disabled ? undefined : onClick}
        ownerState={ownerState}
        ref={ref}
        tabIndex={component === 'button' ? 0 : undefined}
        {...props}
      />
    );
  },
) as OverridableComponent<IconButtonTypeMap>;
