import React from 'react';
import { PathGenerator, pathGenerator } from '../lib';

const PathGeneratorCtx = React.createContext<() => PathGenerator>(() => pathGenerator);

export const usePathGenerator = () => {
  return React.useContext(PathGeneratorCtx)();
};

export const PathGeneratorContextProvider = PathGeneratorCtx.Provider;
