import { useCallback } from 'react';
import { useModal } from 'src/app/shared/hooks/useModal';
import { TRAINING_MODAL_NAME } from 'src/app/shared/components/modals/training/const';
import { useClientProfile } from 'src/entities/client';

export const useModalTrainingPlayer = () => {
  const profile = useClientProfile();
  const { openModal } = useModal();
  return useCallback(() => {
    if (!profile) {
      return;
    }
    openModal(TRAINING_MODAL_NAME, { profile });
  }, [openModal, profile]);
};
