import React from 'react';
import { ClientPageResolver } from './resolver';

const ClientWebPageResolverContext = React.createContext<ClientPageResolver>(new ClientPageResolver({}));

export const useClientWebResolver = (): ClientPageResolver => {
  return React.useContext(ClientWebPageResolverContext);
};

export const ClientWebPageResolverProvider = ClientWebPageResolverContext.Provider;
