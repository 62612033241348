import { Breakpoint } from '@mui/material';

export const CONTAINER_DEFAULT_PADDING_HORIZONTAL = 2.5;

export const CONTAINER_HORIZONTAL_SPACING: Record<Breakpoint, number> = {
  xs: 1.5,
  sm: 1.5,
  cm: 1.5,
  md: 4,
  lg: 4,
  xl: 4,
  xxl: 4,
  mobile: 1.5,
  tablet: 4,
  laptop: 4,
  desktop: 4,
};

export const CONTAINER_MAX_WIDTH: Record<Breakpoint, number> = {
  xs: 0,
  cm: 420,
  sm: 600,
  md: 900,
  lg: 1200,
  xl: 1396,
  xxl: 1396,
  mobile: 600,
  tablet: 900,
  laptop: 1200,
  desktop: 1200,
};
