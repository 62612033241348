import React from 'react';
import { Stack } from '@cp/ds/src/components/stack';
import { Badge } from '@cp/ds/src/components/badge';
import { Typography } from '@cp/ds/src/components/typography';
import { MOBILE_BREAKPOINT_THRESHOLD, SxProps } from '@cp/ds/src/theme';
import { useIsMobile } from '@cp/ds/src/hooks/useIsMobile';
import { ChevronRightIcon } from '@cp/ds/src/icons';
import { Box } from '@cp/ds/src/components/box';
import { AnnounceHintWrapper } from '@cp/ds/src/components/announceHintWrapper';
import { useTheme } from '@cp/ds/src/hooks/useTheme';
import { RoutedLink as Link } from 'src/shared/ui/link';
import { HORIZONTAL_SPACING_DESKTOP, HORIZONTAL_SPACING_MOBILE } from '../const';
import { ItemProps } from './model';

export * from './model';

export const Item: React.FC<ItemProps> = ({
  startIcon,
  startIconBadgeContent,
  endAdornment,
  href,
  closeMenuOnClick = true,
  borderBottom = false,
  onClick: onClickFromProps,
  onMenuClose,
  children,
}) => {
  const theme = useTheme();
  const Icon = startIcon;
  const isMobile = useIsMobile();
  const [isActionExecuting, setActionExecuting] = React.useState(false);

  const onClick = onClickFromProps
    ? async (event: React.MouseEvent) => {
        try {
          setActionExecuting(true);
          await onClickFromProps(event);
        } finally {
          setActionExecuting(false);
        }
      }
    : undefined;

  const linkSx: SxProps = {
    display: 'flex',
    px: { xs: HORIZONTAL_SPACING_MOBILE, [MOBILE_BREAKPOINT_THRESHOLD]: HORIZONTAL_SPACING_DESKTOP },
  };

  const content = (
    <Stack
      direction="row"
      sx={{
        py: { xs: 2, [MOBILE_BREAKPOINT_THRESHOLD]: 1 },
        alignItems: 'center',
        justifyContent: 'space-between',
        width: 1,
        borderBottom: borderBottom ? `1px solid ${theme.palette['gray middle']}` : undefined,
      }}
    >
      <Stack direction="row" sx={{ alignItems: 'center', flex: '1 1 0px' }}>
        <Badge badgeContent={startIconBadgeContent}>
          {Icon && <Icon sx={{ color: isActionExecuting ? 'gray middle' : 'brand gray', fontSize: '1.5rem', mr: 1 }} />}
        </Badge>
        <Stack sx={{ flex: '1 1 0px' }}>
          <Typography
            sx={{
              color: isActionExecuting ? 'gray middle' : undefined,
              typography: { xs: 'button', [MOBILE_BREAKPOINT_THRESHOLD]: 'body bold' },
            }}
          >
            {children}
          </Typography>
        </Stack>
      </Stack>
      {endAdornment ?? (isMobile && <ChevronRightIcon sx={{ fontSize: '1.25rem', color: isActionExecuting ? 'gray middle' : 'gray' }} />)}
    </Stack>
  );

  let contentWithActionWrapper: React.ReactNode = null;

  if (href || onClick) {
    contentWithActionWrapper = (
      <Link disableHover href={href} onClick={isActionExecuting ? undefined : onClick} sx={linkSx} underline={false}>
        {content}
      </Link>
    );
  } else {
    contentWithActionWrapper = (
      <AnnounceHintWrapper>
        {({ setAnnounceMessageVisible }) => (
          <Link onClick={() => setAnnounceMessageVisible(true)} sx={linkSx}>
            {content}
          </Link>
        )}
      </AnnounceHintWrapper>
    );
  }

  return (
    <Box
      component="li"
      onClick={closeMenuOnClick ? onMenuClose : undefined}
      sx={{
        listStyleType: 'none',
        transition: theme.transitions.create(['background-color'], {
          duration: theme.transitions.duration.short,
        }),
        '&:hover': {
          backgroundColor: { xs: 'none', md: 'gray light' },
        },
      }}
    >
      {contentWithActionWrapper}
    </Box>
  );
};
