import { shouldForwardProp } from '@mui/system';
import { styled } from '../../../theme';

export const StyledInput = styled('input', {
  shouldForwardProp,
  name: 'Checkbox',
  slot: 'Input',
})(() => ({
  cursor: 'inherit',
  position: 'absolute',
  opacity: 0,
  width: '100%',
  height: '100%',
  top: 0,
  left: 0,
  margin: 0,
  padding: 0,
  zIndex: 1,
}));
