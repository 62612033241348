import { IModalDescription, IModalResolver } from '@cp/shared/modal';
import { PROFILE_DELETE_MODAL } from 'src/features/client/delete';
import { TARIFF_SELECT_MODAL } from 'src/features/billing';

const modalDict = new Map<string, IModalDescription<any>>([
  [PROFILE_DELETE_MODAL.nss, PROFILE_DELETE_MODAL],
  [TARIFF_SELECT_MODAL.nss, TARIFF_SELECT_MODAL],
]);

export const modalResolver: IModalResolver = {
  resolveComponent: (name: string) => {
    if (!modalDict.has(name)) {
      console.debug(`ModalResolver: Name ${name} is not in modal dict. Resolve from shared/components/modals`);
      return import(`../../shared/components/modals/${name}`).then((module) => module.default);
    }
    const MODAL_DESCRIPTION = modalDict.get(name) as IModalDescription<any>;
    return MODAL_DESCRIPTION.loader();
  },
};
