import React from 'react';
import { ClientWebPage } from '@common/model/meta/clientWebPage';
import { ClientWebRouteParams } from './routeParams.interface';

const EMPTY_PARAMS: ClientWebRouteParams = {
  pageId: null,
  pageProps: {},
  cityAlias: undefined,
  hasCitySeparatedContent: undefined,
  routeId: undefined,
};

/**
 * Хранит информацию о параметрах активного ClientWebRoute
 */
export const ClientWebRouteParamsContext = React.createContext<ClientWebRouteParams>(EMPTY_PARAMS);

/**
 * Предоставляет параметры активного ClientWebRoute
 */
export const useClientWebRouteParams = <P extends ClientWebPage = ClientWebPage>(): ClientWebRouteParams<P> =>
  React.useContext(ClientWebRouteParamsContext) as ClientWebRouteParams<P>;
