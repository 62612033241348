import React from 'react';
import { ApiServerEntityIDType } from '../apiServer/types';

export interface ISuggestionsContext<CitySuggestion = unknown, LocationSuggestion = unknown> {
  onSearchCity?: (query: string) => Promise<CitySuggestion[]>;
  onSearchLocation?: (query: string, parameters: { cityId: ApiServerEntityIDType }) => Promise<LocationSuggestion[]>;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const AppSuggestionsContext = React.createContext<ISuggestionsContext<any, any>>({});

export const useAppSuggestionsContext = function <CitySuggestion, LocationSuggestion>(): ISuggestionsContext<
  CitySuggestion,
  LocationSuggestion
> {
  return React.useContext(AppSuggestionsContext);
};
