import { AppTheme } from '../../theme';
import { BadgeColor } from './model';

export const BADGE_COLOR_DEFAULT: BadgeColor = 'pink';
export const BADGE_MAX_VALUE_DEFAULT = 99;
export const BADGE_SIZE_DEFAULT = 14;

export const badgeColorDict: Record<BadgeColor, { backgroundColor: keyof AppTheme['palette']; color: keyof AppTheme['palette'] }> = {
  pink: {
    backgroundColor: 'brand pink',
    color: 'white',
  },
  white: {
    backgroundColor: 'white',
    color: 'brand gray',
  },
};
