import { ClientWebPage } from './clientWebPage';

export interface ClientWebPageConfig {
  hasCitySeparatedContent: boolean;
}

export const clientWebPageConfigDict: Record<ClientWebPage, ClientWebPageConfig> = {
  [ClientWebPage.Home]: {
    hasCitySeparatedContent: false,
  },
  [ClientWebPage.Catalog]: {
    hasCitySeparatedContent: true,
  },
  [ClientWebPage.CatalogItem]: {
    hasCitySeparatedContent: true,
  },
  [ClientWebPage.FAQ]: {
    hasCitySeparatedContent: false,
  },
  [ClientWebPage.Feedbacks]: {
    hasCitySeparatedContent: false,
  },
  [ClientWebPage.LegalInformation]: {
    hasCitySeparatedContent: false,
  },
  [ClientWebPage.PrivacyPolicy]: {
    hasCitySeparatedContent: false,
  },
  [ClientWebPage.UserAgreement]: {
    hasCitySeparatedContent: false,
  },
  [ClientWebPage.Support]: {
    hasCitySeparatedContent: false,
  },
  [ClientWebPage.PaymentAndRefund]: {
    hasCitySeparatedContent: false,
  },
  [ClientWebPage.Contacts]: {
    hasCitySeparatedContent: false,
  },
  [ClientWebPage.WriteToDirector]: {
    hasCitySeparatedContent: false,
  },
  [ClientWebPage.AboutUs]: {
    hasCitySeparatedContent: false,
  },
  [ClientWebPage.SignupProvider]: {
    hasCitySeparatedContent: false,
  },
  [ClientWebPage.Profile]: {
    hasCitySeparatedContent: false,
  },
  [ClientWebPage.ProfileFavorites]: {
    hasCitySeparatedContent: false,
  },
  [ClientWebPage.ProfilePhoto]: {
    hasCitySeparatedContent: false,
  },
  [ClientWebPage.LegacyPaymentFinish]: {
    hasCitySeparatedContent: false,
  },
  [ClientWebPage.ProfileMessages]: {
    hasCitySeparatedContent: false,
  },
  [ClientWebPage.Confirmation]: {
    hasCitySeparatedContent: false,
  },
  [ClientWebPage.OAuth]: {
    hasCitySeparatedContent: false,
  },
  [ClientWebPage.Team]: {
    hasCitySeparatedContent: false,
  },

  [ClientWebPage.ProfileVacancies]: {
    hasCitySeparatedContent: false,
  },
  [ClientWebPage.NotFound]: {
    hasCitySeparatedContent: false,
  },
  [ClientWebPage.ProfileSchedule]: {
    hasCitySeparatedContent: false,
  },
  [ClientWebPage.ProfileRegularPartners]: {
    hasCitySeparatedContent: false,
  },
  [ClientWebPage.ProfileBilling]: {
    hasCitySeparatedContent: false,
  },
};
