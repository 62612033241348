import { Button } from '@cp/ds/src/components/button';
import { StyledListItem } from '@cp/ds/src/components/styled/styledListItem';
import { StyledUnorderedList } from '@cp/ds/src/components/styled/styledUnorderedList';
import React from 'react';
import { useClientLogin } from 'src/features/client/current/login';
import { SupportNavigate } from 'src/features/support/navigate';
import { SupportCallLink } from 'src/features/support';
import { EButtonName } from 'src/shared/tracking';
import { Box, BoxProps } from '@cp/ds/components/box';
import { styled } from '@cp/ui/theme';
import { FooterItemHeader } from './itemHeader';

const BUTTON_MIN_WIDTH = 151;

const Root = styled(Box)({
  display: 'flex',
  gap: '40px',
  justifyContent: 'space-between',
  '@container (min-width: 430px)': {
    justifyContent: 'start',
  },
  '@container (min-width: 890px)': {
    justifyContent: 'end',
  },
});

export const SupportSection = (props: BoxProps) => {
  const login = useClientLogin();
  return (
    // @ts-ignore
    <Root {...props}>
      <Box>
        <FooterItemHeader>Cтать специалистом:</FooterItemHeader>
        <StyledUnorderedList>
          <StyledListItem>
            <Button
              className={EButtonName.RegisterAsSpecialist}
              color="pink"
              onClick={login}
              size="small"
              sx={{ minWidth: BUTTON_MIN_WIDTH }}
              variant="primary"
            >
              Регистрация
            </Button>
          </StyledListItem>
        </StyledUnorderedList>
      </Box>
      <Box>
        <FooterItemHeader>Служба поддержки:</FooterItemHeader>
        <StyledUnorderedList>
          <StyledListItem>
            <SupportNavigate buttonProps={{ sx: { minWidth: BUTTON_MIN_WIDTH } }} />
          </StyledListItem>
          <StyledListItem sx={{ mt: 2 }}>
            <SupportCallLink />
          </StyledListItem>
        </StyledUnorderedList>
      </Box>
    </Root>
  );
};
