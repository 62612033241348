import React from 'react';
import useResizeObserver from 'use-resize-observer';

export const useSyncDimensions = (containerRef?: React.RefObject<HTMLElement>) => {
  const { height } = useResizeObserver<HTMLElement>({
    ref: containerRef,
    box: 'border-box',
  });
  return height;
};
