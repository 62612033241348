import React from 'react';
import { SvgIcon, SvgIconProps } from '../components/svgIcon';

export const VacanciesIcon = React.forwardRef<SVGSVGElement, SvgIconProps>((props, ref) => {
  return (
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    <SvgIcon cacheName="vacancies" ref={ref as any} {...props}>
      <path
        clipRule="evenodd"
        d="M16 5C16.3314 5 16.6 5.26863 16.6 5.6V7.4H18.4C18.7314 7.4 19 7.66863 19 8C19 8.33137 18.7314 8.6 18.4 8.6H16.6V10.4C16.6 10.7314 16.3314 11 16 11C15.6686 11 15.4 10.7314 15.4 10.4V8.6H13.6C13.2686 8.6 13 8.33137 13 8C13 7.66863 13.2686 7.4 13.6 7.4H15.4V5.6C15.4 5.26863 15.6686 5 16 5Z"
        fill="currentColor"
        fillRule="evenodd"
      />
      <path
        d="M5 7.75C5 6.92428 5.00144 6.37265 5.05668 5.96195C5.10933 5.57046 5.19944 5.40907 5.30425 5.30425C5.40907 5.19944 5.57046 5.10933 5.96195 5.05668C6.37265 5.00144 6.92428 5 7.75 5C8.57572 5 9.12735 5.00144 9.53805 5.05668C9.92954 5.10933 10.0909 5.19944 10.1957 5.30425C10.3006 5.40907 10.3907 5.57046 10.4433 5.96195C10.4986 6.37265 10.5 6.92428 10.5 7.75C10.5 8.57572 10.4986 9.12735 10.4433 9.53805C10.3907 9.92954 10.3006 10.0909 10.1957 10.1957C10.0909 10.3006 9.92954 10.3907 9.53805 10.4433C9.12735 10.4986 8.57572 10.5 7.75 10.5C6.92428 10.5 6.37265 10.4986 5.96195 10.4433C5.57046 10.3907 5.40907 10.3006 5.30425 10.1957C5.19944 10.0909 5.10933 9.92954 5.05668 9.53805C5.00144 9.12735 5 8.57572 5 7.75Z"
        fill="none"
        stroke="currentColor"
        strokeWidth="1.5"
      />
      <path
        d="M5 15.75C5 14.9243 5.00144 14.3726 5.05668 13.9619C5.10933 13.5705 5.19944 13.4091 5.30425 13.3043C5.40907 13.1994 5.57046 13.1093 5.96195 13.0567C6.37265 13.0014 6.92428 13 7.75 13C8.57572 13 9.12735 13.0014 9.53805 13.0567C9.92954 13.1093 10.0909 13.1994 10.1957 13.3043C10.3006 13.4091 10.3907 13.5705 10.4433 13.9619C10.4986 14.3726 10.5 14.9243 10.5 15.75C10.5 16.5757 10.4986 17.1274 10.4433 17.5381C10.3907 17.9295 10.3006 18.0909 10.1957 18.1957C10.0909 18.3006 9.92954 18.3907 9.53805 18.4433C9.12735 18.4986 8.57572 18.5 7.75 18.5C6.92428 18.5 6.37265 18.4986 5.96195 18.4433C5.57046 18.3907 5.40907 18.3006 5.30425 18.1957C5.19944 18.0909 5.10933 17.9295 5.05668 17.5381C5.00144 17.1274 5 16.5757 5 15.75Z"
        fill="none"
        stroke="currentColor"
        strokeWidth="1.5"
      />
      <path
        d="M13 15.75C13 14.9243 13.0014 14.3726 13.0567 13.9619C13.1093 13.5705 13.1994 13.4091 13.3043 13.3043C13.4091 13.1994 13.5705 13.1093 13.9619 13.0567C14.3726 13.0014 14.9243 13 15.75 13C16.5757 13 17.1274 13.0014 17.5381 13.0567C17.9295 13.1093 18.0909 13.1994 18.1957 13.3043C18.3006 13.4091 18.3907 13.5705 18.4433 13.9619C18.4986 14.3726 18.5 14.9243 18.5 15.75C18.5 16.5757 18.4986 17.1274 18.4433 17.5381C18.3907 17.9295 18.3006 18.0909 18.1957 18.1957C18.0909 18.3006 17.9295 18.3907 17.5381 18.4433C17.1274 18.4986 16.5757 18.5 15.75 18.5C14.9243 18.5 14.3726 18.4986 13.9619 18.4433C13.5705 18.3907 13.4091 18.3006 13.3043 18.1957C13.1994 18.0909 13.1093 17.9295 13.0567 17.5381C13.0014 17.1274 13 16.5757 13 15.75Z"
        fill="none"
        stroke="currentColor"
        strokeWidth="1.5"
      />
    </SvgIcon>
  );
});
