import React from 'react';
import { SvgIcon } from '../components/svgIcon';
import { SvgIconProps } from '../components/svgIcon/model';

export const ScheduleIcon = React.forwardRef<SVGSVGElement, SvgIconProps>((props, ref) => {
  return (
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    <SvgIcon cacheName="schedule" ref={ref as any} {...props}>
      <path
        clipRule="evenodd"
        d="M8.8 3.2a.8.8 0 0 1 .8.8h4a.8.8 0 0 1 1.6 0h.8a4 4 0 0 1 4 4v2.4a.8.8 0 0 1-1.6 0v-.8H4.8v5.6a2.4 2.4 0 0 0 2.4 2.4h2.4a.8.8 0 1 1 0 1.6H7.2a4 4 0 0 1-4-4V8a4 4 0 0 1 4-4H8a.8.8 0 0 1 .8-.8ZM8 5.6h-.8A2.4 2.4 0 0 0 4.8 8h13.6A2.4 2.4 0 0 0 16 5.6h-.8a.8.8 0 0 1-1.6 0h-4a.8.8 0 1 1-1.6 0Zm9.766 8.749a.8.8 0 0 1 0 1.131l-1.2 1.2a.8.8 0 1 1-1.132-1.131l1.2-1.2a.8.8 0 0 1 1.132 0Z"
        fill="currentColor"
        fillRule="evenodd"
      />
      <path
        clipRule="evenodd"
        d="M16 12.8a3.2 3.2 0 1 0 0 6.4 3.2 3.2 0 0 0 0-6.4ZM11.2 16a4.8 4.8 0 1 1 9.6 0 4.8 4.8 0 0 1-9.6 0Z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </SvgIcon>
  );
});
