import { useCity } from '@cp/entities/location';
import { CityViewModel } from '@common/model/location/cityViewModel';
import { useClientProfile } from 'src/entities/client';

export const useClientCity = (): { clientCity: CityViewModel | undefined; isLoading: boolean } => {
  const clientProfile = useClientProfile();
  const { city: clientCity, isLoading } = useCity(clientProfile?.cityId);

  return {
    clientCity,
    isLoading,
  };
};
