import React from 'react';
import { SvgIcon } from '../../components/svgIcon';
import { SvgIconProps } from '../../components/svgIcon/model';

export const VkIcon = React.forwardRef<SVGSVGElement, SvgIconProps>((props, ref) => {
  return (
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    <SvgIcon cacheName="social/vk" ref={ref as any} {...props}>
      <path d="M20.8396 16.8113C20.7912 16.7245 20.4919 16.028 19.0518 14.5965C17.5442 13.0975 17.7467 13.3409 19.5626 10.7489C20.6685 9.17033 21.1107 8.20632 20.9723 7.7942C20.8407 7.40137 20.0273 7.505 20.0273 7.505L17.3226 7.52187C17.3226 7.52187 17.1223 7.49295 16.9727 7.58815C16.8275 7.68214 16.7341 7.89904 16.7341 7.89904C16.7341 7.89904 16.3055 9.12092 15.7339 10.1596C14.529 12.3516 14.0463 12.4672 13.8494 12.3311C13.3915 12.0142 13.5063 11.0562 13.5063 10.3765C13.5063 8.25211 13.8067 7.36643 12.9201 7.13747C12.6253 7.06156 12.4093 7.01095 11.6566 7.00251C10.6913 6.99167 9.87338 7.00613 9.41097 7.24833C9.1027 7.40981 8.86531 7.7701 9.01044 7.79059C9.18933 7.8159 9.59436 7.90748 9.80925 8.22078C10.0871 8.62446 10.077 9.53304 10.077 9.53304C10.077 9.53304 10.2368 12.0334 9.70462 12.3443C9.33897 12.5576 8.8383 12.1226 7.76385 10.1331C7.21369 9.1149 6.79741 7.98821 6.79741 7.98821C6.79741 7.98821 6.71752 7.77854 6.57464 7.66647C6.40138 7.53031 6.15836 7.48693 6.15836 7.48693L3.58642 7.5038C3.58642 7.5038 3.20052 7.51585 3.05876 7.69539C2.93275 7.85566 3.04863 8.18583 3.04863 8.18583C3.04863 8.18583 5.06253 13.2312 7.34195 15.775C9.43347 18.1067 11.8074 17.9537 11.8074 17.9537H12.883C12.883 17.9537 13.2081 17.9151 13.3735 17.7235C13.5265 17.5476 13.5209 17.2174 13.5209 17.2174C13.5209 17.2174 13.4995 15.6714 14.1701 15.4436C14.8305 15.2195 15.6788 16.9378 16.5777 17.5994C17.2573 18.0995 17.7737 17.9898 17.7737 17.9898L20.178 17.9537C20.178 17.9537 21.4359 17.8705 20.8396 16.8113Z" />
    </SvgIcon>
  );
});
