import { Box } from '@cp/ds/src/components/box';
import { Tooltip } from '@cp/ds/src/components/tooltip';
import { ClickAwayListener } from '@mui/material';
import React from 'react';

export const AnnounceHintWrapper: React.FC<{
  children: ({ setAnnounceMessageVisible }: { setAnnounceMessageVisible: (nextState: boolean) => void }) => React.ReactElement<any, any>; // eslint-disable-line @typescript-eslint/no-explicit-any
}> = ({ children }) => {
  const [isMessageVisible, setMessageVisible] = React.useState(false);

  const handleMessageDisappear = () => setMessageVisible(false);

  return (
    <Tooltip
      arrow
      colorSchema="dark"
      disableFocusListener
      disableHoverListener
      disableTouchListener
      onClose={handleMessageDisappear}
      open={isMessageVisible}
      title={
        <ClickAwayListener onClickAway={handleMessageDisappear}>
          <Box sx={{ px: 1.75, py: 1 }}>Скоро появится...</Box>
        </ClickAwayListener>
      }
    >
      {children({ setAnnounceMessageVisible: setMessageVisible })}
    </Tooltip>
  );
};
