import React from 'react';
import { SvgIcon, SvgIconProps } from '../../components/svgIcon';

export const VacanciesActiveIcon = React.forwardRef<SVGSVGElement, SvgIconProps>((props, ref) => {
  return (
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    <SvgIcon cacheName="mobileMenu/vacanciesActive" ref={ref as any} {...props}>
      <path
        clipRule="evenodd"
        d="M17.125 4.5c.331 0 .6.302.6.675V7.2h1.8c.331 0 .6.302.6.675 0 .373-.269.675-.6.675h-1.8v2.025c0 .373-.269.675-.6.675-.331 0-.6-.302-.6-.675V8.55h-1.8c-.331 0-.6-.302-.6-.675 0-.373.269-.675.6-.675h1.8V5.175c0-.373.269-.675.6-.675Z"
        fill="#405368"
        fillRule="evenodd"
      />
      <path
        d="M14.125 16.5c0-.9.002-1.503.062-1.95.057-.428.156-.604.27-.718.114-.114.29-.213.717-.27.448-.06 1.05-.062 1.951-.062.9 0 1.503.002 1.95.062.428.057.604.156.718.27.114.114.213.29.27.717.06.448.062 1.05.062 1.951 0 .9-.002 1.503-.062 1.95-.057.428-.156.604-.27.718-.114.114-.29.213-.717.27-.448.06-1.05.062-1.951.062-.9 0-1.503-.002-1.95-.062-.428-.057-.604-.156-.718-.27-.114-.114-.213-.29-.27-.717-.06-.448-.062-1.05-.062-1.951ZM5.125 16.5c0-.9.002-1.503.062-1.95.057-.428.156-.604.27-.718.114-.114.29-.213.717-.27.448-.06 1.05-.062 1.951-.062.9 0 1.503.002 1.95.062.428.057.604.156.718.27.114.114.213.29.27.717.06.448.062 1.05.062 1.951 0 .9-.002 1.503-.062 1.95-.057.428-.156.604-.27.718-.114.114-.29.213-.717.27-.448.06-1.05.062-1.951.062-.9 0-1.503-.002-1.95-.062-.428-.057-.604-.156-.718-.27-.114-.114-.213-.29-.27-.717-.06-.448-.062-1.05-.062-1.951ZM5.125 7.5c0-.9.002-1.503.062-1.95.057-.428.156-.604.27-.718.114-.114.29-.213.717-.27.448-.06 1.05-.062 1.951-.062.9 0 1.503.002 1.95.062.428.057.604.156.718.27.114.114.213.29.27.717.06.448.062 1.05.062 1.951 0 .9-.002 1.503-.062 1.95-.057.428-.156.604-.27.718-.114.114-.29.213-.717.27-.448.06-1.05.062-1.951.062-.9 0-1.503-.002-1.95-.062-.428-.057-.604-.156-.718-.27-.114-.114-.213-.29-.27-.717-.06-.448-.062-1.05-.062-1.951Z"
        fill="#FFCEC3"
        stroke="#405368"
        strokeWidth={1.4}
      />
    </SvgIcon>
  );
});
