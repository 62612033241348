import React from 'react';
import { SvgIcon, SvgIconProps } from '../components/svgIcon';

export const WorkIcon = React.forwardRef<SVGSVGElement, SvgIconProps>((props, ref) => {
  return (
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    <SvgIcon cacheName="work" ref={ref as any} {...props}>
      <path
        clipRule="evenodd"
        d="M9.714 4a2 2 0 0 0-2 2v1.429H6.286A2.286 2.286 0 0 0 4 9.714v8A2.286 2.286 0 0 0 6.286 20h11.428A2.285 2.285 0 0 0 20 17.714v-8a2.286 2.286 0 0 0-2.286-2.285h-1.428V6a2 2 0 0 0-2-2H9.714Zm4.857 3.429V6a.286.286 0 0 0-.285-.286H9.714A.286.286 0 0 0 9.43 6v1.429h5.142ZM6.286 9.143h11.428a.571.571 0 0 1 .572.571v1.143H5.714V9.714a.571.571 0 0 1 .572-.571Zm-.572 3.428v5.143a.571.571 0 0 0 .572.572h11.428a.571.571 0 0 0 .572-.572v-5.143h-5.143v1.715h-2.286V12.57H5.714Z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </SvgIcon>
  );
});
