import React from 'react';
import { styled } from '../../theme/styled';
import { CONTAINER_DEFAULT_PADDING_HORIZONTAL, CONTAINER_HORIZONTAL_SPACING, CONTAINER_MAX_WIDTH } from './const';
import { ContainerProps } from './model';
export type { ContainerProps } from './model';

type UnstyledDivComponent = React.ComponentClass<
  Omit<React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLDivElement>, HTMLDivElement>, 'color' | 'ref'> & {
    ownerState: { maxWidth?: number };
  }
>;

const ContainerRoot = styled('div' as unknown as UnstyledDivComponent, {
  name: 'Container',
  slot: 'Root',
})(({ theme, ownerState }) => {
  const px = theme.spacing(CONTAINER_DEFAULT_PADDING_HORIZONTAL);

  const styleOverrides = Object.keys(theme.breakpoints.values).reduce(
    (acc, breakpointValueKey) => {
      const breakpoint = breakpointValueKey;
      const maxWidth = ownerState.maxWidth ?? CONTAINER_MAX_WIDTH[breakpoint];
      const horizontalSpacing = theme.spacing(CONTAINER_HORIZONTAL_SPACING[breakpoint]);

      acc[theme.breakpoints.up(breakpoint)] = {
        paddingLeft: horizontalSpacing,
        paddingRight: horizontalSpacing,
      };

      if (maxWidth !== 0) {
        acc[theme.breakpoints.up(breakpoint)].maxWidth = `calc(${maxWidth}px + (${horizontalSpacing} * 2))`;
      }
      return acc;
    },
    {} as Record<string, React.CSSProperties>,
  );

  return {
    width: '100%',
    marginLeft: 'auto',
    marginRight: 'auto',
    boxSizing: 'border-box',
    display: 'block', // Fix IE11 layout when used with main.

    ...styleOverrides,
    paddingLeft: px,
    paddingRight: px,
  };
});

export const Container = React.forwardRef<HTMLDivElement, ContainerProps>(({ maxWidth, ...props }, ref) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return <ContainerRoot ref={ref as any} {...props} ownerState={{ maxWidth }} />;
});
