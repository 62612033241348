import { CityViewModel } from '@common/model/location/cityViewModel';
import { coreLocationApi } from '../api';

/**
 * get city model by id
 */
export const useCity = (id?: CityViewModel['id']) => {
  const { data: city, isLoading } = coreLocationApi.endpoints.getCityById.useQuery({ id: id as CityViewModel['id'] }, { skip: !id });

  return {
    city,
    isLoading,
  };
};
