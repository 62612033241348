import { SxProps } from '@mui/material';
import { OverrideProps } from '@mui/material/OverridableComponent';
import React from 'react';

export const ICON_BUTTON_COMPONENT_DEFAULT = 'button' as const;

export const ICON_BUTTON_SIZE = ['small', 'normal'] as const;

export const ICON_BUTTON_COLOR = ['white', 'gray dark'] as const;

export const ICON_BUTTON_VARIANT = ['primary', 'secondary'] as const;

export type IconButtonVariant = typeof ICON_BUTTON_VARIANT[number];

export type IconButtonSize = typeof ICON_BUTTON_SIZE[number];

export type IconButtonColor = typeof ICON_BUTTON_COLOR[number];

export type IconButtonComponentDefault = typeof ICON_BUTTON_COMPONENT_DEFAULT;

export interface IconButtonOwnProps {
  component?: React.ElementType;
  color?: IconButtonColor;
  variant?: IconButtonVariant;
  disabled?: boolean;
  sx?: SxProps;
  size?: IconButtonSize;
}

export type IconButtonOwnerState = Required<Omit<IconButtonOwnProps, 'sx'>>;

export interface IconButtonTypeMap<D extends React.ElementType = IconButtonComponentDefault> {
  props: IconButtonOwnProps;
  defaultComponent: D;
}

export type IconButtonProps<D extends React.ElementType = IconButtonTypeMap['defaultComponent']> = OverrideProps<IconButtonTypeMap<D>, D>;
