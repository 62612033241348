import React from 'react';
import { SvgIcon } from '../components/svgIcon';
import { SvgIconProps } from '../components/svgIcon/model';

export const BurgerIcon = React.forwardRef<SVGSVGElement, SvgIconProps>((props, ref) => {
  return (
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    <SvgIcon cacheName="burger" ref={ref as any} {...props}>
      <line stroke="currentColor" strokeLinecap="round" strokeWidth="1.9" x1="3.95" x2="20.05" y1="5.05" y2="5.05" />
      <line stroke="currentColor" strokeLinecap="round" strokeWidth="1.9" x1="3.95" x2="16.05" y1="12.05" y2="12.05" />
      <line stroke="currentColor" strokeLinecap="round" strokeWidth="1.9" x1="3.95" x2="20.05" y1="19.05" y2="19.05" />
    </SvgIcon>
  );
});
