import { useCallback } from 'react';
import { ClientProfileViewModel } from '@common/model/client';
import { useMainResumeRequest } from 'src/entities/mainResume';
import { checkPatronageCompleted } from './checkPatronageCompleted';

export const useCheckPatronageCompleted = () => {
  const [request] = useMainResumeRequest();
  return useCallback(
    async (clientProfile?: ClientProfileViewModel) => {
      const mainResume = await request();

      if (!mainResume) {
        throw new Error('Main resume is not exist');
      }
      if (!clientProfile) {
        throw new Error('Client profile not loaded');
      }
      return checkPatronageCompleted(clientProfile, mainResume);
    },
    [request],
  );
};
