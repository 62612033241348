import React from 'react';
import { StyledSpan } from '../styled/styledSpan';
import { useTheme } from '../../hooks/useTheme';
import { BADGE_COLOR, BadgeProps } from './model';
import { BADGE_COLOR_DEFAULT, BADGE_MAX_VALUE_DEFAULT, BADGE_SIZE_DEFAULT, badgeColorDict } from './const';

export const Badge: React.FC<BadgeProps> = ({
  badgeContent,
  color: _color = BADGE_COLOR_DEFAULT,
  children,
  maxValue = BADGE_MAX_VALUE_DEFAULT,
}) => {
  const theme = useTheme();
  const { backgroundColor, color } = badgeColorDict[BADGE_COLOR.includes(_color) ? _color : BADGE_COLOR_DEFAULT];

  return (
    <StyledSpan
      sx={{
        position: 'relative',
        display: 'inline-flex',
        flexShrink: 0,
        verticalAlign: 'middle',
      }}
    >
      {children}
      {badgeContent ? (
        <StyledSpan
          sx={{
            display: 'flex',
            flexDirection: 'row',
            flexWrap: 'wrap',
            WebkitBoxPack: 'center',
            justifyContent: 'center',
            alignItems: 'center',
            alignContent: 'center',
            WebkitBoxAlign: 'center',
            position: 'absolute',
            height: BADGE_SIZE_DEFAULT,
            minWidth: BADGE_SIZE_DEFAULT,
            px: 0.5,
            backgroundColor: theme.palette[backgroundColor] as string,
            color: theme.palette[color] as string,
            borderRadius: `${theme.borderRadius.small}px`,
            zIndex: 1,
            transition: theme.transitions.create(['transform'], {
              duration: theme.transitions.duration.short,
            }),
            transform: 'scale(1) translate(50%, -50%)',
            transformOrigin: '100% 0%',
            top: 2,
            right: 2,
            ...theme.typography.extraSmall,
          }}
        >
          {typeof badgeContent === 'number' && maxValue && badgeContent > maxValue ? `${maxValue}+` : badgeContent}
        </StyledSpan>
      ) : null}
    </StyledSpan>
  );
};
