import React from 'react';

export const BADGE_COLOR = ['pink', 'white'] as const;

export type BadgeColor = typeof BADGE_COLOR[number];

export type BadgeProps = React.PropsWithChildren<{
  badgeContent?: React.ReactNode;
  maxValue?: number;
  color?: BadgeColor;
}>;
