import React from 'react';
import { SvgIcon, SvgIconProps } from '../../components/svgIcon';

export const MessagesActiveIcon = React.forwardRef<SVGSVGElement, SvgIconProps>((props, ref) => {
  return (
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    <SvgIcon cacheName="mobileMenu/messagesActive" ref={ref as any} {...props}>
      <path
        d="M18.0664 4.5H5.07662C4.06902 4.5 3.25281 5.31669 3.25281 6.32382V14.5893C3.25281 15.5964 4.06902 16.4131 5.07662 16.4131H5.78311L4.34063 19.3066C4.2374 19.4686 4.26199 19.6799 4.39891 19.8131C4.477 19.8901 4.57916 19.9286 4.68184 19.9286C4.7589 19.9286 4.83802 19.9061 4.90703 19.8601L10.9165 16.4131H18.0664C19.074 16.4131 19.8902 15.5964 19.8902 14.5893V6.32382C19.8902 5.31669 19.074 4.5 18.0664 4.5Z"
        fill="#FFCEC3"
      />
      <path
        clipRule="evenodd"
        d="M4.90703 19.8601C4.83802 19.9061 4.7589 19.9286 4.68184 19.9286C4.57916 19.9286 4.477 19.8901 4.39891 19.8131C4.26199 19.6799 4.2374 19.4686 4.34063 19.3066L5.78311 16.4131H5.07662C4.76484 16.4131 4.47137 16.3349 4.21475 16.197C3.64204 15.8894 3.25281 15.2848 3.25281 14.5893V6.32382C3.25281 5.31669 4.06902 4.5 5.07662 4.5H18.0664C19.074 4.5 19.8902 5.31669 19.8902 6.32382V14.5893C19.8902 15.5964 19.074 16.4131 18.0664 16.4131H10.9165L4.90703 19.8601ZM7.28192 16.7686L10.5169 14.9131H18.0664C18.2453 14.9131 18.3902 14.7682 18.3902 14.5893V6.32382C18.3902 6.14486 18.2453 6 18.0664 6H5.07662C4.89771 6 4.75281 6.14486 4.75281 6.32382V14.5893C4.75281 14.7682 4.89771 14.9131 5.07662 14.9131H8.20696L7.28192 16.7686Z"
        fill="#405368"
        fillRule="evenodd"
      />
      <circle cx="7.875" cy="10.125" fill="#405368" r="1.125" />
      <circle cx="11.625" cy="10.125" fill="#405368" r="1.125" />
      <circle cx="15.375" cy="10.125" fill="#405368" r="1.125" />
    </SvgIcon>
  );
});
