import React from 'react';
import { Typography } from '@cp/ds/components/typography';
import { Box, TypographyAccent } from '@cp/ui/components';
import { MOBILE_BREAKPOINT_THRESHOLD, styled } from '@cp/ui/theme';
import { HeaderCollage } from './collage';
import { Tags } from './tags';
import { FastActions } from './fastActions';

const Root = styled(Box, { name: 'Inrto' })(({ theme }) => ({
  display: 'grid',
  gap: '20px',
  gridTemplateAreas: '"HeaderText" "Collage" "Actions" "Links"',
  textAlign: 'center',
  [theme.breakpoints.up(MOBILE_BREAKPOINT_THRESHOLD)]: {
    textAlign: 'initial',
    gridTemplateAreas: '"HeaderText Collage" "Actions Collage" "Links Links"',
    gridTemplateColumns: '1fr 1fr',
  },
}));

const Advantage = styled(Typography)(({ theme }) => ({
  display: 'block',
  '::before': { content: '"•"', color: theme.palette.brand.main },
  color: '#000',
  margin: '12px 0',
}));
const DecoratedHeader = styled(Typography)(({ theme }) => ({
  borderTop: `1px solid ${theme.palette.text.primary}`,
  alignSelf: 'stretch',
  paddingTop: '20px',
  marginBottom: '12px',
  flex: '1',
  [theme.breakpoints.up(MOBILE_BREAKPOINT_THRESHOLD)]: {
    flex: 'initial',
    paddingTop: '40px',
    marginBottom: '20px',
  },
}));

export const Intro = () => {
  return (
    <Root>
      <Box sx={{ gridArea: 'HeaderText' }}>
        <Box sx={{ display: 'flex' }}>
          <DecoratedHeader variant="h1">
            <TypographyAccent>СВОБОДА</TypographyAccent> НА ЧАС
          </DecoratedHeader>
        </Box>
        <Typography component="p" sx={{ m: '12px 0' }} variant="button">
          Няня поиграет с ребенком, погуляет, встретит со школы, поможет с домашними заданиями, чтобы вы спокойно позанимались своими
          делами.
        </Typography>
        <Advantage component="p" variant="button">
          3422 заказов с 2019 года
        </Advantage>
      </Box>
      <HeaderCollage sx={{ gridArea: 'Collage' }} />
      <FastActions
        sx={{ gridArea: 'Actions', justifySelf: { xs: 'stretch', [MOBILE_BREAKPOINT_THRESHOLD]: 'start' }, alignSelf: 'start' }}
      />
      <Tags sx={{ gridArea: 'Links', mt: { [MOBILE_BREAKPOINT_THRESHOLD]: '20px' } }} />
    </Root>
  );
};
