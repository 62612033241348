import React from 'react';
import { Avatar } from '@cp/ds/src/components/avatar';
import { getFirstLetters } from '@cp/shared/lib/getFirstLetters';
import { ClientPhotoCardProps } from './model';

export const ClientPhotoCard: React.FC<ClientPhotoCardProps> = ({ data, ...restProps }) => {
  const { alt, ...contentProps } = React.useMemo(() => {
    if (!data) {
      return { alt: '' };
    }

    const nameAndSurname = [data.name, data.surname].filter(Boolean);

    return {
      alt: nameAndSurname.join(' '),
      ...(data.mainPhoto?.image
        ? {
            image: data.mainPhoto?.image,
          }
        : {
            letters: getFirstLetters(...nameAndSurname),
          }),
    };
  }, [data]);

  return <Avatar alt={alt} content={contentProps} {...restProps} />;
};
