/* eslint-disable @typescript-eslint/no-magic-numbers */
import React from 'react';
import styled from '@mui/material/styles/styled';
import { shouldForwardProp } from '@mui/system';
import { ButtonStatePropsForDescedants } from '../model';
import { buttonSizeToLabelTypographyVariant } from '../const';

type UnstyledLabelComponent = React.ComponentClass<
  React.DetailedHTMLProps<React.HTMLAttributes<HTMLSpanElement>, HTMLSpanElement> & { ownerState: ButtonStatePropsForDescedants }
>;

export const StyledLabel = styled('span' as unknown as UnstyledLabelComponent, {
  shouldForwardProp,
  name: 'ButtonLabel',
  slot: 'Label',
})(({ ownerState, theme }) => {
  const hasIcon = Boolean(ownerState.iconLeft ?? ownerState.iconRight);

  const horizontalSpacing = ownerState.size === 'normal' && ownerState.variant !== 'text' ? (hasIcon ? 34 : 0) : hasIcon ? 7 : 0;
  const verticalSpacing = ownerState.size === 'normal' && ownerState.variant !== 'text' && hasIcon ? 4 : 0;

  return {
    display: 'inline-flex',
    flexGrow: 1,
    justifyContent: 'center',
    alignSelf: 'stretch',
    alignItems: 'center',
    padding: `${verticalSpacing}px ${horizontalSpacing}px`,
    fontSize: 'inherit',
    lineHeight: 'inherit',
    fontWeight: 'inherit',
    fontFamily: 'inherit',
    color: 'inherit',
    ...(theme.typography[buttonSizeToLabelTypographyVariant[ownerState.size]] as React.CSSProperties),
  };
});
