import React from 'react';
import { coreResumeApi } from '@cp/entities/resume';
import { ClientCatalogItemViewModel } from '@common/model/client/catalog/item/clientCatalogItemViewModel';
import { useApiServerErrorHandler } from 'src/shared/apiServer';
import { useAppDispatch } from 'src/redux';
import { useMainResumeId } from './useMainResumeId';

export const useMainResumeRequest = (): [() => Promise<ClientCatalogItemViewModel | undefined>] => {
  const id = useMainResumeId();
  const dispatch = useAppDispatch();
  const handleApiError = useApiServerErrorHandler();

  return [
    React.useCallback(async () => {
      if (!id) {
        throw new Error('Main resume id is not set');
      }

      const response = await dispatch(coreResumeApi.endpoints.getForCurrentClientById.initiate({ id }));

      if ('error' in response && response.error) {
        handleApiError(response.error);

        throw new Error('Request failed');
      } else {
        return response.data;
      }
    }, [dispatch, handleApiError, id]),
  ];
};
