import { format } from 'date-fns';
import { ClientPatronageActiveStages, ClientPatronageStage } from '@common/model/admin/clientPatronage/stage';
import { ClientProfileViewModel } from '@common/model/client';
import { DateFormat } from '@common/const/date';

export enum ClientPatronageHintReason {
  Alert = 'alert',
  ViewMainResume = 'viewMainResume',
  ConnectWithEmployer = 'connectWithEmployer',
}

export interface IContent {
  icon: string;
  title: string;
  description: Record<
    ClientPatronageHintReason,
    string | string[] | ((payload: { clientProfile: ClientProfileViewModel }) => string | string[])
  >;
  actionLabel?: string;
}

export const STAGE_TO_CONTENT: Record<ClientPatronageActiveStages | ClientPatronageStage.Unknown, IContent> = {
  [ClientPatronageStage.Unknown]: {
    icon: '⚠️',
    title: 'Ошибка',
    description: {
      [ClientPatronageHintReason.Alert]: 'Обратитесь в службу технической поддержки.',
      [ClientPatronageHintReason.ViewMainResume]: 'Обратитесь в службу технической поддержки.',
      [ClientPatronageHintReason.ConnectWithEmployer]: 'Обратитесь в службу технической поддержки.',
    },
    actionLabel: 'Техподдержка',
  },
  [ClientPatronageStage.Initial]: {
    icon: '⚠️',
    title: 'Пройти проверку',
    description: {
      [ClientPatronageHintReason.Alert]: 'Вам необходимо пройти видео-интервью чтобы анкета появилась на сайте',
      [ClientPatronageHintReason.ViewMainResume]: 'Для того чтобы ваша анкета была опубликована, вам необходимо пройти видео-интервью.',
      [ClientPatronageHintReason.ConnectWithEmployer]: 'Для того чтобы вы могли откликнуться, вам необходимо пройти видео-интервью.',
    },
    actionLabel: 'Записаться на интервью',
  },
  [ClientPatronageStage.Interview]: {
    icon: '⏰',
    title: 'Вы записаны на встречу ',
    description: {
      [ClientPatronageHintReason.Alert]: ({ clientProfile }) =>
        `Вы записаны на интервью${
          clientProfile.interview
            ? ` ${format(new Date(clientProfile.interview.startedAt), DateFormat.DateDefault)} в ${format(
                new Date(clientProfile.interview.startedAt),
                DateFormat.Time,
              )}`
            : ''
        }. Ждем встречи с вами.`,
      [ClientPatronageHintReason.ViewMainResume]: ({ clientProfile }) => [
        'Для того чтобы ваша анкета была опубликована, вам необходимо пройти видео-интервью.',
        `Вы записаны на интервью${
          clientProfile.interview
            ? ` ${format(new Date(clientProfile.interview.startedAt), DateFormat.DateDefault)} в ${format(
                new Date(clientProfile.interview.startedAt),
                DateFormat.Time,
              )}`
            : ''
        }. Ждем встречи с вами.`,
      ],
      [ClientPatronageHintReason.ConnectWithEmployer]: ({ clientProfile }) => [
        'Для того чтобы вы могли откликнуться, вам необходимо пройти видео-интервью.',
        `Вы записаны на интервью${
          clientProfile.interview
            ? ` ${format(new Date(clientProfile.interview.startedAt), DateFormat.DateDefault)} в ${format(
                new Date(clientProfile.interview.startedAt),
                DateFormat.Time,
              )}`
            : ''
        }. Ждем встречи с вами.`,
      ],
    },
    actionLabel: 'Ок',
  },
  [ClientPatronageStage.Training]: {
    icon: '👨‍🎓',
    title: 'Пройти обучение',
    description: {
      [ClientPatronageHintReason.Alert]: 'Вам необходимо пройти обучение, чтобы анкета появилась на сайте.',
      [ClientPatronageHintReason.ViewMainResume]: 'Для того чтобы ваша анкета была опубликована, вам необходимо пройти обучение.',
      [ClientPatronageHintReason.ConnectWithEmployer]: 'Для того чтобы вы могли откликнуться, вам необходимо пройти обучение.',
    },
    actionLabel: 'Давай начнем',
  },
  [ClientPatronageStage.Photo]: {
    icon: '📸',
    title: 'Загрузить фото',
    description: {
      [ClientPatronageHintReason.Alert]: 'Вам необходимо загрузить фото, чтобы анкета появилась на сайте.',
      [ClientPatronageHintReason.ViewMainResume]: 'Для того чтобы ваша анкета была опубликована, вам необходимо загрузить фото профиля.',
      [ClientPatronageHintReason.ConnectWithEmployer]: 'Для того чтобы вы могли откликнуться, вам необходимо загрузить фото профиля.',
    },
    actionLabel: 'Загрузить фото',
  },
  [ClientPatronageStage.ResumeFilling]: {
    icon: '✏️',
    title: 'Заполнить анкету',
    description: {
      [ClientPatronageHintReason.Alert]: 'Вам необходимо заполнить анкету, чтобы появиться в выдаче.',
      [ClientPatronageHintReason.ViewMainResume]: 'Для того чтобы ваша анкета была опубликована, вам необходимо ее заполнить.',
      [ClientPatronageHintReason.ConnectWithEmployer]: 'Для того чтобы вы могли откликнуться, вам необходимо заполнить анкету.',
    },
    actionLabel: 'Заполнить анкету',
  },
  [ClientPatronageStage.ResumeModeration]: {
    icon: '👀',
    title: 'Ваша анкета на модерации',
    description: {
      [ClientPatronageHintReason.Alert]: 'Мы сообщим как только закончим.',
      [ClientPatronageHintReason.ViewMainResume]:
        'Для того чтобы ваша анкета была опубликована, вам необходимо дождаться окончания модерации.',
      [ClientPatronageHintReason.ConnectWithEmployer]:
        'Для того чтобы вы могли откликнуться, вам необходимо дождаться окончания модерации.',
    },
  },
  [ClientPatronageStage.Rejected]: {
    icon: '🔒',
    title: 'Ваша анкета заблокирована',
    description: {
      [ClientPatronageHintReason.Alert]: 'Обратитесь в службу технической поддержки.',
      [ClientPatronageHintReason.ViewMainResume]: 'Обратитесь в службу технической поддержки.',
      [ClientPatronageHintReason.ConnectWithEmployer]: 'Обратитесь в службу технической поддержки.',
    },
  },
};

export const getContentForStage = (stage: ClientPatronageStage | undefined): IContent => {
  return STAGE_TO_CONTENT[stage ?? ClientPatronageStage.Unknown] ?? STAGE_TO_CONTENT[ClientPatronageStage.Unknown];
};
