import React, { useRef } from 'react';
import { SxProps, styled } from '@cp/ui/theme';
import { RoutedLink } from '@youhelp/client.web/shared/ui/link';
import { FeatureConfig, useHeaderFeatures } from 'src/widgets/header';
import { useIsMobile } from '@cp/ds/hooks/useIsMobile';
import { Container } from '@cp/ui/components';
import { useTrademarkComponent } from '@cp/entities/trademark';
import { useIsLoggedIn } from '@cp/entities/currentUser';
import { useHomeHref } from '#features/home';
import { HeaderContentMobile } from './content.mobile';
import { HeaderContentDesktop } from './content.desktop';

const Root = styled('header', { name: 'Header' })<HeaderProps>(({ theme, sticky }) => ({
  paddingBlockStart: '12px',
  paddingBlockEnd: '12px',
  position: 'relative',
  zIndex: 10,
  ...(sticky && {
    position: 'sticky',
    top: 0,
    left: 0,
    right: 0,
    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.1)',
    backgroundColor: theme.palette.background.default,
  }),
}));

export interface HeaderProps {
  onGoBack?: React.MouseEventHandler;
  leftBlock?: React.ReactNode | null;
  rightBlock?: React.ReactNode | null;
  sticky?: boolean;
  sx?: SxProps;
  mobileFeatures?: FeatureConfig;
  desktopFeatures?: FeatureConfig;
}
export const Header: React.FC<HeaderProps> = ({ mobileFeatures, desktopFeatures, leftBlock, rightBlock, ...props }) => {
  const Trademark = useTrademarkComponent();
  const isMobile = useIsMobile();
  const features = useHeaderFeatures(mobileFeatures, desktopFeatures);
  const logoHref = useHomeHref();
  const isLoggedIn = useIsLoggedIn();
  const containerRef = useRef<HTMLElement>(null);

  const Content = isMobile ? HeaderContentMobile : HeaderContentDesktop;
  return (
    <Root ref={containerRef} {...props}>
      <Container>
        <Content
          containerRef={containerRef}
          features={features}
          isLoggedIn={isLoggedIn}
          leftBlock={leftBlock}
          logo={
            <RoutedLink href={logoHref}>
              <Trademark />
            </RoutedLink>
          }
          rightBlock={rightBlock}
        />
      </Container>
    </Root>
  );
};
