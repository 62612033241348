/* eslint-disable @typescript-eslint/no-magic-numbers */
import React from 'react';
import { Stack } from '@cp/ds/src/components/stack';
import { Typography } from '@cp/ds/src/components/typography';
import { Box } from '@cp/ds/src/components/box';
import { mergeSx } from '@cp/ds/src/utils/sx/merge';
import { alpha, keyframes } from '@mui/material/styles';
import { useTheme } from '@cp/ds/src/hooks/useTheme';
import { MOBILE_BREAKPOINT_THRESHOLD } from '@cp/ds/src/theme';
import { cx } from '@emotion/css';
import { ClientPatronageStage } from '@common/model/admin/clientPatronage/stage';
import { CLIENT_PATRONAGE_STAGE_TO_CLIENT_PATRONAGE_STAGE_NAME, EButtonName } from 'src/shared/tracking';
import { ClientPatronageHintReason, getContentForStage } from '../../lib';
import { ClientPatronageAlertProps } from './model';

export * from './model';

const BOUNCE = keyframes`
  from {
    transform: scale(1);
  }
  50% {
    transform: scale(1.5);
  }
  to {
    transform: scale(1);
  }
`;

export const ClientPatronageAlert: React.FC<ClientPatronageAlertProps> = ({ clientProfile, onClick, sx }) => {
  const theme = useTheme();
  const content = getContentForStage(clientProfile.patronageStage);
  const description = content.description[ClientPatronageHintReason.Alert];

  return (
    <Stack
      direction="row"
      gap={{ xs: 1.5, [MOBILE_BREAKPOINT_THRESHOLD]: 0.75 }}
      onClick={onClick}
      sx={mergeSx(
        {
          py: 0.5,
          px: 1.5,
          backgroundColor: alpha(theme.palette['brand pink'], 0.1),
          borderRadius: '12px',
          cursor: onClick ? 'pointer' : 'default',
          boxShadow: `0px 2.16656px 3.24983px 0px ${alpha(theme.palette.black, 0.1)}`,
          alignItems: { xs: 'flex-start', [MOBILE_BREAKPOINT_THRESHOLD]: 'center' },
        },
        sx,
      )}
      {...(onClick ? { tabIndex: 0, role: 'button' } : undefined)}
      className={cx(
        EButtonName.ClientPatronageAlert,
        CLIENT_PATRONAGE_STAGE_TO_CLIENT_PATRONAGE_STAGE_NAME[clientProfile.patronageStage as ClientPatronageStage],
      )}
    >
      <Typography
        component="div"
        sx={{
          fontFamily: '"Source Sans Pro", "Helvetica Neue", Helvetica, Arial, sans-serif',
          animation: `${BOUNCE} 2s ease-in-out infinite`,
        }}
        variant="button secondary"
      >
        {content.icon}
      </Typography>
      <Box component="div">
        <Typography component="div" variant="caption bold">
          {content.title}
        </Typography>
        <Typography component="div" variant="small text">
          {typeof description === 'function' ? description({ clientProfile }) : description}
        </Typography>
      </Box>
    </Stack>
  );
};
