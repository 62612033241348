import React from 'react';
import { SvgIcon } from '../components/svgIcon';
import { SvgIconProps } from '../components/svgIcon/model';

export const ArrowRightIcon = React.forwardRef<SVGSVGElement, SvgIconProps>((props, ref) => {
  return (
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    <SvgIcon cacheName="arrowRight" ref={ref as any} {...props}>
      <path
        d="M16.1506 16.2232C15.8666 16.5122 15.4061 16.5122 15.1221 16.2232C14.8381 15.9341 14.8381 15.4654 15.1221 15.1763L17.517 12.7386H4.72727C4.32562 12.7386 4 12.4072 4 11.9984C4 11.5896 4.32562 11.2581 4.72727 11.2581H17.517L15.1222 8.8205C14.8381 8.53145 14.8381 8.06272 15.1222 7.77362C15.2641 7.62912 15.4502 7.55682 15.6364 7.55682C15.8225 7.55682 16.0086 7.62912 16.1506 7.77367L19.787 11.475C20.071 11.764 20.071 12.2328 19.787 12.5219L16.1506 16.2232Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
});
