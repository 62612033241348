import { ElementType } from 'react';
import { OverrideProps } from '@mui/material/OverridableComponent';
import { SxProps } from '../../theme';

export const LINK_SIZE = ['small', 'normal'] as const;

export type LinkSize = (typeof LINK_SIZE)[number];

export const LINK_COLOR = ['dark', 'light', 'pink'] as const;

export type LinkColor = (typeof LINK_COLOR)[number];

export const LINK_COMPONENT_DEFAULT = 'a' as const;

export type LinkComponentDefault = typeof LINK_COMPONENT_DEFAULT;

export interface LinkOwnProps {
  component?: ElementType;
  disableHover?: boolean;
  size?: LinkSize;
  external?: boolean;
  underline?: boolean;
  sx?: SxProps;
  color?: LinkColor;
}

export type LinkOwnerState = Required<Omit<LinkOwnProps, 'sx'>>;

export interface LinkTypeMap<D extends ElementType = LinkComponentDefault> {
  props: LinkOwnProps;
  defaultComponent: D;
}

export type LinkProps<D extends ElementType = LinkTypeMap['defaultComponent']> = OverrideProps<LinkTypeMap<D>, D>;
