import React from 'react';
import { SvgIcon } from '../components/svgIcon';
import { SvgIconProps } from '../components/svgIcon/model';

export const SignoutIcon = React.forwardRef<SVGSVGElement, SvgIconProps>((props, ref) => {
  return (
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    <SvgIcon cacheName="signout" ref={ref as any} {...props}>
      <path
        d="M13.3333 6H16C16.3536 6 16.6927 6.14048 16.9428 6.39052C17.1928 6.64057 17.3333 6.97971 17.3333 7.33333V16.6667C17.3333 17.4 16.7333 18 16 18H13.3333M9.33331 15.3333L5.99998 12L9.33331 8.66667M6.79998 12H14"
        fill="none"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
      />
    </SvgIcon>
  );
});
