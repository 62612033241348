import { CareerViewModel } from '../../model/resource/careerViewModel';

export enum CareerId {
  Nanny = 1,
  Nurse = 2,
  Tutor = 3,
  Housekeeper = 4,
}

// Mocks
export const careerDictionary: {
  [CareerId: number]: CareerViewModel;
} = {
  1: {
    id: 1,
    alias: 'nanny',
    translation: {
      id: 1,
      languageCode: 'ru-RU',
      namePlural: 'няни',
      namePluralGenitive: 'нянь',
      namePluralPrepositional: 'нянях',
      nameSingle: 'няня',
      nameSingleAccusative: 'няню',
      nameSingleGenitive: 'няни',
      nameSinglePrepositional: 'няне',
      nameSingleInstrumental: 'няней',
      nameSingleDative: 'няне',
      nameDeclination1: 'няня',
      nameDeclination2: 'няни',
      nameDeclination3: 'нянь',
    },
  },
  2: {
    id: 2,
    alias: 'nurse',
    translation: {
      id: 2,
      languageCode: 'ru-RU',
      namePlural: 'сиделки',
      namePluralGenitive: 'сиделок',
      namePluralPrepositional: 'сиделках',
      nameSingle: 'сиделка',
      nameSingleAccusative: 'сиделку',
      nameSingleGenitive: 'сиделки',
      nameSinglePrepositional: 'сиделке',
      nameSingleInstrumental: 'сиделкой',
      nameSingleDative: 'сиделке',
      nameDeclination1: 'сиделка',
      nameDeclination2: 'сиделки',
      nameDeclination3: 'сиделок',
    },
  },
  3: {
    id: 3,
    alias: 'tutor',
    translation: {
      id: 3,
      languageCode: 'ru-RU',
      namePlural: 'репетиторы',
      namePluralGenitive: 'репетиторов',
      namePluralPrepositional: 'репетиторах',
      nameSingle: 'репетитор',
      nameSingleAccusative: 'репетитора',
      nameSingleGenitive: 'репетитора',
      nameSinglePrepositional: 'репетиторе',
      nameSingleInstrumental: 'репетитором',
      nameSingleDative: 'репетитору',
      nameDeclination1: 'репетитор',
      nameDeclination2: 'репетитора',
      nameDeclination3: 'репетиторов',
    },
  },
  4: {
    id: 4,
    alias: 'housekeeper',
    translation: {
      id: 4,
      languageCode: 'ru-RU',
      namePlural: 'домработницы',
      namePluralGenitive: 'домработниц',
      namePluralPrepositional: 'домработницах',
      nameSingle: 'домработница',
      nameSingleAccusative: 'домработницу',
      nameSingleGenitive: 'домработницы',
      nameSinglePrepositional: 'домработнице',
      nameSingleInstrumental: 'домработницей',
      nameSingleDative: 'домработнице',
      nameDeclination1: 'домработница',
      nameDeclination2: 'домработницы',
      nameDeclination3: 'домработниц',
    },
  },
} as unknown as {
  [CareerId: number]: CareerViewModel;
};
