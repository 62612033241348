import React from 'react';
import { ReactAppPageNotFoundError } from '@common/model/reactApp/error';
import { PageSkeleton } from '@cp/ds/src/components/pageSkeleton';
import { coreLocationApi } from '../../api';
// FIXME: import from another package
import { useClientWebRouteParams } from '../../../clientWebRoutes';
import { CurrentCityContext } from './context';

// City data fetching is used inside context provider,
// to prevent child components rerender before this component rerender.
// When we use redux instead of react context, we can see situation,
// where wrapper component rerendered with non-empty city data
// and do not block child components render with empty city data.
// Bug discussion: https://github.com/reduxjs/react-redux/issues/1589

export const CurrentCityContextProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
  const { cityAlias, hasCitySeparatedContent } = useClientWebRouteParams();
  const { data, isLoading } = coreLocationApi.endpoints.getCities.useQuery(
    {
      alias: cityAlias ?? '',
    },
    { skip: !hasCitySeparatedContent || !cityAlias },
  );

  const value = React.useMemo(
    () => ({
      currentCity: data?.[0],
    }),
    [data],
  );

  if (!value.currentCity && hasCitySeparatedContent) {
    if (isLoading) {
      return <PageSkeleton />;
    } else {
      throw new ReactAppPageNotFoundError();
    }
  }

  return <CurrentCityContext.Provider value={value}>{children}</CurrentCityContext.Provider>;
};
