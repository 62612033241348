import React, { PropsWithChildren } from 'react';
import { ModalProvider as YhModalProvider } from '@youhelp/client.web/app/shared/components/modalProvider';
import { AppModalsResolver } from '@youhelp/client.web/app/providers/modal';

export const ModalProvider = (props: PropsWithChildren) => {
  return (
    <AppModalsResolver>
      {props.children}
      <YhModalProvider />
    </AppModalsResolver>
  );
};
