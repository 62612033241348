import { SxProps } from '@cp/ds';
import { Badge } from '@cp/ds/src/components/badge';
import { Box } from '@cp/ds/src/components/box';
import { Typography } from '@cp/ds/src/components/typography';
import { useTheme } from '@cp/ds/src/hooks/useTheme';
import React from 'react';
import { useMediaQuery } from '@mui/material';
import { ClientPhotoCard } from '@cp/entities/clientPhoto';
import { IPHONE_LARGE_SCREEN_WIDTH } from '@common/const/display';
import { AnnounceHintWrapper } from '@cp/ds/src/components/announceHintWrapper';
import { RoutedLink as Link } from 'src/shared/ui/link';
import { MenuItemProps } from '../model/item.interface';

export const BottomMenuItem: React.FC<MenuItemProps> = ({
  onClick,
  icon,
  isActiveItem,
  isProfile,
  title,
  href,
  badgeContent = 0,
  clientProfile,
}) => {
  const theme = useTheme();
  const Icon = icon;
  const isTabletScreen = useMediaQuery(theme.breakpoints.up(IPHONE_LARGE_SCREEN_WIDTH));

  const content = (
    <>
      {isProfile && clientProfile.mainPhoto ? (
        <ClientPhotoCard
          data={clientProfile}
          frameSx={{ border: `2px solid ${isActiveItem ? theme.palette['brand pink'] : 'transparent'}` }}
          rounded
          // eslint-disable-next-line @typescript-eslint/no-magic-numbers
          size={isTabletScreen ? 40 : 32}
        />
      ) : Icon ? (
        <Badge badgeContent={badgeContent}>
          <Icon sx={{ fontSize: isTabletScreen ? '2rem' : '2rem' }} />
        </Badge>
      ) : null}
      <Typography sx={{ color: theme.palette[isActiveItem ? 'brand gray' : 'gray'] }} variant="small text bold">
        {title}
      </Typography>
    </>
  );

  const containerSx: SxProps = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'flex-end',
    WebkitTapHighlightColor: 'transparent',
  };

  return href || onClick ? (
    <Link href={href} onClick={onClick} sx={containerSx} underline={false}>
      {content}
    </Link>
  ) : (
    <AnnounceHintWrapper>
      {({ setAnnounceMessageVisible }) => (
        <Box onClick={() => setAnnounceMessageVisible(true)} sx={containerSx}>
          {content}
        </Box>
      )}
    </AnnounceHintWrapper>
  );
};
