import React from 'react';
import { SvgIcon } from '../components/svgIcon';
import { SvgIconProps } from '../components/svgIcon/model';

export const RubOutlineIcon = React.forwardRef<SVGSVGElement, SvgIconProps>((props, ref) => {
  return (
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    <SvgIcon cacheName="rub-outline" ref={ref as any} {...props}>
      <path
        d="M10.4385 6.29385H12.7437C14.7917 6.29385 15.8885 7.27183 15.8885 9.08551C15.8885 10.92 14.8031 11.7557 12.3521 11.7557H10.4385V6.29385ZM10.4385 18.2459V16.3914H13.4719C13.9302 16.3914 14.2104 16.1676 14.2104 15.791C14.2104 15.403 13.9417 15.1896 13.4708 15.1896H10.4396V13.0297H12.7885C15.676 13.0297 17.5 11.4807 17.5 9.04567C17.5 6.5993 15.6531 5 12.8333 5H10.45C9.36458 5 8.87292 5.40789 8.87292 6.32421V11.7557H7.29479C6.79167 11.7557 6.5 11.99 6.5 12.3875C6.5 12.7954 6.79167 13.0297 7.29479 13.0297H8.87292V15.1896H7.25C6.76875 15.1896 6.5 15.403 6.5 15.791C6.5 16.1676 6.76875 16.3914 7.25 16.3914H8.87292V18.2468C8.87292 18.7249 9.16354 19 9.65625 19C10.1479 19 10.4385 18.7154 10.4385 18.2468V18.2459Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
});
