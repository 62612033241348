import React from 'react';
import { Stack as MuiStack, StackTypeMap } from '@mui/material';
import { OverridableComponent, OverrideProps } from '@mui/material/OverridableComponent';

export type StackProps<D extends React.ElementType = StackTypeMap['defaultComponent'], P = {}> = OverrideProps<StackTypeMap<P, D>, D>;

export type StackWithForwardedRef<D extends React.ElementType = StackTypeMap['defaultComponent'], P = {}> = (
  props: StackProps<D, P>,
  ref: React.ForwardedRef<D>,
) => React.ReactElement | null;

const StackInner: StackWithForwardedRef = (props, ref) => {
  // @ts-ignore
  return <MuiStack ref={ref} {...props} />;
};

export const Stack = React.forwardRef(StackInner) as OverridableComponent<StackTypeMap>;
