import { SxProps } from '../../../../theme';
import { AvatarSizeProp } from '../../types';

export const getAvatarContentSizeStyles = (
  contentSize: AvatarSizeProp | undefined,
  { containerSize, contentSizeToContainerSizeRate }: { containerSize: AvatarSizeProp; contentSizeToContainerSizeRate?: number },
): SxProps => {
  let size = contentSize;

  if (!size && typeof containerSize === 'number' && contentSizeToContainerSizeRate) {
    size = containerSize * contentSizeToContainerSizeRate;
  }

  return {
    height: size,
    maxHeight: size,
    maxWidth: size,
    width: size,
  };
};
