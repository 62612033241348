import React from 'react';
import { Drawer } from '@cp/ds/src/components/drawer';
import loadable from '@loadable/component';
import { MenuContentProps } from './menuContent';

export interface MenuProps extends MenuContentProps {
  isOpen: boolean;
}

// Load content async to reduce initial loaded & rendered components count on the page
let isMenuContentLoaded = false;
const MenuContent = loadable(() => {
  return import('./menuContent').then((component) => {
    isMenuContentLoaded = true;
    return component;
  });
});

export const Menu: React.FC<MenuProps> = ({ isOpen, ...props }) => {
  return (
    <Drawer
      BackdropProps={{ sx: { zIndex: 3 } }}
      ModalProps={{
        components: { Root: 'div' },
      }}
      PaperProps={{ sx: { backgroundColor: 'white', zIndex: 90 } }}
      anchor="right"
      onClose={props.onClose}
      open={isOpen}
      sx={{ zIndex: 90 }}
      variant="temporary"
    >
      {/* Render & load component only after filter opening */}
      {(isOpen || isMenuContentLoaded) && <MenuContent {...props} />}
    </Drawer>
  );
};
