import { ClientWebPage } from '@common/model/meta/clientWebPage';
import { modalViewIsAnyOpenedSelector } from '@cp/ds/src/components/modalView';
import { useGetQueryParams } from '@cp/shared/queryParams';
import { useClientWebRouteParams } from '@cp/entities/clientWebRoutes';
import { useSelector } from 'react-redux';
import { firstModalInQueueSelector } from 'src/redux/slices/modal/queue';
import { catalogLayoutActiveItemIdSelector } from 'src/features/catalog';
import { citySelectionIsOpenSelector } from 'src/redux/slices/citySelection';
import { mainResumeViewDataSelector } from 'src/entities/mainResume';

export const useVisibility = () => {
  const { pageId: currentPageId } = useClientWebRouteParams();
  const isOpenedModal = Boolean(useSelector(firstModalInQueueSelector));
  const catalogItemId = useSelector(catalogLayoutActiveItemIdSelector);
  const isCitySelectionOpened = useSelector(citySelectionIsOpenSelector);
  const isMainResumeVisible = Boolean(useSelector(mainResumeViewDataSelector));
  const isAnyModalViewOpened = useSelector(modalViewIsAnyOpenedSelector);
  const queryParams = useGetQueryParams();

  const isHidden = Boolean(
    catalogItemId ||
      isOpenedModal ||
      currentPageId === ClientWebPage.ProfilePhoto ||
      isCitySelectionOpened ||
      (currentPageId === ClientWebPage.ProfileMessages && Boolean('dialogId' in queryParams && queryParams.dialogId)) ||
      currentPageId === ClientWebPage.CatalogItem ||
      isMainResumeVisible ||
      isAnyModalViewOpened,
  );

  return {
    isVisible: !isHidden,
  };
};
