import React from 'react';
import { SvgIcon, SvgIconProps } from '../../components/svgIcon';

export const SigninActiveIcon = React.forwardRef<SVGSVGElement, SvgIconProps>((props, ref) => {
  return (
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    <SvgIcon cacheName="mobileMenu/signinActive" ref={ref as any} {...props}>
      <path
        d="M12.86 10.806a3.82 3.82 0 0 1-3.814-3.813c0-2.101 1.713-3.846 3.813-3.846a3.82 3.82 0 0 1 3.814 3.813c0 2.101-1.713 3.846-3.814 3.846Z"
        fill="#FFCEC3"
      />
      <path
        d="M12.86 10.806a3.82 3.82 0 0 1-3.814-3.813c0-2.101 1.713-3.846 3.813-3.846a3.82 3.82 0 0 1 3.814 3.813c0 2.101-1.713 3.846-3.814 3.846Zm.016-6.024c-1.39 0-2.18.79-2.18 2.18 0 1.39.774 2.181 2.163 2.181 1.39 0 2.198-.793 2.198-2.183 0-1.39-.79-2.178-2.18-2.178Z"
        fill="#405368"
      />
      <path
        d="M18.03 19.5H7.689a1.69 1.69 0 0 1-1.681-1.68v-.227c0-2.812 1.68-5.332 4.298-6.367.42-.162.937-.194 1.39 0a3.682 3.682 0 0 0 2.359 0c.452-.162.97-.162 1.39 0 2.617 1.067 4.298 3.555 4.298 6.367v.194c0 .937-.776 1.713-1.713 1.713Z"
        fill="#FFCEC3"
      />
      <path
        d="M18.03 19.5H7.689a1.69 1.69 0 0 1-1.681-1.68v-.227c0-2.812 1.68-5.332 4.298-6.367.42-.162.937-.194 1.39 0a3.684 3.684 0 0 0 2.359 0 2.05 2.05 0 0 1 1.39 0c2.617 1.067 4.298 3.555 4.298 6.367v.194c0 .937-.776 1.713-1.713 1.713Zm-6.983-6.574c-.065 0-.162 0-.226.032-2.133.84-3.239 1.953-3.239 4.247v.194c0 .226.194.388.388.388l9.812.032a.393.393 0 0 0 .388-.387v-.194c0-2.295-1.04-3.44-3.174-4.28-.129-.064-.29-.064-.484 0a4.743 4.743 0 0 1-3.271 0c0-.032-.097-.032-.194-.032Z"
        fill="#405368"
      />
    </SvgIcon>
  );
});
