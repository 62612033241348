import { Button } from '@cp/ds/src/components/button';
import { BurgerIcon } from '@cp/ds/src/icons/burger';
import React from 'react';
import { mergeSx } from '@cp/ds/src/utils/sx/merge';
import { SxProps } from '@cp/ds';
import { Menu } from './menu';

export interface BurgerMenuProps {
  sx?: SxProps;
}

export const BurgerMenu: React.FC<BurgerMenuProps> = ({ sx }) => {
  const [isOpen, setOpen] = React.useState(false);

  return (
    <>
      <Button color="gray" onClick={() => setOpen(true)} size="small" square sx={mergeSx({}, sx)} variant="text">
        <BurgerIcon />
      </Button>

      <Menu isOpen={isOpen} onClose={() => setOpen(false)} />
    </>
  );
};
