import { CoreClientApiTags, coreApi } from '@cp/shared/api/core';
import { ClientCatalogItemViewModel } from '@common/model/client/catalog/item/clientCatalogItemViewModel';
import { ApiRoutePathParams } from '@common/api/routePathParams';
import { ApiRouteBody } from '@common/api/routeBody';
import { ApiRoute } from '@common/api/route';
import { generateApiUrl } from '@common/api/configure';
import { apiRouteMethodDict } from '@common/api/routeMethod';
import { CoreResumeApiTags, coreResumeApiTagTypes } from './tags';

export const coreResumeApi = coreApi
  .enhanceEndpoints({
    addTagTypes: coreResumeApiTagTypes,
  })
  .injectEndpoints({
    endpoints: (builder) => ({
      getForCurrentClientById: builder.query<ClientCatalogItemViewModel, ApiRoutePathParams[ApiRoute.ClientResumesGetOne]>({
        query: (params) => {
          return {
            url: generateApiUrl(ApiRoute.ClientResumesGetOne, params),
          };
        },
        providesTags: (_result, _error, { id }) => [{ type: CoreResumeApiTags.CurrentClientResume, id }],
      }),

      updateForCurrentClientById: builder.mutation<
        void,
        { params: ApiRoutePathParams[ApiRoute.ClientResumesUpdate]; body: ApiRouteBody[ApiRoute.ClientResumesUpdate] }
      >({
        query: ({ params, body }) => ({
          url: generateApiUrl(ApiRoute.ClientResumesUpdate, params),
          method: apiRouteMethodDict[ApiRoute.ClientResumesUpdate],
          body,
        }),
        invalidatesTags: (_result, _error, { params: { id } }) => [
          { type: CoreResumeApiTags.CurrentClientResume, id },
          CoreClientApiTags.ClientProfile,
        ],
      }),

      createScheduleIntervalForCurrentClient: builder.mutation<
        void,
        {
          params: ApiRoutePathParams[ApiRoute.ClientResumesScheduleIntervalsCreate];
          body: ApiRouteBody[ApiRoute.ClientResumesScheduleIntervalsCreate];
        }
      >({
        query: ({ params, body }) => ({
          url: generateApiUrl(ApiRoute.ClientResumesScheduleIntervalsCreate, params),
          method: apiRouteMethodDict[ApiRoute.ClientResumesScheduleIntervalsCreate],
          body,
        }),
        invalidatesTags: (_result, _error, { params: { id } }) => [{ type: CoreResumeApiTags.CurrentClientResume, id }],
      }),

      deleteScheduleIntervalForCurrentClient: builder.mutation<
        void,
        {
          params: ApiRoutePathParams[ApiRoute.ClientResumesScheduleIntervalsDelete];
          body: ApiRouteBody[ApiRoute.ClientResumesScheduleIntervalsDelete];
        }
      >({
        query: ({ params, body }) => ({
          url: generateApiUrl(ApiRoute.ClientResumesScheduleIntervalsDelete, params),
          method: apiRouteMethodDict[ApiRoute.ClientResumesScheduleIntervalsDelete],
          body,
        }),
        invalidatesTags: (_result, _error, { params: { id } }) => [{ type: CoreResumeApiTags.CurrentClientResume, id }],
      }),
    }),
  });
