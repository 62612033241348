import { TRAINING_MODAL_NAME } from '../modals/training/const';
import { AUDIO_MESSAGE_RECORD_MODAL_NAME } from '../modals/audioMessageRecord/const';
import { CLIENT_PROFILE_PHOTO_MODAL_NAME } from '../modals/clientProfilePhoto/const';
import { CURRENT_CLIENT_CONTACTS_MODAL_NAME } from '../modals/currentClientContacts/const';
import { CURRENT_CLIENT_INTERVIEW_DETAILS } from '../modals/currentClientInterviewDetails/const';
import { CURRENT_CLIENT_INTERVIEW_SCHEDULE } from '../modals/currentClientInterviewSchedule/const';
import { CURRENT_CLIENT_PATRONAGE_HINTS } from '../modals/currentClientPatronageHints/const';
import { CURRENT_CLIENT_PERSONAL_INFORMATION_EDIT_MODAL_NAME } from '../modals/currentClientPersonalInformationEdit/const';
import { EXAMPLE_CLIENT_PHOTO_MODAL_NAME } from '../modals/exampleClientPhoto/const';
import { RESUME_ADDITIONAL_EDIT_MODAL_NAME } from '../modals/resumeAdditionalEdit/const';
import { RESUME_DESCRIPTION_EDIT_MODAL_NAME } from '../modals/resumeDescriptionEdit/const';
import { RESUME_TARIFFS_EDIT_MODAL_NAME } from '../modals/resumeTariffsEdit/const';
import { RESUME_OPTIONS_EDIT_MODAL_NAME } from '../modals/resumeOptionsEdit/const';
import { VACANCY_CREATE_MODAL_NAME } from '../modals/vacancyCreate/const';
import { PAYMENT_SUCCESS_MODAL_NAME } from '../modals/paymentSuccess/const';
import { ModalDictionary } from './model';

export const modalsWithFullscreenMobileView: (keyof ModalDictionary)[] = [
  CLIENT_PROFILE_PHOTO_MODAL_NAME,
  EXAMPLE_CLIENT_PHOTO_MODAL_NAME,
  AUDIO_MESSAGE_RECORD_MODAL_NAME,
  CURRENT_CLIENT_CONTACTS_MODAL_NAME,
  RESUME_DESCRIPTION_EDIT_MODAL_NAME,
  RESUME_TARIFFS_EDIT_MODAL_NAME,
  RESUME_ADDITIONAL_EDIT_MODAL_NAME,
  RESUME_OPTIONS_EDIT_MODAL_NAME,
  VACANCY_CREATE_MODAL_NAME,
  CURRENT_CLIENT_PERSONAL_INFORMATION_EDIT_MODAL_NAME,
  CURRENT_CLIENT_INTERVIEW_SCHEDULE,
  CURRENT_CLIENT_INTERVIEW_DETAILS,
  CURRENT_CLIENT_PATRONAGE_HINTS,
  TRAINING_MODAL_NAME,
  PAYMENT_SUCCESS_MODAL_NAME,
];
