import React from 'react';
import { useIsMobile } from '@cp/ds/hooks/useIsMobile';
import { Box, Container } from '@cp/ui/components';
import { IconButton } from '@cp/ds/components/iconButton';
import { Link } from '@cp/ds/components/link';
import { Typography } from '@cp/ds/components/typography';
import { OkIcon } from '@cp/ds/icons/social/ok';
import { VkIcon } from '@cp/ds/icons/social/vk';
import { YoutubeIcon } from '@cp/ds/icons/social/youtube';
import { Stack } from '@cp/ds/components/stack';
import { StyledListItem } from '@cp/ds/components/styled/styledListItem';
import { StyledUnorderedList } from '@cp/ds/components/styled/styledUnorderedList';
import { useIsLoggedIn } from '@cp/entities/currentUser';
import { useConfig } from '@cp/shared/config';

export const FooterBottom = () => {
  const isMobile = useIsMobile();
  const loggedIn = useIsLoggedIn();
  const config = useConfig();

  return (
    <Box sx={{ backgroundColor: 'gray middle', color: 'text.primary', zIndex: 1, pb: isMobile && loggedIn ? 10 : 0 }}>
      <Container>
        <Stack direction="row" sx={{ width: { xs: 1, xl: 0.77 }, m: '0 auto', justifyContent: 'space-between', py: 2.25 }}>
          <Stack alignItems="center" direction="row" flexWrap="wrap">
            <Typography component="h5" sx={{ my: 0, mr: 2 }} variant="body bold">
              Присоединяйтесь{!isMobile && ' к нам'}:
            </Typography>
            <Stack component={StyledUnorderedList} direction="row" spacing={2}>
              {config.get('VK_GROUP') && (
                <StyledListItem>
                  <Link external href={config.get('VK_GROUP', '')} underline={false}>
                    <IconButton color="gray dark" sx={{ borderColor: 'brand gray', padding: 0 }} variant="secondary">
                      <VkIcon title="ВКонтакте" titleAccess="ВКонтакте" />
                      <Typography component="span" sx={{ visibility: 'hidden', position: 'absolute' }}>
                        ВКонтакте
                      </Typography>
                    </IconButton>
                  </Link>
                </StyledListItem>
              )}
              {config.get('OK_GROUP') && (
                <StyledListItem>
                  <Link external href={config.get('OK_GROUP', '')} underline={false}>
                    <IconButton color="gray dark" sx={{ borderColor: 'brand gray', padding: 0 }} variant="secondary">
                      <OkIcon title="Одноклассники" titleAccess="Одноклассники" />
                      <Typography component="span" sx={{ visibility: 'hidden', position: 'absolute' }}>
                        Одноклассники
                      </Typography>
                    </IconButton>
                  </Link>
                </StyledListItem>
              )}
              {config.get('YOUTUBE_CHANNEL') && (
                <StyledListItem>
                  <Link external href={config.get('YOUTUBE_CHANNEL', '')} underline={false}>
                    <IconButton color="gray dark" sx={{ borderColor: 'brand gray', padding: 0 }} variant="secondary">
                      <YoutubeIcon title="YouTube" titleAccess="YouTube" />
                      <Typography component="span" sx={{ visibility: 'hidden', position: 'absolute' }}>
                        YouTube
                      </Typography>
                    </IconButton>
                  </Link>
                </StyledListItem>
              )}
            </Stack>
          </Stack>
        </Stack>
      </Container>
    </Box>
  );
};
