import { createContext, useContext } from 'react';
import { IRouter } from './router.interface';
import { RouterError } from './errors';

const RouterContext = createContext<IRouter>({
  location: { pathname: '/', hash: '', search: '', href: '', origin: '' },
  navigate: () => Promise.reject(RouterError.NotInitialized()),
});
RouterContext.displayName = 'IRouter';

export const RouterContextProvider = RouterContext.Provider;

export const useRouter = (): IRouter => useContext(RouterContext);
