import React from 'react';
import { Box, Image } from '@cp/ui/components';
import { SxProps, styled } from '@cp/ui/theme';
import c1 from './assets/c1.png';
import c2 from './assets/c2.png';
import c3 from './assets/c3.png';

const Root = styled(Box, { name: 'HeaderCollage' })({
  display: 'inline-grid',
  gap: '20px',
  gridTemplateAreas: '"a b" "a c"',
});

export const HeaderCollage = (props: { sx?: SxProps }) => {
  return (
    <Root {...props}>
      <Image alt="" src={c1.src} sx={{ gridArea: 'a', maxWidth: '100%' }} />
      <Image alt="" src={c2.src} sx={{ gridArea: 'b', maxWidth: '100%' }} />
      <Image alt="" src={c3.src} sx={{ gridArea: 'c', maxWidth: '100%' }} />
    </Root>
  );
};
