import React from 'react';
import { SvgIcon } from '../components/svgIcon';
import { SvgIconProps } from '../components/svgIcon/model';

export const PortfolioIcon = React.forwardRef<SVGSVGElement, SvgIconProps>((props, ref) => {
  return (
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    <SvgIcon cacheName="portfolio" ref={ref as any} {...props}>
      <path
        d="M15.38 3.276a.385.385 0 0 0-.274-.118H6.973a1.925 1.925 0 0 0-1.92 1.916v14.364a1.925 1.925 0 0 0 1.92 1.916h10.686a1.925 1.925 0 0 0 1.92-1.916V7.75a.389.389 0 0 0-.113-.267L15.38 3.276Zm.109 1.211 2.795 2.876H15.87a.378.378 0 0 1-.381-.374V4.487Zm2.17 16.103H6.973a1.16 1.16 0 0 1-1.156-1.152V5.074a1.16 1.16 0 0 1 1.156-1.151h7.751v3.063c0 .631.513 1.142 1.144 1.142h2.946v11.31a1.16 1.16 0 0 1-1.155 1.152Z"
        fill="currentColor"
      />
      <path
        clipRule="evenodd"
        d="M6.973 2.833h8.136a.71.71 0 0 1 .505.217v.001l4.084 4.203v.002c.13.13.203.306.206.489V19.44a2.25 2.25 0 0 1-2.244 2.24H6.973a2.25 2.25 0 0 1-2.245-2.24V5.072a2.25 2.25 0 0 1 2.245-2.239Zm0 .65a1.6 1.6 0 0 0-1.595 1.592v14.363a1.6 1.6 0 0 0 1.595 1.591h10.685a1.6 1.6 0 0 0 1.596-1.591V7.754a.064.064 0 0 0-.019-.042l-.002-.003-4.086-4.206-.002-.002a.06.06 0 0 0-.041-.018h-8.13Zm-.001.115h8.077v3.387c0 .452.367.818.819.818h3.271V19.44a1.484 1.484 0 0 1-1.48 1.474H6.972a1.485 1.485 0 0 1-1.48-1.474V5.072a1.484 1.484 0 0 1 1.48-1.474Zm7.427.65H6.973a.834.834 0 0 0-.83.827v14.362a.834.834 0 0 0 .83.828h10.685a.835.835 0 0 0 .831-.828V8.453h-2.62m-1.47-4.205v2.738c.001.81.659 1.467 1.47 1.467m-.705-4.766 3.89 4.001-3.674-.205m.434-2.195v1.699c0 .028.024.051.053.051h1.648l-1.701-1.75Z"
        fill="currentColor"
        fillRule="evenodd"
      />
      <path d="M16.598 17.76h-8.8a.382.382 0 0 0 0 .765h8.8a.382.382 0 1 0 0-.764Z" fill="currentColor" />
      <path
        clipRule="evenodd"
        d="M7.09 18.143c0-.39.317-.707.708-.707h8.8a.707.707 0 0 1 0 1.414h-8.8a.707.707 0 0 1-.707-.707Zm.708-.058a.057.057 0 0 0 0 .115h8.8a.057.057 0 0 0 0-.114h-8.8Z"
        fill="currentColor"
        fillRule="evenodd"
      />
      <path d="M7.798 16.461h5.268a.382.382 0 0 0 0-.764H7.798a.382.382 0 0 0 0 .764Z" fill="currentColor" />
      <path
        clipRule="evenodd"
        d="M7.09 16.079c0-.39.317-.707.708-.707h5.268a.707.707 0 1 1 0 1.414H7.798a.707.707 0 0 1-.707-.707Zm.708-.057a.057.057 0 1 0 0 .114h5.268a.057.057 0 1 0 0-.114H7.798Z"
        fill="currentColor"
        fillRule="evenodd"
      />
      <path
        d="M8.072 14.014h5.296c.21 0 .4-.177.4-.388v-.484a2.654 2.654 0 0 0-2.663-2.644h-.77a2.638 2.638 0 0 0-2.645 2.631v.497c0 .212.17.385.382.389Zm.383-.872c0-1.037.841-1.878 1.88-1.88h.77c1.037 0 1.899.844 1.899 1.88v.108h-4.55v-.108Z"
        fill="currentColor"
      />
      <path
        clipRule="evenodd"
        d="M11.105 10.173a2.979 2.979 0 0 1 2.988 2.968v.485a.729.729 0 0 1-.725.713h-5.3a.712.712 0 0 1-.703-.714v-.496a2.963 2.963 0 0 1 2.97-2.956m.77 0Zm2.338 2.97a2.329 2.329 0 0 0-2.337-2.32h-.772a2.313 2.313 0 0 0-2.319 2.307v.497c0 .034.027.061.06.063h5.293a.083.083 0 0 0 .054-.025c.018-.018.021-.033.021-.04v-.482Zm-3.109-2.206h.771c1.212 0 2.224.985 2.224 2.205v.433h-5.2v-.433a2.207 2.207 0 0 1 2.205-2.205Zm.771.65h-.77a1.557 1.557 0 0 0-1.54 1.338h3.868a1.578 1.578 0 0 0-1.558-1.338Z"
        fill="currentColor"
        fillRule="evenodd"
      />
      <path
        d="M10.725 10.337a1.904 1.904 0 1 0 0-3.808 1.904 1.904 0 0 0 0 3.808Zm0-3.044a1.14 1.14 0 1 1 0 2.28 1.14 1.14 0 0 1 0-2.28Z"
        fill="currentColor"
      />
      <path
        clipRule="evenodd"
        d="M8.496 8.433a2.23 2.23 0 1 1 4.458 0 2.23 2.23 0 0 1-4.458 0Zm2.229-1.58a1.58 1.58 0 1 0 0 3.159 1.58 1.58 0 0 0 0-3.158Zm0 .765a.814.814 0 1 0 0 1.63.814.814 0 0 0 0-1.63Zm-1.465.815a1.464 1.464 0 1 1 2.93 0 1.464 1.464 0 0 1-2.93 0Z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </SvgIcon>
  );
});
