import React from 'react';
import { SvgIcon, SvgIconProps } from '../../components/svgIcon';

export const SearchIcon = React.forwardRef<SVGSVGElement, SvgIconProps>((props, ref) => {
  return (
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    <SvgIcon cacheName="mobileMenu/search" ref={ref as any} {...props}>
      <ellipse cx="10.846" cy="11.3076" fill="#DDE6EB" rx="5.76923" ry="5.76923" />
      <path
        d="M10.1536 14.1715C10.1536 14.5017 9.88373 14.7748 9.55895 14.7151C9.27875 14.6637 9.00409 14.576 8.74057 14.4532C8.29259 14.2444 7.88556 13.9384 7.54269 13.5527C7.19983 13.167 6.92786 12.7091 6.7423 12.2051C6.61719 11.8653 6.53302 11.5091 6.49146 11.1458C6.45809 10.8542 6.69918 10.6155 6.99269 10.6155C7.28621 10.6155 7.51973 10.855 7.56657 11.1447C7.59989 11.3509 7.65267 11.553 7.7243 11.7475C7.85644 12.1064 8.05012 12.4325 8.29428 12.7072C8.53844 12.9819 8.82831 13.1998 9.14732 13.3484C9.28199 13.4112 9.42075 13.4611 9.56211 13.4978C9.88171 13.5808 10.1536 13.8413 10.1536 14.1715Z"
        fill="#7E8C9C"
      />
      <path
        clipRule="evenodd"
        d="M16.4989 15.2291L20.2442 18.9621C20.5853 19.302 20.5853 19.8533 20.2443 20.1931C20.074 20.3629 19.8504 20.4477 19.6279 20.4477C19.4055 20.4477 19.1819 20.3629 19.0116 20.1931L15.2651 16.459C14.0821 17.3766 12.596 17.9242 10.9842 17.9242C7.13365 17.9242 4 14.8022 4 10.9624C4 7.12231 7.13364 4 10.9842 4C14.8347 4 17.9683 7.12231 17.9683 10.9624C17.9683 12.569 17.419 14.0501 16.4989 15.2291ZM10.9842 5.74007C8.09427 5.74007 5.74414 8.08376 5.74414 10.9624C5.74414 13.8407 8.09424 16.1841 10.9842 16.1841C13.8741 16.1841 16.2241 13.8407 16.2241 10.9624C16.2241 8.08375 13.8741 5.74007 10.9842 5.74007Z"
        fill="#7E8C9C"
        fillRule="evenodd"
      />
    </SvgIcon>
  );
});
