import { Manager } from 'socket.io-client';

export type SocketConfig = {
  CORE_SERVER_WEBSOCKET_ORIGIN: string;
};

export const createSocketManager = (params: SocketConfig): Manager => {
  return new Manager(params.CORE_SERVER_WEBSOCKET_ORIGIN, {
    autoConnect: false,
    transports: ['websocket'],
  });
};
