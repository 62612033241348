import React from 'react';
import { WebpSupportedFeaturesDictionary, getWebpSupport } from '../utils/feature/webp';

export type ImageFormat = 'base' | 'webp';

export interface WebpSupportResolver {
  (webpFeatures: WebpSupportedFeaturesDictionary): boolean;
}

export const defaultWebpSupportResolver: WebpSupportResolver = (webpFeatures) =>
  webpFeatures.lossy && webpFeatures.lossless && webpFeatures.alpha;

export const useImageFormat = ({
  webpSupportResolver = defaultWebpSupportResolver,
}: { webpSupportResolver?: WebpSupportResolver } = {}): ImageFormat | null => {
  const [format, setFormat] = React.useState<ImageFormat | null>(null);

  React.useEffect(() => {
    let isEffectCancelled = false;

    (async () => {
      const webpFeatures = await getWebpSupport();

      if (isEffectCancelled) {
        return;
      }

      const isWebpSupported = webpSupportResolver(webpFeatures);
      setFormat(isWebpSupported ? 'webp' : 'base');
    })();

    return () => {
      isEffectCancelled = true;
    };
  }, [webpSupportResolver]);

  return format;
};
