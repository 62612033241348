import React from 'react';
import { SvgIcon } from '../components/svgIcon';
import { SvgIconProps } from '../components/svgIcon/model';

export const ChevronRightIcon = React.forwardRef<SVGSVGElement, SvgIconProps>((props, ref) => {
  return (
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    <SvgIcon cacheName="chevronRight" ref={ref as any} {...props}>
      <path
        d="M15.7611 11.2179L8.41039 4.2295C8.08784 3.92353 7.56527 3.92353 7.24191 4.2295C6.91936 4.53547 6.91936 5.03246 7.24191 5.33843L14.0096 11.7723L7.24272 18.2062C6.92018 18.5122 6.92018 19.0092 7.24272 19.3159C7.56527 19.6219 8.08866 19.6219 8.4112 19.3159L15.7619 12.3276C16.0795 12.0248 16.0795 11.52 15.7611 11.2179Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
});
