import React from 'react';
import ClientMenu from '@youhelp/client.web/widgets/client/current/menu';
import BottomMenu, { BOTTOM_MENU_HEIGHT } from '@youhelp/client.web/widgets/bottomMenu';
import { useIsLoggedIn } from '@cp/entities/currentUser';
import { coreCurrentClientApi } from '@cp/entities/client';
import { useIsMobile } from '@cp/ds/hooks';
import { useIsStandalone } from 'src/shared/mediaQueries';
import { STANDALONE_BOTTOM_OFFSET_PX } from 'src/shared/ui/layout';

const CLIENT_MENU_BOTTOM_SPACING_IN_PX = 8;

export const BottomMenuWidget = () => {
  const loggedIn = useIsLoggedIn();
  const { data: clientProfile } = coreCurrentClientApi.endpoints.getProfile.useQuery(undefined, {
    skip: !loggedIn,
  });
  const isMobile = useIsMobile();
  const isStandalone = useIsStandalone();

  return clientProfile && isMobile ? (
    <ClientMenu
      sx={{ pb: `${BOTTOM_MENU_HEIGHT + CLIENT_MENU_BOTTOM_SPACING_IN_PX + (isStandalone ? STANDALONE_BOTTOM_OFFSET_PX : 0)}px` }}
    >
      {({ isMenuOpened, toggleMenu }) => <BottomMenu isProfileTabActive={isMenuOpened} onProfileTabClick={toggleMenu} />}
    </ClientMenu>
  ) : null;
};
