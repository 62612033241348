import { useCallback, useEffect } from 'react';
import { ClientPatronageStageUpdatedCSEvent } from '@common/model/admin/clientPatronage/events/clientPatronageChanged.event';
import { useDispatch } from 'react-redux';
import { CoreResumeApiTags, coreResumeApi } from '@cp/entities/resume';
import { CoreClientApiTags, coreCurrentClientApi } from '@cp/entities/client';
import { usePatronageSocket } from 'src/entities/client';

export const usePatronageUpdater = () => {
  const socket = usePatronageSocket();
  const dispatch = useDispatch();
  const invalidateClientCache = useCallback(() => {
    dispatch(coreCurrentClientApi.util.invalidateTags([CoreClientApiTags.ClientProfile]));
    dispatch(coreResumeApi.util.invalidateTags([CoreResumeApiTags.CurrentClientResume]));
  }, [dispatch]);

  useEffect(() => {
    socket.on(ClientPatronageStageUpdatedCSEvent.type, invalidateClientCache);
    return () => {
      socket.off(ClientPatronageStageUpdatedCSEvent.type, invalidateClientCache);
    };
  }, [socket, invalidateClientCache]);
};
