import React, { ReactNode } from 'react';
import { formatPhone } from '@cp/ds/src/utils/format';
import { mergeSx } from '@cp/ds/src/utils/sx/merge';
import { Link, LinkProps } from '@cp/ds/src/components/link';
import { useConfig } from '@cp/shared/config';

export interface SupportCallProps extends LinkProps {
  before?: ReactNode;
}
export const SupportCallLink: React.FC<SupportCallProps> = ({ sx, before, ...props }) => {
  const config = useConfig();
  const SUPPORT_PHONE = config.get('SUPPORT_PHONE', '');

  return (
    <Link external href={`tel:+${SUPPORT_PHONE}`} underline={false} {...props} sx={mergeSx({ variant: 'body bold', color: 'gray' }, sx)}>
      {before}
      {SUPPORT_PHONE && formatPhone(SUPPORT_PHONE)}
    </Link>
  );
};
