/* eslint-disable @typescript-eslint/no-magic-numbers */
import React from 'react';
import { shouldForwardProp } from '@mui/system';
import get from 'lodash/get';
import { SvgStyleProps, SvgTagProps } from '../model';
import { styled } from '../../../theme';

type UnstyledSvgComponent = React.ComponentClass<
  SvgTagProps & { ownerState: Required<Omit<SvgStyleProps, 'color'>> & Pick<SvgStyleProps, 'color'> }
>;

export const SvgIconRoot = styled('svg' as unknown as UnstyledSvgComponent, {
  name: 'SvgIcon',
  slot: 'Root',
  shouldForwardProp: (prop) => shouldForwardProp(prop) && prop !== 'fontSize' && prop !== 'color',
})(({ theme, ownerState }) => ({
  userSelect: 'none',
  width: '1em',
  height: '1em',
  display: 'inline-block',
  fill: 'currentColor',
  flexShrink: 0,
  transition: theme.transitions?.create?.('fill', {
    duration: theme.transitions?.duration?.shorter,
  }),
  fontSize:
    {
      inherit: 'inherit',
      small: theme.typography?.pxToRem?.(20) || '1.25rem',
      medium: theme.typography?.pxToRem?.(24) || '1.5rem',
      large: theme.typography?.pxToRem?.(35) || '2.1875',
    }[ownerState.fontSize] ?? ownerState.fontSize,
  color:
    (ownerState.color ? get(theme.palette, ownerState.color ?? '') : undefined) ??
    {
      action: theme.palette?.action?.active,
      disabled: theme.palette?.action?.disabled,
      inherit: undefined,
    }[ownerState.color ?? 'inherit'],
}));
