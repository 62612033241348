import React from 'react';
import { LOGIN_MODAL_NAME } from 'src/app/shared/components/modals/login/const';
import { useModal } from 'src/app/shared/hooks/useModal';

export const useClientLogin = () => {
  const { openModal } = useModal();

  return React.useCallback(() => {
    openModal(LOGIN_MODAL_NAME);
  }, [openModal]);
};
