import { ApiServerEntityIDType } from '@cp/utils/apiServer/types';
import { coreResumeApi } from '@cp/entities/resume';
import { ClientCatalogItemViewModel } from '@common/model/client/catalog/item/clientCatalogItemViewModel';
import { useMainResumeId } from './useMainResumeId';

export const useMainResume = (
  options: { skipQuery?: boolean } = {},
): { data: ClientCatalogItemViewModel | undefined; isLoading: boolean } => {
  const id = useMainResumeId();

  const { data, isLoading } = coreResumeApi.endpoints.getForCurrentClientById.useQuery(
    { id: id as ApiServerEntityIDType },
    { skip: options.skipQuery || !id },
  );

  return { data, isLoading };
};
