import React from 'react';
import styled from '@mui/material/styles/styled';
import { ViewProps } from './model';

const PLACEHOLDER_IMAGE_SRC = 'data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7';

const StyledImg = styled('img')({});

// ToDo: "assetItem" and "responsiveGrid" props not used, but exists for compatibility with ViewProps
// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const ViewPlaceholder: React.FC<ViewProps> = ({ assetItem, responsiveGrid, ...imgProps }) => {
  return <StyledImg {...imgProps} sizes="1px" srcSet={`${PLACEHOLDER_IMAGE_SRC} 1w`} />;
};
