import React, { ReactHTML } from 'react';
import { SxProps } from '../../theme';

export const BUTTON_VARIANT = ['primary', 'secondary', 'text'] as const;

export type ButtonVariant = (typeof BUTTON_VARIANT)[number];

export const BUTTON_COLOR = ['pink', 'gray', 'white', 'gray ultra light'] as const;

export type ButtonColor = (typeof BUTTON_COLOR)[number];

export type ButtonSize = 'small' | 'normal' | 'extraSmall';

export type ButtonTagProps = Omit<
  React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement>,
  'color' | 'ref'
>;

export interface ButtonStateProps extends Pick<ButtonTagProps, 'disabled'> {
  variant?: ButtonVariant;
  color?: ButtonColor;
  size?: ButtonSize;
  loading?: boolean;
  iconLeft?: React.ReactElement;
  iconRight?: React.ReactElement;
  square?: boolean;
  labelSx?: SxProps;
}

export type ButtonStatePropNamesWhichAreNotRequiredForDescedants = 'iconLeft' | 'iconRight' | 'labelSx';

export type ButtonStatePropsForDescedants = Required<Omit<ButtonStateProps, ButtonStatePropNamesWhichAreNotRequiredForDescedants>> &
  Pick<ButtonStateProps, ButtonStatePropNamesWhichAreNotRequiredForDescedants>;

export interface ButtonProps extends ButtonTagProps, ButtonStateProps {
  /**
   * @deprecated Use iconLeft or iconRight instead
   */
  icon?: React.ReactElement;
  sx?: SxProps;
  as?: keyof ReactHTML;
}
