import React from 'react';
import { FieldValues } from 'react-hook-form';
import { ApiServerHandlerOptions, handleApiServerError } from '@cp/utils/apiServer/errorHandler';
import { SerializedError } from '@reduxjs/toolkit';
import { ApiServerError } from '@cp/utils/rtk/query/model';

export const useApiServerErrorHandler = () => {
  // ToDo: get toasts context api and show toast on unhandled error

  return React.useCallback(
    <F extends FieldValues = FieldValues, FC = unknown>(
      error: SerializedError | ApiServerError,
      options: ApiServerHandlerOptions<F, FC> = {},
    ) => {
      return handleApiServerError(error, options);
    },
    [],
  );
};
