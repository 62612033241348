import { ClientType } from '@common/model/client/clientType';
import { ClientWebPage } from '@common/model/meta/clientWebPage';
import { clientTypeToSuitableCatalogItemType } from '@common/utils/catalogType';
import { CITY_PATH_PARAMETER, useClientWebResolver, useClientWebRouteProps } from '@cp/entities/clientWebRoutes';
import { useRouter } from '@cp/shared/router';
import { useCurrentCity } from '@cp/entities/location';
import { CatalogItemType } from '@common/model/catalog/item/type';
import { ROUTES_DEFAULT_CITY_ALIAS } from '@common/const/routes/defaultCity';
import { CAREER_MODAL_NAME } from 'src/app/shared/components/modals/career/const';
import { CLIENT_TYPE_MODAL_NAME } from 'src/app/shared/components/modals/clientType/const';
import { useClientCity } from 'src/app/shared/hooks/useClientCity';
import { useClientCityFillingChecker } from 'src/app/shared/hooks/useClientCityFillingChecker';
import { useClientProfile } from 'src/entities/client';
import { useModal } from 'src/app/shared/hooks/useModal';

/**
 * UseNavigateToCatalog
 */
export const useNavigateToCatalog = (options: { type?: CatalogItemType } = {}) => {
  const clientProfile = useClientProfile();
  const resolver = useClientWebResolver();
  const pageProps = useClientWebRouteProps();
  const router = useRouter();
  const { openModal } = useModal();
  const { currentCity } = useCurrentCity({ allowEmptyValue: true });
  const { isLoading, clientCity } = useClientCity();
  const checkThatClientCityFilled = useClientCityFillingChecker();

  const handleNavigate = async () => {
    let catalogType =
      options.type ??
      clientTypeToSuitableCatalogItemType(clientProfile?.type as ClientType) ??
      ('catalogType' in pageProps ? pageProps.catalogType : undefined);

    if (!catalogType) {
      const clientType = await new Promise<ClientType>((resolve, reject) => {
        openModal(CLIENT_TYPE_MODAL_NAME, {
          onModalDestroy: reject,
          onClientTypeSelected: resolve,
        });
      });

      catalogType = clientTypeToSuitableCatalogItemType(clientType);
    }

    let city = currentCity ?? clientCity;

    if (!city) {
      if (clientProfile) {
        city = await checkThatClientCityFilled();
      }
    }

    const careerId = await new Promise<number>((resolve, reject) => {
      openModal(CAREER_MODAL_NAME, {
        onModalDestroy: reject,
        onCareerSelected: resolve,
        showCatalogStatsFor: city ? { catalogType, cityId: city.id } : undefined,
      });
    });

    const hrefCatalog = resolver.resolve({
      page: ClientWebPage.Catalog,
      pageProps: { catalogType, careerId },
      pathParams: { [CITY_PATH_PARAMETER]: city?.alias ?? ROUTES_DEFAULT_CITY_ALIAS },
    });

    return router.navigate(hrefCatalog);
  };

  return {
    isLoading,
    clientProfile,
    currentCity,
    navigateToCatalog: handleNavigate,
  };
};
