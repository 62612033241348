export enum ClientPatronageStage {
  Unknown = 0,
  Initial = 1,
  Interview = 2,
  Training = 3,
  Photo = 4,
  ResumeFilling = 5,
  ResumeModeration = 6,
  Completed = 7,
  Rejected = 8,
}

export const CLIENT_PATRONAGE_NON_ACTIVE_STAGES = [ClientPatronageStage.Unknown, ClientPatronageStage.Completed] as const;

export type ClientPatronageActiveStages = Exclude<ClientPatronageStage, (typeof CLIENT_PATRONAGE_NON_ACTIVE_STAGES)[number]>;
