import { AppTheme } from '../../theme';
import { ButtonColor, ButtonSize, ButtonVariant } from './model';

export const BUTTON_VERTICAL_SPACING = 12;
export const BUTTON_ICON_HORIZONTAL_SPACING_NORMAL = 12;

export const BUTTON_VARIANT_DEFAULT: ButtonVariant = 'primary';
export const BUTTON_COLOR_DEFAULT: ButtonColor = 'gray';

export const buttonSizeDict: Record<ButtonSize, { borderRadius: string }> = {
  normal: { borderRadius: '12px' },
  small: { borderRadius: '8px' },
  extraSmall: { borderRadius: '8px' },
};

export const buttonSquareSizeDict: Record<ButtonSize, { size: number }> = {
  normal: { size: 56 },
  small: { size: 40 },
  extraSmall: { size: 24 },
};

export const buttonColorToThemeColor: Record<
  ButtonColor,
  Record<'color' | 'hoverColor' | 'disabledColor' | 'borderColor', keyof AppTheme['palette']> &
    Partial<Record<'backgroundColor', keyof AppTheme['palette']>>
> = {
  gray: {
    color: 'brand gray',
    borderColor: 'brand gray',
    hoverColor: 'brand gray hover',
    disabledColor: 'gray middle',
  },
  pink: {
    color: 'brand pink',
    borderColor: 'brand pink',
    hoverColor: 'brand pink hover',
    disabledColor: 'peach',
  },
  white: {
    color: 'white',
    borderColor: 'white',
    hoverColor: 'gray ultra light',
    disabledColor: 'gray middle',
  },
  ['gray ultra light']: {
    color: 'brand gray',
    borderColor: 'gray light',
    hoverColor: 'gray light',
    disabledColor: 'gray middle',
    backgroundColor: 'gray ultra light',
  },
};

export const buttonSizeToLabelTypographyVariant: Record<ButtonSize, keyof AppTheme['typography']> = {
  normal: 'button',
  small: 'caption bold',
  extraSmall: 'caption bold',
};
