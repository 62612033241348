import { useTheme } from '@cp/ds/src/hooks/useTheme';
import { MODAL_MOBILE_BREAKPOINT_THRESHOLD } from '@cp/ds/src/components/modalLayout';
import { ModalLayoutContext } from '@cp/ds/src/components/modalLayout/context';
import { useMediaQuery } from '@mui/material';
import React, { ComponentType } from 'react';
import loadable from '@loadable/component';
import { Box } from '@cp/ds/src/components/box';
import { useModalResolver } from '@cp/shared/modal';
import { useDispatch, useSelector } from 'react-redux';
import { firstModalInQueueSelector, modalQueueLengthSelector } from '../../../../redux/slices/modal/queue';
import { ModalQueueItem, modalQueueSlice } from '../../../../redux/slices/modal/queue/reducer';
import { CommonModalProps } from '../modals/model';
import { ModalDictionary, ModalProviderProps } from './model';
import { modalsWithFullscreenMobileView } from './const';

const MODAL_TRANSITION_DURATION = 300;
const MODAL_QUEUE_MAX_LENGTH = 1;

const SwipeableDrawer = loadable(() => import('@mui/material/SwipeableDrawer'));
const Dialog = loadable(() => import('@mui/material/Dialog'));
type ModalProps = CommonModalProps & { passedProps: ModalDictionary[keyof ModalDictionary] };

export const ModalProvider: React.FC<ModalProviderProps> = () => {
  const resolver = useModalResolver();
  const theme = useTheme();
  const dispatch = useDispatch();
  const isMobile = useMediaQuery(theme.breakpoints.down(MODAL_MOBILE_BREAKPOINT_THRESHOLD));
  const queueLength = useSelector(modalQueueLengthSelector);
  const activeModal = useSelector(firstModalInQueueSelector);
  const [isOpen, setOpen] = React.useState(Boolean(activeModal));
  const activeModalComponentRef = React.useRef<ComponentType<ModalProps> | null>(null);
  const wm = React.useRef(new WeakMap<ComponentType<ModalProps>, ModalQueueItem<any>>());

  const { onModalDestroy, ...activeModalProps } = activeModal?.props ?? {};

  React.useEffect(() => {
    if (!activeModal) {
      return;
    }

    let isEffectCanceled = false;

    (async () => {
      const ModalComponent = await resolver.resolveComponent<ModalProps>(activeModal.name);

      if (!isEffectCanceled) {
        activeModalComponentRef.current = ModalComponent;
        wm.current.set(ModalComponent, activeModal);
        setOpen(true);
      }
    })();

    return () => {
      isEffectCanceled = true;
    };
  }, [activeModal]);

  const onClosingTransitionEnd = React.useCallback(() => {
    dispatch(modalQueueSlice.actions.shift());
    activeModalComponentRef.current = null;
    onModalDestroy?.();
  }, [dispatch, onModalDestroy]);

  const onOpen = React.useCallback(() => setOpen(true), []);
  const onClose = React.useCallback(() => setOpen(false), []);

  React.useEffect(() => {
    if (queueLength > MODAL_QUEUE_MAX_LENGTH) {
      onClose();
    }
    const activeModalComponent = activeModalComponentRef.current;
    const componentMap = wm.current;
    if (
      activeModalComponent &&
      componentMap.has(activeModalComponent) &&
      componentMap.get(activeModalComponent)?.name !== activeModal?.name
    ) {
      onClose();
    }
  }, [onClose, queueLength, activeModal]);

  const renderedActiveModal = activeModalComponentRef.current && (
    // @ts-expect-error use both passedProps and props destruction while new modal provider api in development
    <activeModalComponentRef.current onClose={onClose} passedProps={activeModalProps} {...activeModalProps} />
  );

  // @ts-expect-error new modal provider api in development
  const isFullscreen = isMobile && (activeModalProps.fullscreen || modalsWithFullscreenMobileView.includes(activeModal?.name));

  // ToDo: render backdrop with loader if currentModal && !activeModalComponentRef.current

  return (
    <ModalLayoutContext.Provider value={{ isFullscreen }}>
      {isMobile && !isFullscreen ? (
        <SwipeableDrawer
          PaperProps={{
            sx: {
              borderTopLeftRadius: `${theme.borderRadius.large}px`,
              borderTopRightRadius: `${theme.borderRadius.large}px`,
              overflow: 'visible',
              backfaceVisibility: 'hidden',
            },
          }}
          SlideProps={{ onExited: onClosingTransitionEnd }}
          anchor="bottom"
          onClose={onClose}
          onOpen={onOpen}
          open={isOpen}
          sx={{ zIndex: 50 }}
          transitionDuration={MODAL_TRANSITION_DURATION}
          variant="temporary"
        >
          {renderedActiveModal}
        </SwipeableDrawer>
      ) : (
        <Dialog
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          PaperComponent={(isFullscreen ? Box : undefined) as any}
          PaperProps={
            isFullscreen
              ? {
                  sx: [
                    {
                      m: 0,
                      maxHeight: '100%',
                      maxWidth: '100% !important',
                      width: '100%',
                      height: '100%',
                      backgroundColor: theme.palette.white,
                    },
                    // { maxHeight: '100vh', maxWidth: '100vw', width: '100vw', height: '100vh' },
                  ],
                }
              : {
                  sx: { borderRadius: `${theme.borderRadius.extraLarge}px`, overflow: 'visible', maxWidth: 'none' },
                }
          }
          TransitionProps={{ onExited: onClosingTransitionEnd }}
          onClose={onClose}
          open={isOpen}
          scroll="body"
          sx={{ zIndex: 50 }}
          transitionDuration={MODAL_TRANSITION_DURATION}
        >
          {renderedActiveModal}
        </Dialog>
      )}
    </ModalLayoutContext.Provider>
  );
};
