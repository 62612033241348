import React from 'react';
import { SvgIconCacheContext } from './components/cache/context';
import { SvgIconRoot } from './components/styledSvg';
import { SvgIconProps } from './model';

export * from './model';

export const SvgIcon = React.forwardRef<
  SVGSVGElement,
  SvgIconProps /* Omit<SvgIconProps, 'cacheName'> & Pick<Required<SvgIconProps>, 'cacheName'> */
>((props, ref) => {
  const { subscribe, unsubscribe } = React.useContext(SvgIconCacheContext);
  const useCache = Boolean(subscribe && unsubscribe && props.cacheName && !props.color);
  const { cacheName, fontSize = 'medium', viewBox = '0 0 24 24', htmlColor, titleAccess, children, color, ...restProps } = props;
  const id = React.useId();

  // if (process.env.NODE_ENV === 'development') {
  //   if (!cacheName) {
  //     console.error('cacheName prop is required');
  //   }
  // }

  if (typeof window === 'undefined' && cacheName && subscribe && children) {
    subscribe(cacheName, children, id);
  }

  React.useEffect(() => {
    if (subscribe && cacheName && children) {
      subscribe(cacheName, children, id);
    }
    return () => {
      if (unsubscribe && cacheName) {
        unsubscribe(cacheName, id);
      }
    };
  }, [cacheName, children, id, subscribe, unsubscribe]);

  return (
    <SvgIconRoot
      aria-hidden={titleAccess ? undefined : true}
      color={htmlColor}
      focusable="false"
      ownerState={{ fontSize, color }}
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      ref={ref as any}
      role={titleAccess ? 'img' : undefined}
      viewBox={viewBox}
      {...restProps}
    >
      {titleAccess ? <title>{titleAccess}</title> : null}
      {useCache ? <use href={`#${cacheName}`} /> : children}
    </SvgIconRoot>
  );
});
