import { EventFactory } from '@cp/shared/tracking';
import { useCallback } from 'react';
import { useEventBus } from 'src/shared/eventBus';

/** return callback that send semantic event to trackers */
export const useSemanticEventTrigger = <T>(eventFactory: EventFactory<T>) => {
  const eventBus = useEventBus();
  return useCallback(
    (payload: T) => {
      eventBus.publish(eventFactory(payload));
    },
    [eventBus, eventFactory],
  );
};
