import React, { PropsWithChildren, useMemo } from 'react';
import { IRouter, RouterContextProvider } from '@cp/shared/router';
import { useRouter } from 'next/router';
import { useConfig } from '@cp/shared/config';
import { isBrowser } from '@cp/utils/browser';

export const RouterProvider: React.FC<PropsWithChildren> = (props) => {
  const config = useConfig();
  const nextRouter = useRouter();
  const router: IRouter = useMemo(() => {
    return {
      get location() {
        return isBrowser ? window.location : new URL(nextRouter.asPath, config.get('CLIENT_WEB_ORIGIN', ''));
      },
      navigate: (url, options) =>
        new Promise((resolve) => {
          const targetUrl = new URL(url, config.get('CLIENT_WEB_ORIGIN', ''));
          if (window.location.origin === targetUrl.origin) {
            return options?.method === 'replace' ? nextRouter.replace(targetUrl) : nextRouter.push(targetUrl);
          } else if (targetUrl.protocol.startsWith('http')) {
            window.open(targetUrl, '_blank'); // open external urls in new tab
          } else {
            window.location.href = targetUrl.toString();
          }
          return resolve(true);
        }),
    };
  }, [nextRouter, config]);
  return <RouterContextProvider value={router}>{props.children}</RouterContextProvider>;
};
