import { ClientPatronageActiveStages, ClientPatronageStage } from '@common/model/admin/clientPatronage/stage';
import { ClientWebPage } from '@common/model/meta/clientWebPage';
import { usePathGenerator } from '@cp/entities/clientWebRoutes';
import React from 'react';
import { useHistory } from 'react-router';
import { useScheduleInterview } from 'src/features/client/current/interview/schedule';
import { useShowInterviewDetails } from 'src/features/client/current/interview/showDetails';
import { useChangeClientPhoto } from 'src/features/client/current/photo/change';
import { useStartTraining } from 'src/features/client/current/training/start';

export type GoToNextPatronageStageAction = () => void;

export const useGoToNextPatronageStage = (stage: ClientPatronageStage | undefined): readonly [GoToNextPatronageStageAction | undefined] => {
  const history = useHistory();
  const pathGenerator = usePathGenerator();

  const goToContactsPage = React.useCallback(() => {
    history.push(pathGenerator({ page: ClientWebPage.Contacts }));
  }, [history, pathGenerator]);

  const goToProfilePage = React.useCallback(() => {
    history.push(pathGenerator({ page: ClientWebPage.Profile }));
  }, [history, pathGenerator]);

  const [scheduleInterview] = useScheduleInterview();
  const [showInterviewDetails] = useShowInterviewDetails();
  const [startTraining] = useStartTraining();
  const [changePhoto] = useChangeClientPhoto();

  const PATRONAGE_STAGE_TO_ACTION: Record<ClientPatronageActiveStages | ClientPatronageStage.Unknown, GoToNextPatronageStageAction> = {
    [ClientPatronageStage.Unknown]: goToContactsPage,
    [ClientPatronageStage.Initial]: scheduleInterview,
    [ClientPatronageStage.Interview]: showInterviewDetails,
    [ClientPatronageStage.Training]: startTraining,
    [ClientPatronageStage.Photo]: changePhoto,
    [ClientPatronageStage.ResumeFilling]: goToProfilePage,
    [ClientPatronageStage.ResumeModeration]: goToProfilePage,
    [ClientPatronageStage.Rejected]: goToContactsPage,
  };

  return [stage ? PATRONAGE_STAGE_TO_ACTION[stage] : undefined] as const;
};
