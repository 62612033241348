import React from 'react';
import { GenerateAppPathProps, RoutesDictionary, pathGenerator } from '@cp/entities/clientWebRoutes';
import { ClientWebPage } from '@common/model/meta/clientWebPage';
import { useCurrentCity } from '@cp/entities/location';

export const createUseAppPathGenerator = (routesDict: RoutesDictionary) => () => {
  const { currentCity } = useCurrentCity({ allowEmptyValue: true });

  return React.useCallback(
    <T extends ClientWebPage>({
      page,
      pageProps = undefined,
      pathParams = {} as GenerateAppPathProps<T>['pathParams'],
      queryParams,
      pageRoutes = routesDict[page],
    }: GenerateAppPathProps<T>): string => {
      if (pathParams && currentCity) {
        pathParams.city = pathParams.city ?? currentCity.alias;
      }

      return pathGenerator<T>({ page, pageProps, pathParams, queryParams, pageRoutes });
    },
    [currentCity],
  );
};
