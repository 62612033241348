import { IResolver, IResource } from '@cp/shared/resolver';
import { ClientWebPage } from '@common/model/meta/clientWebPage';
import { ClientWebPageProps } from '@common/model/meta/clientWebPageProps';
import { ClientWebPageQueryParams, ClientWebPageQueryParamsDictionary } from '@common/model/meta/clientWebPageQueryParams';
import { ClientWebPagePathParams } from '@common/model/meta/clientWebPagePathParams';
import { CITY_PATH_PARAMETER, RoutesDictionary, pathGenerator } from '../lib';

interface ClientPageResource<P extends ClientWebPage = ClientWebPage> extends IResource {
  page: P;
  pageProps?: P extends keyof ClientWebPageProps ? ClientWebPageProps[P] : undefined;
  queryParams?: P extends keyof ClientWebPageQueryParamsDictionary ? ClientWebPageQueryParams<P> : undefined;
  pathParams?: (P extends keyof ClientWebPagePathParams ? ClientWebPagePathParams[P] : {}) & {
    [key in typeof CITY_PATH_PARAMETER]?: string;
  };
  cityAlias?: string;
}

export class ClientPageResolver implements IResolver<ClientPageResource> {
  constructor(
    private readonly routesDict: RoutesDictionary,
    private readonly origin?: string | URL,
  ) {}

  resolve(resource: ClientPageResource) {
    const path = pathGenerator({
      pageRoutes: this.routesDict[resource.page],
      ...resource,
      ...(resource.cityAlias && { pathParams: { [CITY_PATH_PARAMETER]: resource.cityAlias, ...resource.pathParams } }),
    });
    return this.origin ? new URL(path, this.origin) : path;
  }
}
