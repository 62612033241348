import { FieldValues, UnpackNestedValue, UseFormReturn } from "react-hook-form";

export const getFirstFieldNameInForm = <F extends FieldValues = FieldValues, FC = unknown>(form: UseFormReturn<F, FC>): string | undefined => {
  const pickFieldName = (formValues: UnpackNestedValue<FieldValues>): string | undefined => {
    const [key, value] = Object.entries(formValues)[0] ?? [];

    if (key) {
      if (typeof value === 'object') {
        return [key, pickFieldName(value)].join('.');
      } else {
        return key;
      }
    }

    return;
  };

  const formValues = form.getValues();
  return pickFieldName(formValues);
};