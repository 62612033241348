import { OverridableComponent } from '@mui/material/OverridableComponent';
import React from 'react';
import { LINK_COLOR, LINK_COMPONENT_DEFAULT, LINK_SIZE, LinkOwnerState, LinkProps, LinkTypeMap } from './model';
import { LinkRoot } from './components/linkRoot';
import { LINK_COLOR_DEFAULT, LINK_SIZE_DEFAULT } from './const';
export type { LinkProps } from './model';

const isModifiedEvent = (event: React.MouseEvent<HTMLAnchorElement>) => {
  return Boolean(event.metaKey || event.altKey || event.ctrlKey || event.shiftKey);
};

type Navigate = () => void;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const InnerLink = React.forwardRef<
  HTMLAnchorElement,
  { ownerState: LinkOwnerState } & React.DetailedHTMLProps<React.AnchorHTMLAttributes<HTMLAnchorElement>, HTMLAnchorElement> & {
      navigate: Navigate;
    }
>(({ onClick, navigate, target, ...restProps }, ref) => {
  const handleClick = (event: React.MouseEvent<HTMLAnchorElement>) => {
    try {
      onClick?.(event);
    } catch (ex) {
      event.preventDefault();
      throw ex;
    }

    if (!restProps.href || new URL(restProps.href, window.location.origin).origin !== window.location.origin) {
      return;
    }

    if (
      !event.defaultPrevented && // onClick prevented default
      event.button === 0 && // ignore everything but left clicks
      (!target || target === '_self') && // let browser handle "target=_blank" etc.
      !isModifiedEvent(event) // ignore clicks with modifier keys
    ) {
      event.preventDefault();
      navigate();
    }
  };

  return <LinkRoot onClick={handleClick} ref={ref} target={target} {...restProps} />;
});

export const Link = React.forwardRef(
  (
    {
      href,
      external = false,
      color: _color = LINK_COLOR_DEFAULT,
      component = LINK_COMPONENT_DEFAULT,
      size: _size = LINK_SIZE_DEFAULT,
      disableHover = false,
      underline = true,
      ...restProps
    }: LinkProps,
    ref,
  ) => {
    const color = LINK_COLOR.includes(_color) ? _color : LINK_COLOR_DEFAULT;
    const size = LINK_SIZE.includes(_size) ? _size : LINK_SIZE_DEFAULT;

    const ownerState: LinkOwnerState = {
      disableHover,
      component,
      external,
      size,
      color,
      underline,
    };

    if (external) {
      return <LinkRoot as={component} href={href} ownerState={ownerState} ref={ref} target="_blank" {...restProps} />;
    }

    return <LinkRoot as={component} href={href} ownerState={ownerState} ref={ref} {...restProps} />;
  },
) as OverridableComponent<LinkTypeMap>;
