export const getOrganzationSchema = () => ({
  '@type': 'Organization',
  logo: 'https://youhelp.ru/img/logo.png',
  name: 'YouHelp',
  sameAs: [
    'viber://pa?chatURI=youhelpru',
    'https://api.whatsapp.com/send?phone=74951285457',
    'https://www.facebook.com/youhelpru/',
    'https://vk.com/youhelpru',
    'https://www.instagram.com/youhelp.ru/',
  ],
  url: 'https://youhelp.ru/',
});
