import React from 'react';
import { SearchIcon } from '@cp/ds/src/icons/mobileMenu/search';
import { SearchActiveIcon } from '@cp/ds/src/icons/mobileMenu/searchActive';
import { MessagesIcon } from '@cp/ds/src/icons/mobileMenu/messages';
import { MessagesActiveIcon } from '@cp/ds/src/icons/mobileMenu/messagesActive';
import { SigninIcon } from '@cp/ds/src/icons/mobileMenu/signin';
import { SigninActiveIcon } from '@cp/ds/src/icons/mobileMenu/signinActive';
import { VacanciesIcon } from '@cp/ds/src/icons/mobileMenu/vacancies';
import { VacanciesActiveIcon } from '@cp/ds/src/icons/mobileMenu/vacanciesActive';
import { InviteIcon } from '@cp/ds/src/icons/mobileMenu/invite';
import { InviteActiveIcon } from '@cp/ds/src/icons/mobileMenu/inviteActive';
import { useUnreadMessagesCount } from '@cp/entities/dialog';
import { useClientWebRouteParams, usePathGenerator } from '@cp/entities/clientWebRoutes';
import { ClientType } from '@common/model/client/clientType';
import { ClientWebPage } from '@common/model/meta/clientWebPage';
import { useNavigateToCatalog } from 'src/features/catalog';
import { UseItemsProps, UseItemsReturn } from './useItems.interface';
import { MenuItemProps } from './item.interface';

export const useItems = ({ clientProfile, isProfileTabActive, onProfileTabClick }: UseItemsProps): UseItemsReturn => {
  const generateAppPath = usePathGenerator();
  const isClientProvider = clientProfile.type === ClientType.Specialist;
  const { pageId: currentPageId } = useClientWebRouteParams();
  const unreadMessagesCount = useUnreadMessagesCount();
  const { isLoading: isLoadingNavigate, navigateToCatalog } = useNavigateToCatalog();

  const menuItems = React.useMemo<MenuItemProps[]>(() => {
    const items: MenuItemProps[] = [];

    if (isLoadingNavigate) {
      return items;
    }

    const isSearchActive = currentPageId === ClientWebPage.Catalog && !isProfileTabActive;

    items.push({
      title: isClientProvider ? 'Работа' : 'Помощники',
      clientProfile,
      icon: isSearchActive ? SearchActiveIcon : SearchIcon,
      onClick: navigateToCatalog,
      isActiveItem: isSearchActive,
    });

    const isMyVacanciesPageActive = currentPageId === ClientWebPage.ProfileVacancies && !isProfileTabActive;
    const isMessagesPageActive = currentPageId === ClientWebPage.ProfileMessages && !isProfileTabActive;

    if (clientProfile.type === ClientType.Specialist) {
      items.push({
        title: 'Заказы',
        clientProfile,
        icon: isMessagesPageActive ? InviteActiveIcon : InviteIcon,
        // href: generateAppPath({ page: ClientWebPage.ProfileMessages }),
        isActiveItem: isMessagesPageActive,
      });
    }

    if (clientProfile.type === ClientType.Employer) {
      items.push({
        title: 'Объявления',
        clientProfile,
        icon: isMyVacanciesPageActive ? VacanciesActiveIcon : VacanciesIcon,
        href: generateAppPath({ page: ClientWebPage.ProfileVacancies }),
        isActiveItem: isMyVacanciesPageActive,
      });
    }

    items.push({
      title: 'Сообщения',
      clientProfile,
      icon: isMessagesPageActive ? MessagesActiveIcon : MessagesIcon,
      href: generateAppPath({ page: ClientWebPage.ProfileMessages }),
      badgeContent: unreadMessagesCount,
      isActiveItem: isMessagesPageActive,
    });

    items.push({
      title: 'Профиль',
      clientProfile,
      isProfile: true,
      icon: isProfileTabActive ? SigninActiveIcon : SigninIcon,
      isActiveItem: isProfileTabActive,
      onClick: onProfileTabClick,
    });

    return items;
  }, [
    clientProfile,
    currentPageId,
    generateAppPath,
    isClientProvider,
    isLoadingNavigate,
    isProfileTabActive,
    navigateToCatalog,
    onProfileTabClick,
    unreadMessagesCount,
  ]);

  return { items: menuItems, isLoading: isLoadingNavigate };
};
