import React from 'react';
import { SchemaProps } from './model';
export type { SchemaProps } from './model';

export const Schema: React.FC<SchemaProps> = ({ schema }) => {
  return (
    <script
      dangerouslySetInnerHTML={{
        __html: JSON.stringify(
          {
            '@context': 'http://schema.org/',
            ...schema,
          },
          null,
          undefined, // process.env.NODE_ENV === 'development' ? '\t' : undefined,
        ),
      }}
      suppressHydrationWarning
      type={'application/ld+json'}
    />
  );
};
