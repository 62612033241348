import React from 'react';
import loadable from '@loadable/component';
import { CitySelectionButton } from 'src/features/location';
import { LoginButton } from 'src/features/client/current/login';
import { styled } from '@cp/ds/theme/styled';
import { useSyncDimensions } from 'src/widgets/header';
import { HeaderContentProps, HeaderFeature } from '../model';

const NavigateToCatalogLink = loadable(() => import('src/features/catalog/ui/navigateLink'));
const ClientDashboard = loadable(() => import('src/widgets/client/current/dashboard'));
const ClientMenu = loadable(() => import('src/widgets/client/current/menu'));

const Content = styled('div', { name: 'Header', slot: 'content' })({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  flexFlow: 'row nowrap',
});

const Left = styled('div', { name: 'Header', slot: 'left' })({
  display: 'flex',
  flexFlow: 'row nowrap',
  alignItems: 'center',
});

export const HeaderContentDesktop: React.FC<HeaderContentProps> = ({ features, isLoggedIn, logo, rightBlock, leftBlock, containerRef }) => {
  const height = useSyncDimensions(containerRef);
  return (
    <Content>
      <Left>
        {logo}
        {features[HeaderFeature.CitySelection] ? <CitySelectionButton /> : null}
        {features[HeaderFeature.NavigateToCatalog] ? <NavigateToCatalogLink sx={{ ml: 6.25 }} /> : null}
        {leftBlock}
      </Left>
      {rightBlock}
      {features[HeaderFeature.ClientMenu] && isLoggedIn ? (
        <ClientMenu absoluteTopPosition={height}>
          {({ ref, isMenuOpened, toggleMenu }) => (
            <ClientDashboard isProfileButtonActive={isMenuOpened} onProfileButtonClick={toggleMenu} ref={ref} />
          )}
        </ClientMenu>
      ) : null}
      {features[HeaderFeature.LoginButton] && !isLoggedIn ? <LoginButton /> : null}
    </Content>
  );
};
