import { useDispatch } from 'react-redux';
import { useCallback } from 'react';
import { slice } from './slice';

export const useOpenCitySelectionModal = () => {
  const dispatch = useDispatch();
  return useCallback(() => {
    dispatch(slice.actions.openModal(true));
  }, [dispatch]);
};
