import React from 'react';
import { StyledButton } from '@cp/ds/src/components/styled/styledButton';
import { LocationOutlineIcon } from '@cp/ds/src/icons';
import { Typography } from '@cp/ds/src/components/typography';
import { useTheme } from '@cp/ds/src/hooks/useTheme';
import { useCurrentCity } from '@cp/entities/location';
import { SxProps } from '@cp/ds';
import { mergeSx } from '@cp/ds/src/utils/sx/merge';
import { useOpenCitySelectionModal } from '../../model';

export interface CitySelectionButtonProps {
  sx?: SxProps;
}

export const CitySelectionButton = React.forwardRef<HTMLButtonElement, CitySelectionButtonProps>(({ sx }, ref) => {
  const theme = useTheme();
  const openModal = useOpenCitySelectionModal();
  const { currentCity } = useCurrentCity({ allowEmptyValue: true });

  return (
    <StyledButton
      onClick={openModal}
      ref={ref}
      sx={mergeSx({ display: 'flex', flexDirection: 'row', alignItems: 'center', pl: 3.75, cursor: 'pointer' }, sx)}
    >
      <LocationOutlineIcon />
      <Typography sx={{ borderBottom: `1px dashed ${theme.palette['brand gray']}` }} variant="body bold">
        {currentCity?.translation.name}
      </Typography>
    </StyledButton>
  );
});
