import { sleep } from '@cp/utils/sleep';
import { ClientProfileUpdateRequest } from '@common/model/client/profile/clientProfileUpdateRequest';
import { authenticationSlice, coreApi } from '@cp/shared/api/core';
import { ClientProfileViewModel } from '@common/model/client';
import { ApiServerEntityIDType } from '@cp/utils/apiServer/types';
import { CoreLocationApiTags, coreLocationApiTagTypes } from '@cp/entities/location';
import { CoreClientApiTags, coreClientApiTagTypes } from './tags';

export const coreCurrentClientApi = coreApi
  .enhanceEndpoints({ addTagTypes: coreClientApiTagTypes })
  .enhanceEndpoints({
    addTagTypes: coreLocationApiTagTypes,
  })
  .injectEndpoints({
    endpoints: (builder) => ({
      getProfile: builder.query<ClientProfileViewModel, void>({
        query: () => ({
          url: 'client/profile',
        }),
        providesTags: [CoreClientApiTags.ClientProfile],
      }),

      updateProfile: builder.mutation<ClientProfileViewModel, ClientProfileUpdateRequest>({
        query: (body) => ({
          method: 'PUT',
          url: 'client/profile',
          body,
        }),
        invalidatesTags: (a, b, r) => {
          if (r.cityId) {
            return [CoreClientApiTags.ClientProfile, { type: CoreLocationApiTags.City, id: r.cityId }];
          }
          return [CoreClientApiTags.ClientProfile];
        },
      }),

      deleteProfile: builder.mutation<boolean, void>({
        query: () => ({
          method: 'DELETE',
          url: 'client',
        }),
        onQueryStarted: async (_, { dispatch, queryFulfilled }) => {
          try {
            await queryFulfilled;
            await sleep(0);
            dispatch(coreCurrentClientApi.util.resetApiState());
            dispatch(authenticationSlice.actions.setToken(null));
          } catch {}
        },
      }),

      deleteProfilePhoto: builder.mutation<void, { id: ApiServerEntityIDType }>({
        query: (params) => ({
          method: 'DELETE',
          url: `client/photos/${params.id}`,
        }),
        onQueryStarted: async (_, { dispatch, queryFulfilled }) => {
          try {
            await queryFulfilled;
            await sleep(0);
            dispatch(
              coreCurrentClientApi.util.updateQueryData('getProfile', undefined, (draft) => {
                draft.mainPhoto = undefined;
              }),
            );
          } catch {}
        },
      }),
    }),

    overrideExisting: false,
  });
