/* eslint-disable import/no-duplicates */
import { Stack } from '@cp/ds/src/components/stack';
import React from 'react';
import { useTheme } from '@cp/ds/src/hooks/useTheme';
import { Skeleton } from '@cp/ds/src/components/skeleton';
import { mergeSx } from '@cp/ds/src/utils/sx/merge';
import { useIsStandalone } from 'src/shared/mediaQueries';
import { STANDALONE_BOTTOM_OFFSET_PX } from 'src/shared/ui/layout';
import { BottomMenuViewProps } from './model';
import { BottomMenuItem } from './menuItem';
import { BOTTOM_MENU_HEIGHT } from './const';

export const View: React.FC<BottomMenuViewProps> = ({ items, isLoading, isVisible, sx }) => {
  const theme = useTheme();
  const isStandalone = useIsStandalone();
  const menuHeight = isStandalone ? BOTTOM_MENU_HEIGHT + STANDALONE_BOTTOM_OFFSET_PX : BOTTOM_MENU_HEIGHT;
  return (
    <Stack
      direction="row"
      justifyContent="space-around"
      sx={mergeSx(
        {
          background: 'white',
          position: 'fixed',
          bottom: isVisible ? 0 : `-${menuHeight}px`,
          zIndex: 60,
          width: 1,
          alignItems: 'flex-start',
          height: `${menuHeight}px`,
          boxShadow: isVisible ? '0px 0px 16px rgba(0, 0, 0, 0.22)' : 'none',
          paddingTop: 1,
          transition: `bottom 300ms ${theme.transitions.easing.easeInOut}, box-shadow 300ms ${theme.transitions.easing.easeInOut}`,
        },
        sx,
      )}
    >
      {isLoading ? (
        <Skeleton sx={{ width: 1, height: 1, mx: 2.5 }} />
      ) : (
        items.map(({ title, ...props }) => {
          return <BottomMenuItem key={title} title={title} {...props} />;
        })
      )}
    </Stack>
  );
};
