import React from 'react';
import { useClientProfile } from 'src/entities/client';
import { useShowPatronageHints } from './useShowPatronageHints';
import { useCheckPatronageCompleted } from './useCheckPatronageCompleted';

export const useWithShowPatronageHints = () => {
  const clientProfile = useClientProfile();
  const showHints = useShowPatronageHints();
  const checkPatronage = useCheckPatronageCompleted();

  return [
    React.useCallback(
      async <T>(action: () => void): Promise<T | void> => {
        if (await checkPatronage(clientProfile)) {
          return action();
        } else {
          showHints();
        }
      },
      [checkPatronage, clientProfile, showHints],
    ),
  ];
};
