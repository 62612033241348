import { CatalogItemType } from '../model/catalog/item/type';

import { ClientType } from '../model/client/clientType';

const _clientTypeToSuitableCatalogItemType: Record<ClientType, CatalogItemType> = {
  [ClientType.Specialist]: CatalogItemType.Vacancy,
  [ClientType.Employer]: CatalogItemType.Resume,
};

export const clientTypeToSuitableCatalogItemType = (clientType: ClientType): CatalogItemType => {
  return _clientTypeToSuitableCatalogItemType[clientType];
};

export const catalogTypeToSuitableClientType = (catalogType: CatalogItemType): ClientType => {
  const foundClientType = Object.entries(_clientTypeToSuitableCatalogItemType).find((item) => item[1] === catalogType)?.[0];
  return (foundClientType ? parseInt(foundClientType as unknown as string) : undefined) as ClientType;
};

const _clientTypeToProvidedCatalogItemType: Record<ClientType, CatalogItemType> = {
  [ClientType.Specialist]: CatalogItemType.Resume,
  [ClientType.Employer]: CatalogItemType.Vacancy,
};

export const clientTypeToProvidedCatalogItemType = (clientType: ClientType): CatalogItemType => {
  return _clientTypeToProvidedCatalogItemType[clientType];
};

export const catalogTypeToProvidedByClientType = (catalogType: CatalogItemType): ClientType => {
  const foundClientType = Object.entries(_clientTypeToProvidedCatalogItemType).find((item) => item[1] === catalogType)?.[0];
  return (foundClientType ? parseInt(foundClientType as unknown as string) : undefined) as ClientType;
};
