import React from 'react';
import { Box } from '../../../box';
import { mergeSx } from '../../../../utils/sx/merge';
import { IconContainerProps } from './model';

export const IconContainer: React.FC<React.PropsWithChildren<IconContainerProps>> = ({ theme, sx, children }) => {
  return (
    <Box
      sx={mergeSx(
        {
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          px: 1,
          position: 'absolute',
          top: 0,
          right: 0,
          bottom: 0,
          left: 0,
          zIndex: 1,
          backgroundColor: theme.palette.background.paper,
        },
        sx,
      )}
    >
      {children}
    </Box>
  );
};
