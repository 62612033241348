import React from 'react';
import { CURRENT_CLIENT_INTERVIEW_SCHEDULE } from 'src/app/shared/components/modals/currentClientInterviewSchedule/const';
import { useModal } from 'src/app/shared/hooks/useModal';

export const useScheduleInterview = () => {
  const { openModal } = useModal();

  return [
    React.useCallback(() => {
      openModal(CURRENT_CLIENT_INTERVIEW_SCHEDULE);
    }, [openModal]),
  ];
};
