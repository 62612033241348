import React from 'react';
import { SvgIcon } from '../components/svgIcon';
import { SvgIconProps } from '../components/svgIcon/model';

export const LocationOutlineIcon = React.forwardRef<SVGSVGElement, SvgIconProps>((props, ref) => {
  return (
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    <SvgIcon cacheName="locationOutline" ref={ref as any} {...props}>
      <path
        d="M12 3C7.86447 3 4.5 6.26998 4.5 10.2894C4.5 11.9479 5.05922 13.5099 6.11723 14.8064C7.48715 16.4851 11.4709 20.4711 11.6398 20.6399L12 21L12.3601 20.64C12.5291 20.471 16.5141 16.4842 17.8842 14.8049C18.9413 13.5094 19.5 11.948 19.5 10.2894C19.5 6.26998 16.1355 3 12 3ZM17.097 14.1982C15.994 15.55 13.0319 18.5572 12 19.5973C10.9682 18.5572 8.00714 15.5509 6.90442 14.1996C5.98873 13.0774 5.50471 11.7253 5.50471 10.2894C5.50471 6.80842 8.41846 3.97649 12 3.97649C15.5815 3.97649 18.4953 6.80842 18.4952 10.2894C18.4952 11.7254 18.0117 13.0771 17.097 14.1982Z"
        fill="currentColor"
      />
      <path
        d="M12 6C9.51867 6 7.5 8.01867 7.5 10.5C7.5 12.9813 9.51872 15 12 15C14.4813 15 16.5 12.9813 16.5 10.5C16.5 8.01872 14.4813 6 12 6ZM12 13.9816C10.0802 13.9816 8.5184 12.4197 8.5184 10.5C8.5184 8.58022 10.0803 7.0184 12 7.0184C13.9197 7.0184 15.4816 8.58027 15.4816 10.5C15.4816 12.4198 13.9198 13.9816 12 13.9816Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
});
