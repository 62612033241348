import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { coreCurrentClientApi } from '@cp/entities/client';

/**
 * Returns current client profile if exists
 * Works only if user logged in and is client
 */
export const useClientProfile = () => {
  const profileSelector = useMemo(() => coreCurrentClientApi.endpoints.getProfile.select(), []);
  return useSelector(profileSelector).data;
};
