import React from 'react';
import { useTheme } from '../../hooks/useTheme';
import { Box } from '../box';
import { Skeleton } from '../skeleton';
import { mergeSx } from '../../utils/sx/merge';
import { AvatarIconContent } from './components/content/icon';
import { AvatarProps } from './model';
import { AvatarLettersContent } from './components/content/letters';
import { AVATAR_SIZE_DEFAULT } from './const';
import { getAvatarSizeStyles } from './utils';
import { AvatarImageContent } from './components/content/image';
import { AvatarContentBaseProps } from './components/content/model';
export type { AvatarProps } from './model';

export const Avatar: React.FC<AvatarProps> = ({
  rounded,
  content,
  contentSize,
  contentBackgroundColor,
  contentColor,
  alt,
  sx,
  frameSx,
  lazyLoadProps,
  size = AVATAR_SIZE_DEFAULT,
  clickAction = 'default',
  onClick,
  iconProps,
}) => {
  const theme = useTheme();

  const contentBaseProps: AvatarContentBaseProps = {
    theme,
    containerSize: size,
    color: contentColor,
    size: contentSize,
    backgroundColor: contentBackgroundColor,
    clickAction,
    onClick,
  };

  return (
    <Box
      onClick={onClick}
      sx={mergeSx(
        {
          position: 'relative',
          flexBasis: size,
          flexShrink: 0,
          cursor: onClick ? 'pointer' : 'inherit',
        },
        getAvatarSizeStyles(size),
        sx,
      )}
    >
      <Box
        sx={mergeSx(
          {
            borderRadius: rounded ? '50%' : { xs: `${theme.borderRadius.normal}px`, md: `${theme.borderRadius.medium}px` },
            overflow: 'hidden',
            position: 'relative',
          },
          getAvatarSizeStyles(size),
          frameSx,
        )}
      >
        <Skeleton
          sx={{
            borderRadius: { xs: `${theme.borderRadius.normal}px`, md: `${theme.borderRadius.medium}px` },
            position: 'absolute',
            top: 0,
            right: 0,
            bottom: 0,
            left: 0,
            transform: 'none',
            zIndex: 0,
          }}
        />
        {'letters' in content && content.letters ? (
          <AvatarLettersContent {...contentBaseProps} letters={content.letters} />
        ) : 'icon' in content && content.icon ? (
          <AvatarIconContent {...contentBaseProps} icon={content.icon} iconProps={iconProps} />
        ) : (
          <AvatarImageContent
            {...contentBaseProps}
            alt={alt}
            asset={'image' in content ? content.image : undefined}
            lazyLoadProps={lazyLoadProps}
          />
        )}
      </Box>
    </Box>
  );
};
